<template>
  <div class="moreInfo" :class="{'expand' : show}">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoreInfoBanner",
  props: ['show']
}
</script>

<style scoped>

</style>
import axios from "axios";

import ENV_CONFIG from "@/config/environment";
const API = ENV_CONFIG.env_api;

export class WorkDashboardApi {
  static addOrUpdateSavedFilter(newFilter) {
    return axios.post(`${API}WorkDashboard/AddOrUpdateSavedFilter`, newFilter, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    });
  }

  static deleteSavedFilter(filterId) {
    return axios.delete(`${API}WorkDashboard/DeleteSavedFilter/${filterId}`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    });
  }

  static clearDefaultFilter() {
    return axios.delete(`${API}WorkDashboard/ClearDefaultFilter`, {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    });
  }

}

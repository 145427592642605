<template>
    <div class="radio-group" :ref="`${parent}RadioGroup`" :id="`${parent}RadioGroup`">
        <RadioButton 
            label="Contains"
            value="CONTAINS"
            :key="`${parent}-CONTAINS`"
            :id="`${parent}-contains`"
            :label-classes="['radio-group__label']"
            v-model="localValue"
            @change="onChangeHandler"
        />

        <RadioButton 
            label="Does Not Contain"
            value="DOES_NOT_CONTAIN"
            :key="`${parent}-DOES_NOT_CONTAIN`"
            :id="`${parent}-does-not-contain`"
            :label-classes="['radio-group__label']"
            v-model="localValue"
            @change="onChangeHandler"
        />
    </div>
</template>

<script>
import RadioButton from '@/components/RadioButton'

export default {
    name: 'ContainsDoesNotContains',
    components: {
        RadioButton,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        parent: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            localValue: '',
        }
    },
    mounted: function () {
        this.localValue = this.value

        this.$refs[`${this.parent}RadioGroup`]
            .addEventListener(`${this.parent}Update`, this.updateLocalValue)
    },
    computed: {},
    methods: {
        onChangeHandler: function (value) {
            this.$emit('change', { value, parent: this.parent })
        },
        updateLocalValue(event) {
            this.localValue = event?.detail?.value ?? "CONTAINS"
        }
    },
}
</script>

<style lang="scss" scoped>
.radio-group {
    display: flex; 
    padding: 0.5rem 1.5rem; 
    flex-wrap: wrap;

    &__label {
        display: inline-block;

        &:first-child {
            margin-right: 5rem;
        }
    }
}
</style>
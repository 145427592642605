import store from '@/store'


const FINALIZATION_STATUSES = {
    PA_IS_UNNECESSARY_ID: 11,
    APPROVED: 200,
    APPROVED_WITH_CHANGES: 201,
    COMPLETED_PARTIAL_APPROVAL: 202,
    DENIED: 501
}

const SUBMISSION_STATUSES = {
    WITHDRAWN_BY_PA_STAFF: 8001,
    DENIED: 6000,
    PENDING_PAYER_REVIEW: 5500,
    NO_PRIOR_AUTH_NEEDED: 5100,
    PARTIALLY_APPROVED: 5001,
    PAYER_MODIFIED_APPROVED: 5002,
    APPROVED: 5000,
    NEEDS_MORE_INFO: 2000,
    NOT_SUBMITTED: 10,
    SENT_TO_PAYER: 0,
}

export const isNoPriorAuthNeeded = (status) => {
    return status == SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED;
};

export class SubmissionHelper {
    constructor(submissions, statusChange, payers) {
        this.submissions = submissions || []
        this.statusChange = statusChange || { Id: null }
        this.payers = payers || []
    }

    formatPayers(submissions) {
        const payerString = Array.from(new Set(
            submissions
                .map( p => this.payers.find( payer => 
                    (payer.primaryInsuranceId === p.insuranceCompanyId) ||
                    (payer.secondaryInsuranceId === p.insuranceCompanyId) ||
                    (payer.tertiaryInsuranceId === p.insuranceCompanyId)
                ) )
                .filter(p => p)
                .map( p => `<b>${p.name}</b>` )
        ))

        if (payerString.length === 2) {
            return `${payerString[0]} and ${payerString[1]}`
        } else if (payerString.length === 3) {
            return `${payerString[0]}, ${payerString[1]}, and ${payerString[2]}`
        }

        return payerString
    }

    needsSubmissionWarning() {
        if (
            (this.statusChange.Id === FINALIZATION_STATUSES.PA_IS_UNNECESSARY_ID) ||
            (this.statusChange.Id === FINALIZATION_STATUSES.APPROVED) ||
            (this.statusChange.Id === FINALIZATION_STATUSES.APPROVED_WITH_CHANGES) ||
            (this.statusChange.Id === FINALIZATION_STATUSES.COMPLETED_PARTIAL_APPROVAL) ||
            (this.statusChange.Id === FINALIZATION_STATUSES.DENIED)
        ) {
            if (this.statusChange.Id === FINALIZATION_STATUSES.PA_IS_UNNECESSARY_ID) {
                const hasApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.APPROVED )
                const hasNotSubmittedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                const hasSentToPayerStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER ) 
                const hasNeedsMoreInfoStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO )
                const hasPendingPayerReviewStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW )
                const hasPartialApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED )
                const hasPayerModifiedApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PAYER_MODIFIED_APPROVED )

                const allHaveNoPriorAuthNeeded = this.submissions.every( submission => 
                    (submission.statusId === SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED)
                )
                
                if (allHaveNoPriorAuthNeeded) {
                    return {
                        canFinalize: false,
                        needsWarning: false,
                        payload: {}
                    }
                } else if (hasNotSubmittedStatus || hasSentToPayerStatus || hasNeedsMoreInfoStatus || hasPendingPayerReviewStatus) {
                    const payersStillNeedingSubmitted = this.submissions.filter( 
                        submission => 
                            submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED ||
                            submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER ||
                            submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO ||
                            submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW
                    )
                    const payerString = this.formatPayers(payersStillNeedingSubmitted)

                    return {
                        canFinalize: true,
                        needsWarning: true,
                        nearestTabToSelect: payersStillNeedingSubmitted[0],
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        message: `You have not selected a final submission status for the following payor(s): ${payerString}. Would you like to have the payor(s) marked as <b>"No Prior Auth Needed"</b>?`,
                        payload: {
                            submitVia: store.state.submissionMethods.find( sm => sm.name === 'Other' ),
                            submissionsToUpdate: payersStillNeedingSubmitted,
                            submissionStatusId: SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED,
                            submissionStatusText: 'No Prior Auth Needed',
                        }
                    }

                } else if (hasApprovedStatus || hasPartialApprovedStatus || hasPayerModifiedApprovedStatus) {
                    return {
                        canFinalize: true,
                        needsWarning: true,
                        cancelButtonText: 'No - Go Back',
                        finalizeButtonText: 'Yes - and Finalize Request',
                        message: `You have selected <b>"PA is Unnecessary"</b> but you have an approved payor. Would you like to change the overall PA Request Status to <b>"Approved"</b>?`,
                        payload: {
                            finalizationStatus: FINALIZATION_STATUSES.APPROVED,
                            submissionStatusText: 'Approved'
                        }
                    }
                }
            } else if (this.statusChange.Id === FINALIZATION_STATUSES.APPROVED || this.statusChange.Id === FINALIZATION_STATUSES.APPROVED_WITH_CHANGES) {
                const hasApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.APPROVED )
                const hasPartiallyApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED )
                const hasADeniedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.DENIED )
                const hasSentToPayerStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER )
                const hasNotSubmittedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                const hasPayerModifiedApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PAYER_MODIFIED_APPROVED )
                const hasNeedsMoreInfoStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO )
                const hasPendingPayerReviewStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW )

                const statusCheck = this.submissions.every( submission => 
                    (submission.statusId === SUBMISSION_STATUSES.APPROVED) ||
                    (submission.statusId === SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED) ||
                    (submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED) ||
                    (submission.statusId === SUBMISSION_STATUSES.PAYER_MODIFIED_APPROVED)
                )

                if (statusCheck) {
                    return {
                        canFinalize: false,
                        needsWarning: false,
                        payload: {}
                    }
                } else if (
                    !hasApprovedStatus && !hasPartiallyApprovedStatus && !hasPayerModifiedApprovedStatus
                ) {
                    return {
                        canFinalize: false,
                        needsWarning: true,
                        cancelButtonText: 'OK',
                        message: `You do not have any payers listed as Approved. You must have at least one approved payer before finalizing the request as Approved.`,
                        payload: {}
                    }
                } else if (hasSentToPayerStatus || hasNeedsMoreInfoStatus || hasPendingPayerReviewStatus) {
                    const submissionsStillNeeded = this.submissions.filter( 
                        submission => 
                            submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER ||
                            submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO ||
                            submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW
                    )

                    const payerString = this.formatPayers(submissionsStillNeeded)

                    return {
                        canFinalize: false,
                        needsWarning: true,
                        cancelButtonText: 'OK',
                        nearestTabToSelect: submissionsStillNeeded[0],
                        message: `You still have an uncompleted submission(s) for ${payerString}. Before you can finalize this request, you must first update the submission to either <b>Approved</b>, <b>Denied</b>, or <b>No Prior Auth Needed</b>.`,
                        payload: {}
                    }
                } else if (hasNotSubmittedStatus) {
                    const submissionsNotSubmittedStatus = this.submissions.filter( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                    const payerString = this.formatPayers(submissionsNotSubmittedStatus)
                            
                    return {
                        canFinalize: true,
                        needsWarning: true,
                        nearestTabToSelect: submissionsNotSubmittedStatus[0],
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        message: `You have not submitted to the following payor(s): ${payerString}. Would you like to have the payor(s) marked as <b>"No Prior Auth Needed"</b>?`,
                        payload: {
                            submitVia: store.state.submissionMethods.find( sm => sm.name === 'Other' ),
                            submissionsToUpdate: submissionsNotSubmittedStatus,
                            submissionStatusId: SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED,
                            submissionStatusText: 'No Prior Auth Needed',
                        }
                    }
                } else if (hasADeniedStatus) {
                    return {
                        canFinalize: true,
                        needsWarning: true,
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        allowToProceedAnyway: true,
                        message: `You have indicated one of the payor submissions was Denied. Would you like to change the overall PA Request Status to <b>"Completed - Partial Approval"</b>?`,
                        payload: {
                            submissionStatusText: 'Completed - Partial Approval',
                            finalizationStatus: FINALIZATION_STATUSES.COMPLETED_PARTIAL_APPROVAL,
                        }
                    }

                }
            } else if (this.statusChange.Id === FINALIZATION_STATUSES.COMPLETED_PARTIAL_APPROVAL) {
                const hasSentToPayerStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER )
                const hasNotSubmittedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                const hasApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.APPROVED )
                const hasPartialApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED )
                const hasNeedsMoreInfoStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO )
                const hasPendingPayerReviewStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW )

                const statusCheck = this.submissions.every( submission => 
                    (submission.statusId === SUBMISSION_STATUSES.APPROVED) ||
                    (submission.statusId === SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED) ||
                    (submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED) ||
                    (submission.statusId === SUBMISSION_STATUSES.DENIED)
                )

                if (statusCheck) {
                    return {
                        canFinalize: false,
                        needsWarning: false,
                        message: null,
                        payload: {}
                    }
                } else if (!hasApprovedStatus && !hasPartialApprovedStatus) {
                    return {
                        canFinalize: false,
                        needsWarning: true,
                        cancelButtonText: 'OK',
                        message: `You do not have any payers listed as Approved. You must have at least one approved payer before finalizing the request as Approved.`,
                        payload: {}
                    }
                } else if (hasSentToPayerStatus || hasNeedsMoreInfoStatus || hasPendingPayerReviewStatus) {
                    const payersStillNeedingSubmitted = this.submissions.filter( 
                        submission => 
                            submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER ||
                            submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO ||
                            submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW
                    )
                    const payerString = this.formatPayers(payersStillNeedingSubmitted)

                    return {
                        canFinalize: false,
                        needsWarning: true,
                        cancelButtonText: 'OK',
                        message: `You still have an uncompleted submission for ${payerString}. Before you can finalize this request, you must first update the submission to either <b>Approved</b>, <b>Denied</b>, or <b>No Prior Auth Needed</b>.`,
                        payload: {}
                    }
                } else if (hasNotSubmittedStatus) {
                    const payersStillNeedingSubmitted = this.submissions.filter( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                    const payerString = this.formatPayers(payersStillNeedingSubmitted)

                    return {
                        canFinalize: true,
                        needsWarning: true,
                        nearestTabToSelect: payersStillNeedingSubmitted[0],
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        message: `You have not submitted to the following payor(s): ${payerString}. Would you like to have the payor(s) marked as <b>"No Prior Auth Needed"</b>?`,
                        payload: {
                            submitVia: store.state.submissionMethods.find( sm => sm.name === 'Other' ),
                            submissionsToUpdate: payersStillNeedingSubmitted,
                            submissionStatusId: SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED,
                            submissionStatusText: 'No Prior Auth Needed',
                            finalizationStatus: FINALIZATION_STATUSES.COMPLETED_PARTIAL_APPROVAL,
                        }
                    }
                }
            } else if (this.statusChange.Id === FINALIZATION_STATUSES.DENIED) {
                const hasApprovedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.APPROVED )
                const hasADeniedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.DENIED )
                const hasSentToPayerStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER )
                const hasNotSubmittedStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                const hasNeedsMoreInfoStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO )
                const hasPendingPayerReviewStatus = this.submissions.find( submission => submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW )

                const statusCheck = this.submissions.every( submission => 
                    (submission.statusId === SUBMISSION_STATUSES.DENIED) ||
                    (submission.statusId === SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED) ||
                    (submission.statusId === SUBMISSION_STATUSES.PARTIALLY_APPROVED)
                )
                
                if (statusCheck) {
                    return {
                        canFinalize: false,
                        needsWarning: false,
                        message: null,
                        payload: {}
                    }
                } else if (!hasADeniedStatus) {
                    return {
                        canFinalize: false,
                        needsWarning: true,
                        cancelButtonText: 'OK',
                        message: `You do not have any payers listed as Denied. You must have at least one denied payer before finalizing the request as Denied.`,
                        payload: {}
                    }
                } else if (hasSentToPayerStatus || hasNeedsMoreInfoStatus || hasPendingPayerReviewStatus) {
                    const payersStillNeedingSubmitted = this.submissions.filter( 
                        submission => 
                            submission.statusId === SUBMISSION_STATUSES.SENT_TO_PAYER ||
                            submission.statusId === SUBMISSION_STATUSES.NEEDS_MORE_INFO ||
                            submission.statusId === SUBMISSION_STATUSES.PENDING_PAYER_REVIEW
                    )
                    const payerString = this.formatPayers(payersStillNeedingSubmitted)

                    return {
                        canFinalize: false,
                        needsWarning: true,
                        nearestTabToSelect: payersStillNeedingSubmitted[0],
                        cancelButtonText: 'OK',
                        message: `You still have an uncompleted submission for ${payerString}. Before you can finalize this request, you must first update the submission to either <b>Approved</b>, <b>Denied</b>, or <b>No Prior Auth Needed</b>.`,
                        payload: {}
                    }
                } else if (hasNotSubmittedStatus) {
                    const payersStillNeedingSubmitted = this.submissions.filter( submission => submission.statusId === SUBMISSION_STATUSES.NOT_SUBMITTED )
                    const payerString = this.formatPayers(payersStillNeedingSubmitted)

                    return {
                        canFinalize: true,
                        needsWarning: true,
                        nearestTabToSelect: payersStillNeedingSubmitted[0],
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        message: `You have not submitted to the following payor(s): ${payerString}. Would you like to have the payor(s) marked as <b>"No Prior Auth Needed"</b>?`,
                        payload: {
                            submitVia: store.state.submissionMethods.find( sm => sm.name === 'Other' ),
                            submissionsToUpdate: payersStillNeedingSubmitted,
                            submissionStatusId: SUBMISSION_STATUSES.NO_PRIOR_AUTH_NEEDED,
                            submissionStatusText: 'No Prior Auth Needed',
                            finalizationStatus: FINALIZATION_STATUSES.DENIED,
                        }
                    }
                } else if (hasApprovedStatus) {
                    return {
                        canFinalize: true,
                        needsWarning: true,
                        allowToProceedAnyway: true,
                        finalizeButtonText: 'Yes - and Finalize Request',
                        cancelButtonText: 'No - Go Back',
                        message: `You have indicated one of the payor submissions was <b>Approved</b>. Would you like to change the overall PA Request Status to <b>"Completed - Partial Approval"</b>?`,
                        payload: {
                            submissionStatusText: 'Completed - Partial Approval',
                            finalizationStatus: FINALIZATION_STATUSES.COMPLETED_PARTIAL_APPROVAL,
                        }
                    }
                }
            } else {
                return {
                    canFinalize: false,
                    needsWarning: false,
                    message: null,
                    payload: {}
                }
            }
        }

        return {
            canFinalize: false,
            needsWarning: false,
            message: null
        }
    }
}
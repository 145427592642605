<template>
    <label :for="id" :class="labelClasses" @click.stop>
        <input 
            type="radio" 
            :id="id" 
            :key="id"
            :checked="shouldBeChecked" 
            :value="value"
            :class="inputClasses"
            @click.stop
            @change="updateInput"
        >
        <span class="ml-0.5">{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'RadioButton',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: String,
        },
        modelValue: {
            default: ""
        },
        label: {
            type: String,
            required: true
        },
        inputClasses: {
            type: [Object, Array],
            required: false
        },
        labelClasses: {
            type: [Object, Array],
            required: false
        }
    },
    computed: {
        shouldBeChecked() {
            return this.modelValue == this.value
        }
    },
    methods: {
        updateInput() {
            this.$emit('change', this.value)
        }
    }
}
</script>
<template>
  <WarnModal modalContainerId="sessionExpiring" :modalContainerClasses="['authCertWarning']">
    <div>
      <div>
        <i class="far fa-exclamation-triangle"></i>
        <h3>Warning</h3>
        <div class="approvalWarning">
          <p v-html="overallSubmissionInfo.message"></p>
        </div>
      </div>

      <div v-if="overallSubmissionInfo.canFinalize">
        <div class="buttonWrap twoOptions">
          <button @click="postAllSubmissionUpdate">
            <span v-if="finalizing"
              ><i class="fa fa-spin fa-spinner"></i> Finalizing Request...</span
            >
            <span v-if="!finalizing">{{
              overallSubmissionInfo.finalizeButtonText
            }}</span>
          </button>
          <a class="ml-2.5" href="#" @click.prevent="cancel">{{
            overallSubmissionInfo.cancelButtonText
          }}</a>
        </div>
        <div v-if="overallSubmissionInfo.allowToProceedAnyway">
          <div class="separator"><label>OR</label></div>
          <a href="#" @click.prevent="proceedAnyway">Proceed Anyway</a>
        </div>
      </div>
      <div v-if="!overallSubmissionInfo.canFinalize">
        <div class="buttonWrap">
          <a href="#" @click.prevent="cancel">{{
            overallSubmissionInfo.cancelButtonText
          }}</a>
        </div>
      </div>
    </div>
  </WarnModal>
</template>
  
  <script>
import WarnModal from "@/components/Modal.WarnModal"

export default {
  name: "SubmissionWarningModal",
  data: function () {
    return {
        finalizing: false
    }
  },
  props: [
    "modalContainerId",
    "overallSubmissionInfo",
    "postAllSubmissionUpdate",
    "proceedAnyway",
    "cancel",
  ],
  components: { WarnModal },
  methods: {
    finalizeSubmission: async function () {
        try {
            this.finalizing = true
            await this.postAllSubmissionUpdate()
        } catch (error) {
            console.warn(error)
            this.finalizing = false
        }
    }
  }
};
</script>
  
<template>
  <BaseModal
    :modalContainerId="modalContainerId"
    modalContentId="warnModal"
    :includeCloseOption="false"
  >
    <slot></slot>
  </BaseModal>
</template>
  
  <script>
import BaseModal from "@/components/Modal.Base";

export default {
  name: "SubmissionWarningModal",
  props: ["modalContainerId", "modalContainerClasses", "modalContentClasses"],
  components: { BaseModal },
};
</script>
  
import moment from "moment";
import { WorklistFilter, Filter } from '@/shared/models/worklist'


const mapping = {
  paRequestId: {
    fieldName: "paRequestId",
    operator: "EQ",
  },
  finalizedDateRange: {
    fieldName: "statusUpdated",
    operator: "BETWEEN",
  },
  filterByCptCode: {
    fieldName: "cptCodes.CptCode",
    operator: "CONTAINS",
  },
  filterByProcedureDescription: {
    fieldName: "procedureDescription",
    operator: "CONTAINS",
  },
  filterByEmrId: {
    fieldName: "strOrderNumber",
    operator: "CONTAINS",
  },
  filterByMrn: {
    fieldName: "mrn",
    operator: "CONTAINS",
  },
  filterByPatientFirstName: {
    fieldName: "firstName",
    operator: "CONTAINS",
  },
  filterByPatientLastName: {
    fieldName: "lastName",
    operator: "CONTAINS",
  },
  filterBySubmissionMethod: {
    fieldName: "submissionInfos.uiSubmissionMethod",
    operator: "EQ",
  },
  filterBySubmissionStatus: {
    fieldName: "submissionInfos.uiSubmissionCategoryName",
    operator: "EQ",
  },
  createdDateRange: {
    fieldName: "createdDate",
    operator: "BETWEEN",
  },
  createdDateRangeWithinNDays: {
    fieldName: "createdDate",
    operator: "SINCE",
  },
  followUpDateRange: {
    fieldName: "followUpDate",
    operator: "BETWEEN",
  },
  followUpDateRangeNullDatesIncluded: {
    fieldName: "followUpDate",
    operator: "EQ",
    // value should be null
  },
  followUpDateRangeWithinNDays: {
    fieldName: "followUpDate",
    operator: "WITHIN",
  },
  procedureDateRange: {
    fieldName: "toBeDoneDate",
    operator: "BETWEEN",
  },
  procedureDateRangeNullDatesIncluded: {
    fieldName: "toBeDoneDate",
    operator: "EQ",
    // value should be null
  },
  procedureDateRangeWithinNDays: {
    fieldName: "toBeDoneDate",
    operator: "WITHIN",
  },
  filterByAssigned: {
    fieldName: "assignedToId",
    operator: "EQ",
  },
  filterByDepartments: {
    fieldName: "departmentId",
    operator: "EQ",
  },
  filterByFacility: {
    fieldName: "facilityName",
    operator: "EQ",
  },
  filterByPayer: {
    fieldName: "insurances.InsuranceCompanyId",
    operator: "EQ",
  },
  filterByProvider: {
    fieldName: "orderingProviderId",
    operator: "EQ",
  },
  filterByStatus: {
    fieldName: "status",
    operator: "EQ",
  },
};

const reverseMapping = Object.keys(mapping).reduce((acc, map) => {
    acc[mapping[map].fieldName] = map
    return acc
}, {})

const getValuesFromReverseMapping = (fieldObj) => {
    return Object.keys(fieldObj).reduce((acc, mapKey) => {

        let fieldName = reverseMapping[fieldObj.Field]
        if (fieldObj.Field === 'toBeDoneDate') {
            fieldName ='procedureDateRange'
        } else if (fieldObj.Field === 'followUpDate') {
            fieldName = 'followUpDateRange'
        } else if (fieldObj.Field === 'createdDate') {
            fieldName = 'createdDateRange'
        }

        const operator = fieldObj.Operator
      
        let mapKeyValue = fieldName
      
        if (operator === "WITHIN") {
          mapKeyValue = `${fieldName}WithinNDays`
          acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: fieldObj.Value,
          });

          mapKeyValue = `${fieldName}DisplayWithDaysInput`
          acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: null,
          });

          return acc;
        } else if (operator === 'EQ' && (fieldName === 'procedureDateRange' || fieldName === 'followUpDateRange')) {
          mapKeyValue = `${fieldName}NullDatesIncluded`
          acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: null,
          });

          return acc;
        } else if (operator === 'SINCE') {
          mapKeyValue = `${fieldName}WithinNDays`
          acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: fieldObj.Value,
          });

          mapKeyValue = `${fieldName}DisplayWithDaysInput`
          acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: null,
          });

          return acc;
        }
      
        acc[mapKeyValue] = new Filter({
            field: fieldName,
            operator: operator,
            value: fieldObj.Value,
        });

        return acc;
      }, {});
}

export default class PaginatedFilterMapping {
    static getFilterMapping() {
        return mapping
    }

    static reverseEngineerFilters(filter) {
        const convertedFilter = JSON.parse(filter.filter);
        const filters = convertedFilter.Filter.Filters;
  
        const convertedFiltersObj = filters.reduce(
          (convertedFilter, currentFilter) => {
            // console.log('reverseEngineerFilters', currentFilter)
            const fieldNameObj = getValuesFromReverseMapping(currentFilter);
            const fieldName = Object.keys(fieldNameObj)[0]
  
            let value = "";
            
            if (Object.keys(fieldNameObj).length > 0) {
              Object.keys(fieldNameObj).forEach(key => {
                if (fieldNameObj[key].Value) {
                  value = fieldNameObj[key].Value.split("|");
      
                  if (
                    fieldNameObj[key].Operator === "BETWEEN"
                  ) {
                    convertedFilter[key] = [new Date(value[0]), new Date(value[1])];
                  } else if (fieldNameObj[key].Operator === "WITHIN") { 
                    if (!value[0]) {
                      convertedFilter[key] = [true]
                    } else if (isNaN(Number(value[0]))) {
                      convertedFilter[key] = [new Date(value[0])]
                    } else {
                      convertedFilter[key] = [value[0]]
                    }
                  } else {
                    if (convertedFilter[key]) {
                      convertedFilter[key].push(
                        isNaN(Number(value[0])) ? value[0] : Number(value[0])
                      );
                    } else {
                      convertedFilter[key] = [
                        isNaN(Number(value[0])) ? value[0] : Number(value[0]),
                      ];
                    }
                  }
                } else {
                  // null search included detected for date range
                  value = true;
                  convertedFilter[key] = [value];
                }
              })
  
              return convertedFilter
            } else {
              if (fieldNameObj[fieldName].Value) {
                value = fieldNameObj[fieldName].Value.split("|");
    
                if (
                  fieldNameObj[fieldName].Operator === "BETWEEN"
                ) {
                  convertedFilter[fieldName] = [new Date(value[0]), new Date(value[1])];
                  return convertedFilter;
                } else if (fieldNameObj[fieldName].Operator === "WITHIN") { 
                  if (!value[0]) {
                    convertedFilter[fieldName] = [true]
                  } else if (isNaN(Number(value[0]))) {
                    convertedFilter[fieldName] = [new Date(value[0])]
                  } else {
                    convertedFilter[fieldName] = [value[0]]
                  }
    
                  return convertedFilter
                } else {
                  if (convertedFilter[fieldName]) {
                    convertedFilter[fieldName].push(
                      isNaN(Number(value[0])) ? value[0] : Number(value[0])
                    );
                    return convertedFilter
                  } else {
                    convertedFilter[fieldName] = [
                      isNaN(Number(value[0])) ? value[0] : Number(value[0]),
                    ];
                  }
    
                  return convertedFilter;
                }
              } else {
                // null search included detected for date range
                value = true;
                convertedFilter[fieldName] = [value];
                return convertedFilter
              }
            }
          },
          {}
        );
        return new WorklistFilter({
          ...filter,
          filter: JSON.stringify({ ...convertedFiltersObj }), 
        }); 
    }

    static formatCurrentFilter(currentFilter, filterUpdates) {
      const formatted = [];

      function getFilterValue(filterVal, filterOperator, fieldName) {
        if (filterOperator !== "BETWEEN" && filterVal instanceof Array) {
          return filterVal[0];
        } else if (filterVal === "true") {
          return null;
        } else {
          return `${filterVal}`;
        }
      }

      function addFormattedFilter(fieldName, filterVal, filterOperator, originalFilter) {
        const formattedFilter = new Filter({
          field: fieldName,
          value: getFilterValue(filterVal, filterOperator, fieldName),
          operator: filterUpdates?.[originalFilter]?.operator ?? filterOperator
        });

        formatted.push(formattedFilter);
      }

      function getMappedFilterData(filterName) {
        return mapping[filterName] || null;
      }

      for (const [key, values] of Object.entries(currentFilter)) {
        const filterData = getMappedFilterData(key);

        if (filterData === null) continue;

        if (
          filterData.operator === "BETWEEN" &&
          values && values.length &&
          moment(values[0]).isValid() &&
          moment(values[1]).isValid()
        ) {
          const dateRange = `${values[0].toString()}|${values[1].toString()}`;
          addFormattedFilter(
            filterData.fieldName,
            dateRange,
            filterData.operator,
            key
          );
        } else {
          if (Array.isArray(values)) {
            for (let i = 0; i < values.length; i++) {
              addFormattedFilter(
                filterData.fieldName,
                values[i],
                filterData.operator,
                key
              );
            }
          } else {
            addFormattedFilter(
              filterData.fieldName,
              values,
              filterData.operator,
              key
            );
          }
        }
      }

      return formatted || null;
    }

    static getFilterNameFromField(fieldName) {
      return reverseMapping[fieldName]
    }
}

<template>
    <div class="messageBanner" v-bind:class="messageType">
        <p>
            {{ message }} <a v-if="hasDetails" href="#" @click.prevent="() => onMoreInfo(messageID)"><strong> (Show More) </strong></a> <a href="#" @click.prevent="() => onClose(messageID)"> <i class="fa fa-times-circle"></i> </a> 
        </p>
        
    </div>
</template>

<script>
export default {
    name: 'UMSBanner',
    props: {
        message: String,
        messageType: String,
        messageID: String,
        hasDetails: Boolean,
        onClose: Function,
        onMoreInfo: Function,
    }
}
</script>
<style lang="scss" scoped>
.messageBanner {
    padding: 0.5rem;
    font-size: 1.4rem;;
    background-color: #D9E3F5;
    color: #1B3668;
    a {
        color: #1B3668;
    }
    text-align: center;
}

.warning {
    background-color: #FAEBE2;
    color: #a30000;
    a {
        color: #a30000;
    }
}

.good {
    background-color: #E4F7E2;
    color: #1e4c00;
    a {
        color: #1e4c00;
    }
}

.caution {
    background-color: #FFFDCD;
    color: #345400;
    a {
        color: #345400;
    }
}

</style>
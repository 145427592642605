

export class VirtualSelectService {
    constructor(selector) {
        this.el = document.querySelector(selector)
    }

    /**
     * Get selected value
     * 
     * @returns 
     */
    val() {
        return this.el.value
    }

    /**
     * value - single value or array of values
     * noEventTrigger - set true to avoid event trigger
     * 
     * @param {*} value 
     * @param {*} noEventTrigger 
     */
    setValue(value, noEventTrigger ) {
        this.el.setValue(value, noEventTrigger)
    }

    reset() {
        this.el.reset()
    }

    /**
     * options - list of options details
     * keepValue - set true to keep selected value
     * 
     * @param {*} options 
     * @param {*} keepValue 
     */
    setOptions(options, keepValue) {
        this.el.setOptions(options, keepValue)
    }

    /**
     * disabledOptions - list of disabled option's values or true to disable all options
     * keepValue - set true to keep selected value
     * 
     * @param {*} disabledOptions 
     * @param {*} keepValue 
     */
    setDisabledOptions(disabledOptions, keepValue) {
        this.el.setOptions(disabledOptions, keepValue)
    }

    /**
     * enabledOptions - list of enabled option's values or true to enable all options
     * keepValue - set true to keep selected value
     * 
     * @param {*} enabledOptions 
     * @param {*} keepValue 
     */
    setEnabledOptions(enabledOptions, keepValue) {
        this.el.setEnabledOptions(enabledOptions, keepValue)
    }

    /**
     * Select / Deselect all options
     * 
     * @param {*} isAllSelected 
     */
    toggleSelectAll(isAllSelected) {
        this.el.toggleSelectAll(isAllSelected)
    }

    /**
     * To check that if all options selected or not
     * 
     * @returns
     */
    isAllSelected() {
        return this.el.isAllSelected()
    }

    /**
     * To add a new option with existing options
     * 
     * @param {*} optionDetails 
     * 
     * @example
     * 
     * document.querySelector('#sample-select').addOption({
     *      value: '101',
     *      label: 'Option 101',
     * });
     */
    addOption(optionDetails) {
        this.el.addOption(optionDetails)
    }

    /**
     * Get selected value which is added as new option
     * 
     * @returns 
     */
    getNewValue() {
        return this.el.getNewValue()
    }

    /**
     * Get selected option's display value (i.e label)
     * 
     * @returns 
     */
    getDisplayValue() {
        return this.el.getDisplayValue()
    }

    /**
     * Get selected option's details. It would contains isNew: true property for options added newly by allowNewOption
     * 
     * @returns 
     */
    getSelectedOptions() {
        return this.el.getSelectedOptions()
    }

    /**
     * Get disabled option's details.
     * 
     * @returns 
     */
    getDisabledOptions() {
        return this.el.getDisabledOptions()
    }

    open() {
        this.el.open()
    }

    close() {
        this.el.close()
    }

    /**
     * To focus dropdown element programmatically
     */
    focus() {
        this.el.focus()
    }

    /**
     * To enable dropdown element programmatically
     */
    enable() {
        this.el.enable()
    }

    /**
     * To disable dropdown element programmatically
     */
    disable() {
        this.el.disable()
    }

    /**
     * To destroy the virtual select instance from the element
     */
    destroy() {
        this.el.destroy()
    }

    /**
     * Use this method to set options while loading options from server.
     * 
     * @param {*} serverOptions
     * 
     * @example
     * VirtualSelect.init({
     *      ...
     *      onServerSearch: onSampleSelectServerSearch,
     * });
     * 
     * function onSampleSelectServerSearch(searchValue, virtualSelect) {
     *      project developer has to define anyMehodToGetDataFromServer 
     *      function to make API call
     * 
     *      anyMehodToGetDataFromServer(searchValue).then(function(newOptions) {
     *          virtualSelect.setServerOptions(newOptions);
     *      });
     * }
     */
    setServerOptions(serverOptions) {
        this.el.setServerOptions(serverOptions)
    }

    /**
     * To trigger required validation programmatically
     */
    validate() {
        this.el.validate()
    }

    /**
     * To update required property value
     * 
     * @param {*} isRequired - true/false
     */
    toggleRequired(isRequired) {
        this.el.toggleRequired(isRequired)
    }

    setTextSearchValue(value) {
        this.el.querySelector('.vscomp-search-input').value = value

        const event = new KeyboardEvent('keyup', {
            key: value
        })

        this.el.querySelector('.vscomp-search-input').dispatchEvent(event)
    }

    getTextSearchValue() {
        return this.el.querySelector('.vscomp-search-input').value
    }

    focusOnTextSearch() {
        this.el.querySelector('.vscomp-search-input').focus()
    }

    /**
     * Returns true if the dropbox exists and is open.
     * returns false otherwise.
     */
    isOpen() {
        const dropboxElm = this.el ? this.el.querySelector('.vscomp-dropbox') : null;
        return dropboxElm && dropboxElm.offsetParent !== null
    }
    /**
     * Returns true if the dropbox does not exists or is closed.
     * returns false otherwise.
     */
    isClosed() {
        const dropboxElm = this.el ? this.el.querySelector('.vscomp-dropbox') : null;
        return !dropboxElm || dropboxElm.offsetParent === null
    }
}
import axios from 'axios'
import ENV_CONFIG from '@/config/environment'

const API = ENV_CONFIG.env_api

// FAX SUBMISSION STATUS REFERENCE
// 
// [Description( "Has not been submitted" )]
// NotSubmitted = 0,

// [Description( "Submitted and sent successfully")]
// Success = 1,

// [Description( "Submitted but failed to send")]
// Failure = 2,

// [Description( "Submitted and sending in progress")]
// InProgress = 3,

// [Description( "Submission in progress")]
// SubmissionInProgress = 4,

// [Description( "Submission Failed" )]
// SubmissionFailed = 5,

// [Description( "Packet Creation Failed" )]
// PacketCreationFailed = 6,

// [Description( "Could not Generate AccessToken for EFax Corporate " )]
// AccessTokenNotFound = 7,

// [Description( "Exception Occurred while Deserializing response from Efax" )]
// ErrorOccurred = 8

function initialState() {
    return {
        faxingSubmissions: [], // Array of faxes in process
        faxCompleted: [], // Array of faxes completed, used in Request.vue and Request.Submit.History.vue to trigger refreshes of event feed and submission history,
    }
}

async function getFaxStatus(params) {
    return await axios.get(`${API}PaRequests/GetFaxStatus/${params.paRequestId}/?insuranceCompanyId=${params.insuranceCompanyId}`, {
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Pragma': 'no-cache'
        }
    })
}

async function checkFaxStatus(submission) {
    return await getFaxStatus(submission)
        .then(res => {
            let lastFax = res.data[res.data.length - 1]
            return lastFax
        })
        .catch(err => {
            return { status: 6, statusDescription: "Failed to get fax status" }
        })
}

function removeTime(time){
  if(time){time = time.split(' ')
    return time[0];
  }
  return '';
}

function getFaxDetails(faxParams, rootState){
  if(!rootState.requests.length || !rootState.payerList.length) return faxParams

  let faxingPa = rootState.requests.find(r => r.paRequestId === faxParams.paRequestId)
  if(faxingPa){
    let dob = removeTime(faxingPa.dob)
    faxParams.patient = `${faxingPa.lastName} ${dob} ${faxingPa.mrn}` 
  }
  
  let faxingIns = rootState.payerList.find(p => p.insuranceCompanyId === faxParams.insuranceCompanyId)
  if(faxingIns) faxParams.companyName = faxingIns.company
  return faxParams
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_FAX_STATUS: async function({ commit, dispatch, state, rootState }, params) {
            let lastFax = await checkFaxStatus(params)
            
            if(!lastFax){
              commit('REMOVE_FAX_FROM_QUEUE', params.submissionId)
              return
            }
            
            // Timestamp updated every time we loop to force vue's reactivity
            params.updated = Date.now()
            
            // If we refreshed we won't have a payer list or paRequest list to get patient/payer details
            if (!params.patient || !params.companyName) params = getFaxDetails(params, rootState)
            
            // If refreshed set time stamp to fax sent to server time
            if(!params.when) params.when = lastFax.sentToServerTime || lastFax.statusAsOf
            params.statusDescription = lastFax.statusDescription
            
            // add new faxes to queue and session
            if (!state.faxingSubmissions.includes(params)) {
                commit('ADD_FAX_TO_QUEUE', params)
                commit('ADD_FAX_TO_SESSION', params)
            }
            
            // If efax is not still in process (see status reference above), remove from queue
            if (lastFax.status !== 0 && lastFax.status !== 3 && lastFax.status !== 4) {
                commit('REMOVE_FAX_FROM_QUEUE', params.submissionId)
                let title = `Faxing completed`
                if(params.patient != undefined && params.companyName != undefined) title = `${params.patient} to ${params.companyName}`
                let faxingNotification = {
                    message: `${lastFax.statusDescription}`,
                    destination: `request/${params.paRequestId}/?tab=submission`,
                    title: title,
                    when: Date.now()
                }
                dispatch('ADD_USER_NOTIFICATION', faxingNotification, { root: true })
                commit('ADD_FAX_TO_COMPLETED', params)
            }
            
            // if fax is still in process loop
            if (state.faxingSubmissions.includes(params)) {
                setTimeout(() => {
                    params.refresh = null
                    commit('UPDATE_FAX_IN_QUEUE', params)
                    return dispatch('GET_FAX_STATUS', params)
                }, params.refresh || 30000)
            }

        },
        GET_SESSION_FAXES: function({dispatch, commit, rootState}) {
          let sessionFaxes = sessionStorage.getItem('faxesInProcess')
          if(sessionFaxes) {
            let faxingArray = JSON.parse(sessionFaxes)
            faxingArray.map(f => dispatch('GET_FAX_STATUS', {...f, refresh: 10000}))
          }
        },
        GET_FAX_PREVIEW: function ({}, pdfInfo) {
          return axios.post(`${API}PaRequests/FaxPaPreviewRequest`, pdfInfo, {
                  headers: {
                      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                      'Pragma': 'no-cache'
                  },
                })
        },
        VIEW_FAX_PREVIEW: function ({}, faxPreviewUrl) {
          return axios.get(`${API}${faxPreviewUrl}`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                'Pragma': 'no-cache'
            },
            responseType: 'arraybuffer'
          })
        },
        SEND_PREVIEWED_FAX: function({}, urlToSendFax) {
          return axios.post(`${API}${urlToSendFax}`, {}, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                'Pragma': 'no-cache'
            },
          })
        }
    },
    mutations: {
        ADD_FAX_TO_QUEUE: (state, submission) => {
            state.faxingSubmissions.push(submission)
        },
        UPDATE_FAX_IN_QUEUE: (state, submission) => {
            let faxingSubmissions = state.faxingSubmissions.filter(s => s.submissionId !== submission.submissionId)
            state.faxingSubmissions = [...faxingSubmissions, submission]
        },
        REMOVE_FAX_FROM_QUEUE: (state, submissionId) => {
            state.faxingSubmissions = state.faxingSubmissions.filter(s => s.submissionId !== submissionId)
            
            let faxesInProcess = state.faxingSubmissions.map( f => {
              return { paRequestId: f.paRequestId, insuranceCompanyId: f.insuranceCompanyId, submissionId: f.submissionId, companyName: null, patient: null }
            })
            sessionStorage.setItem('faxesInProcess', JSON.stringify(faxesInProcess))
        },
        ADD_FAX_TO_SESSION: (state, fax) => {
          let faxing = state.faxingSubmissions.map( f => {
            return { paRequestId: f.paRequestId, insuranceCompanyId: f.insuranceCompanyId, submissionId: f.submissionId, companyName: null, patient: null }
          })

          sessionStorage.setItem('faxesInProcess', JSON.stringify(faxing))
        },
        ADD_FAX_TO_COMPLETED: (state, fax) => {
          state.faxCompleted = [...state.faxCompleted, fax]
        },
        REMOVE_FAX_COMPLETED: (state, fax) => {
          state.faxCompleted = state.faxCompleted.filter( f => f.paRequestId !== fax.paRequestId)
        },
        RESET: (state) => {
          const s = initialState()
          Object.keys(s).forEach(key => {
             state[key] = s[key]
          })
        }
    }
}
<template>
  <WarnModal modalContainerId="sessionExpiring">
    <div>
      <div v-if="showAuthWarning">
        <i class="far fa-exclamation-triangle"></i>
        <h3>Warning</h3>
        <div class="approvalWarning">
          <p>
            The following information is missing, which could result in the
            claim being denied.
          </p>
          <ul>
            <li v-for="(data, index) in missingSubmissionInfo" :key="index">
              {{ data }}
            </li>
          </ul>
        </div>
      </div>

      <p v-if="showContinueMessage">
        Setting the status to <b>{{ statusName }}</b> will finalize this request
        and remove it from the worklist. <br /><b
          >Are you sure you want to continue?</b
        >
      </p>

      <div class="buttonWrap">
        <button @click="postSubmissionWarningUpdate">
          <i v-if="finalizing" class="fa fa-spin fa-spinner"></i>
          {{ buttonText ? buttonText : "Finalize Request" }}
        </button>
        <a href="#" @click.prevent="cancel" class="ml-2.5">{{
          cancelButtonText ? cancelButtonText : "Cancel"
        }}</a>
      </div>
    </div>
  </WarnModal>
</template>

<script>
import WarnModal from "@/components/Modal.WarnModal";

export default {
  name: "SubmissionWarningModal",
  data: function () {
    return {
        finalizing: false
    }
  },
  props: [
    'postUpdate',
    'cancel',
    'showContinueMessage',
    'buttonText',
    'cancelButtonText',
    'showAuthWarning',
    'missingSubmissionInfo',
    'statusName'
  ],
  components: { WarnModal },
  methods: {
    postSubmissionWarningUpdate: function () {
      this.finalizing = true
      this.postUpdate()
    }
  }
};
</script>

<template>
  <div class="no-search-results">
    <h2>No Results Found</h2>
    <!-- Display when not searched for finalized dates -->
    <template v-if="namedRoute === 'Worklist'">
      <template v-if="(mappedFilters && mappedFilters.finalizedDateRange && mappedFilters.finalizedDateRange.length > 0) && !isSearchPerformedForFinalizedDatesAndOtherFilters()">
        <p>No results found with that combination of finalized dates.</p>
        <p>Note: You cannot search for Finalized Dates on Active Worklist.</p>
      </template>
      <template v-else-if="isSearchPerformedForFinalizedDatesAndOtherFilters()">
        <p>Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters(mappedFilters), searchTerm: ''} }">Active Worklist</router-link>?</p>
        <p>Note: You cannot search for Finalized Dates on Active Worklist.</p>
      </template>
      <template v-else>
        <p>Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters(mappedFilters), searchTerm: ''} }">Active Worklist</router-link>?</p>
      </template>
    </template>
    <template v-if="namedRoute === 'Archive'">
        <p v-if="(!mappedSearchValue && mappedFilters.followUpDateRange.length == 0)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters(mappedFilters), displayType: 'search', searchValue: mappedSearchValue} }">Archive</router-link>?</p>
        <template v-if="isSearchPerfomedForFollowUpDatesAndOtherFilters()">
         <p>Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters(mappedFilters), displayType: 'search',  searchValue: mappedSearchValue} }">Archive</router-link>?</p>
         <p>Note: You cannot search for Follow Up Dates on Archive.</p>
        </template>
        <template v-if="mappedSearchValue && isSearchTermAnAllSearch">
          <!-- A few different links for each input type (First Name, Last Name, MRN, EMR ID, and NPI?) -->
          <p v-if="!hasNumbersInString(modifiedSearchString)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByPatientFirstName: modifiedSearchString[1] ? [modifiedSearchString[1]] : [modifiedSearchString[0]]} ), displayType: 'search' } }">First Name</router-link> in Archive?</p>
          <p v-if="!hasNumbersInString(modifiedSearchString)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByPatientLastName: [modifiedSearchString[0]] }), displayType: 'search' } }">Last Name</router-link> in Archive?</p>
          <p v-if="!hasNumbersInString(mappedSearchValue)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByProcedureDescription: [mappedSearchValue] }), displayType: 'search' } }">Procedure Description</router-link> in Archive?</p>
          <p v-if="hasNumbersInString(mappedSearchValue)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByEmrId: [mappedSearchValue] }), displayType: 'search' } }">EMR ID</router-link> in Archive?</p>
          <p v-if="hasNumbersInString(mappedSearchValue)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByMrn: [mappedSearchValue] }), displayType: 'search' } }">Patient MRN</router-link> in Archive?</p>
          <p v-if="hasNumbersInString(mappedSearchValue)">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByCptCode: [mappedSearchValue] }), displayType: 'search' } }">CPT CODE</router-link> in Archive?</p>
        </template>
        <template v-if="mappedSearchValue && !isSearchTermAnAllSearch">
          <!-- A few different links for each input type (First Name, Last Name, MRN, EMR ID, and NPI?) -->
          <p v-if="!hasNumbersInString(modifiedSearchString) && isSearchTermAPatientFirstNameSearch">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByPatientFirstName: modifiedSearchString[1] ? [modifiedSearchString[1]] : [modifiedSearchString[0]]} ), displayType: 'search' } }">First Name</router-link> in Archive?</p>
          <p v-if="!hasNumbersInString(modifiedSearchString) && isSearchTermAPatientLastNameSearch">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByPatientLastName: [modifiedSearchString[0]] }), displayType: 'search' } }">Last Name</router-link> in Archive?</p>
          <p v-if="hasNumbersInString(mappedSearchValue) && isSearchTermAnEmrIdSearch">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByEmrId: [mappedSearchValue] }), displayType: 'search' } }">EMR ID</router-link> in Archive?</p>
          <p v-if="hasNumbersInString(mappedSearchValue) && isSearchTermAnMrnSearch">Would you like to perform a similar search on the <router-link :to="{ name: namedRoute, params: { filters: makeDeepCopyOfFilters({ ...mappedFilters, filterByMrn: [mappedSearchValue] }), displayType: 'search' } }">Patient MRN</router-link> in Archive?</p>
        </template>
    </template>
  </div>
</template>

<script>
  import utility from '@/mixins/utility';
  import { mapGetters, mapState } from 'vuex'

  export default {
      name: 'NoSearchResults',
      props: ['namedRoute', 'filters', 'searchValue', 'narrowSearchBy'],
      mixins: [utility],
      data: function () {
        return {
          mappedFilters: this.filters,
          mappedSearchValue: this.searchValue
        }
      },
      // mounted: function () {
      //   this.mappedFilters = this.filters
      //   this.mappedSearchValue = this.searchValue
      // },
      computed: {
        ...mapState(['textSearchTerm']),
        ...mapGetters(['hiddenStatuesFromWorklist']),
        modifiedSearchString: function () {
          if(this.mappedSearchValue && this.mappedSearchValue.length > 0) {
            if (this.mappedSearchValue.indexOf(',') != -1) {
              return this.mappedSearchValue.split(',')
            }
            return [this.mappedSearchValue]
          }
        },
        isSearchTermAnAllSearch: function () {
          return this.textSearchTerm?.narrowSearchBy === 'all'
        },
        isSearchTermAnEmrIdSearch: function () {
          return this.textSearchTerm?.narrowSearchBy === 'emr_id'
        },
        isSearchTermAnMrnSearch: function () {
          return this.textSearchTerm?.narrowSearchBy ==='mrn'
        },
        isSearchTermAPatientFirstNameSearch: function () {
          return this.textSearchTerm?.narrowSearchBy === 'patientFirstName'
        },
        isSearchTermAPatientLastNameSearch: function () {
          return this.textSearchTerm?.narrowSearchBy === 'patientLastName'
        }
      },
      methods: {
        makeDeepCopyOfFilters: function (obj) {
          return JSON.parse(JSON.stringify(this.sanitizeFilters(obj ? obj : this.mappedFilters || {})))
        },
        /**
         * Remove any filters that aren't associated with the Worklist or Archive.
         * 
         * @param {WORKLIST FILTERS} filters 
         */
        sanitizeFilters: function (filters) {
          if (this.namedRoute === 'Archive') {
            return {
              ...filters,
              filterByStatus: (filters.filterByStatus || []).filter(status => status >= 200 || status === 11)
            }
          }

          return {
            ...filters,
            filterByStatus: (filters.filterByStatus || []).filter(status => !this.hiddenStatuesFromWorklist.includes(status))
          }
        },
        isSearchPerformedForFinalizedDatesAndOtherFilters() {
          if (
              (this.mappedFilters &&this.mappedFilters.finalizedDateRange && this.mappedFilters.finalizedDateRange.length > 0) && (
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByPatientLastName) || 
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByPatientFirstName) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByMrn) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByAssigned) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByStatus) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByPayer) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterBySubmissionMethod) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByProvider) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterBySubmissionStatus) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.procedureDateRange) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.createdDateRange) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByCptCode) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByProcedureDescription) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByEmrId) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByDepartments) ||
              !this.isEmptyNullOrUndefined(this.mappedFilters.filterByFacility)
              )
              ) {
                return true;
              }
          return false;
        },
        isSearchPerfomedForFollowUpDatesAndOtherFilters() {
          if (this.mappedFilters.followUpDateRange.length > 0 && (
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByAssigned) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByStatus) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByFacility) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByPayer) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByProvider) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterBySubmissionMethod) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterBySubmissionStatus) ||
            !this.isEmptyDateRange(this.mappedFilters.procedureDateRange) ||
            !this.isEmptyDateRange(this.mappedFilters.createdDateRange) ||
            !this.isEmptyNullOrUndefined(this.mappedFilters.filterByDepartments)
          )) {
            return true;
          }
          return false;
        }
      }
  }
</script>

<style scoped lang="scss">
  .no-search-results {
    margin: 5rem auto;
    max-width: 520px;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #1B3668;
  }

  .no-search-results h2 {
      margin-bottom: 0.25em;
      font-size: 3em;
      color: $color-blue4;
    }

  .no-search-results p {
      font-size: 1.5em;
    }

  .no-search-results a {
      text-decoration: underline;
    }
</style>
<template>
    <div class="filterWrap">
        <label v-if="$slots.label">
            <slot name="label"></slot>
        </label>

        <div 
          v-if="id" 
          :id="id" 
          @change="handleOnChange"
          @beforeOpen="handleBeforeOpen"
          @afterOpen="handleAfterOpen"
          @reset="handleReset"
        ></div>
    </div>
</template>
  
<script>
import '../../node_modules/virtual-select-plugin/dist/virtual-select.min.js'
import '../../node_modules/virtual-select-plugin/dist/virtual-select.min.css'

import { VirtualSelectService } from '@/services/virtualSelect'
import NodeRenderer from '@/components/NodeRenderer'
import Vue from 'vue'

export default {
  name: "VirtualSelector",
  components: {  },
  props: {
    id: {
      type: String,
      required: true,
      default: 'virtual-select'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    mapper: {
        type: Function,
        required: true,
        default: () => {}
    },
    multiple: {
        type: Boolean,
        required: false,
        default: true
    },
    search: {
        type: Boolean,
        required: false,
        default: true
    },
    showValueAsTags: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedValue: {
        type: Array,
        required: true,
        default: () => [],
    },
    dataParser: {
        type: Function,
    },
    hideClearButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "bottom"
    },
    optionHeight: {
      type: String,
      default: '25px'
    },
    dropboxWidth: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      elementSelector: `#${this.id}`,
      virtualSelectService: null,
      isOpening: true,
      DOMElement: null,
      headerNode: null,
    };
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header
    }
  },
  methods: {
    init() {},
    mountHeader() {
      if (this.hasHeader) {
        const VirtualSelectHeader = Vue.extend(NodeRenderer)
        this.headerNode = new VirtualSelectHeader({
          propsData: {
            node: this.$slots.header
          }
        })

        this.headerNode.$mount()
      }
    },
    addHeader() {
      if (this.hasHeader) {
        this.virtualSelectService
            .el
            .querySelector('.vscomp-dropbox')
            .before(this.headerNode.$el)
      }
    },
    mount() {
      VirtualSelect.init({
        ele: this.elementSelector,
        multiple: this.multiple,
        search: this.search,
        placeholder: this.placeholder,
        selectedValue: this.selectedValue,
        options: this.list.map(this.mapper),
        showValueAsTags: this.showValueAsTags,
        enableSecureText: this.showValueAsTags,
        markSearchResults: true,
        silentInitialValueSet: true,
        alwaysShowSelectedOptionsCount: true,
        disableSelectAll: true,
        // searchByStartsWith: true,
        // autoFocus: true,
        setValueAsArray: true,
        // showSelectedOptionsFirst: true,
        // keepAlwaysOpen: true,
        hideClearButton: this.hideClearButton,
        position: this.position,
        disabled: this.disabled,
        dropboxWidth: this.dropboxWidth,
        optionHeight: this.optionHeight
      });

      this.virtualSelectService = new VirtualSelectService(this.elementSelector)
      this.virtualSelectService.el.querySelector('.vscomp-clear-icon')?.addEventListener('click', () => {
        this.$emit('input', [])
        this.$emit('clear', { field: this.id })
      })
    },
    unmount() {
      this.virtualSelectService.destroy()
    },
    handleOnChange(event) {
      const selectedValues = event.target.value

      if (this.multiple && selectedValues instanceof Array) {
        this.$emit('input', selectedValues.map(this.formatData))
      }

      if (!this.multiple) {
        this.$emit('input', [selectedValues].map(this.formatData))
      }
    },
    formatData(selectedValue) {
      if (this.dataParser) {
        return this.dataParser(selectedValue)
      }

      return selectedValue
    },
    handleBeforeOpen() {
      this.isOpening = true
    },
    handleAfterOpen() {
      this.isOpening = false
      this.$emit('open')
    },
    handleReset() {
      this.$emit('reset')
    }
  },
  created: function () {
    this.mountHeader()
  },
  mounted: function () {
    this.mount()
    this.addHeader()
  },
  unmount: function () {
    this.unmount();
  },
  watch: {
    list: {
      handler(next, prev) {
        if (this.virtualSelectService.el) {
          this.virtualSelectService.setOptions(next.map(this.mapper), true)
        }
      }
    },
  }
};
</script>
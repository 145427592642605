<template>
  <div class="editArea">
    <strong class="sectionLabel">Assigned To:</strong>
    <span class="nonSelected" v-if="!workerIsAssignable()">{{getAssignedName()}}</span>
    <div class="spinner" v-show="assigning">
      <i class="fa fa-spin fa-spinner"></i>  Updating
    </div>
    <select v-if="workerIsAssignable()" v-show="!assigning" v-model="selected" v-on:change="assignPa" :disabled="readOnlyUser || disabled || requestInUnAssignableState">
      <option disabled value="">Assign to</option>
      <option value="null">Unassigned</option>
      <option
        v-for="(worker, index) in assigneesList"
        :key="index"
        v-bind:value="parseInt(worker.paPersonId)"
        :disabled="worker.disabled || worker.systemManagedAccount">
          <span v-if="!worker.disabled && !worker.isValid">{{ worker.firstName }} {{ worker.lastName }} (inactive)</span>
          <span v-if="!worker.disabled && worker.isValid">{{ worker.firstName }} {{ worker.lastName }}</span>
          <!-- <span v-if="worker.disabled">{{worker.departmentName}}</span>
          <span v-if="!worker.disabled">{{worker.firstName}} {{worker.lastName}}</span> -->
          <!-- <span v-if="!worker.valid">{{worker.firstName}} {{worker.lastName}} (Inactive User)</span> -->
      </option>
    </select>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Assign',
  props: ['paRequest', 'paPersonId', 'disabled'],
  data: function(){
    return {
      selected: '',
      assigning: false,
    }
  },
  methods: {
    assignPa: async function(event){
      this.assigning = true
      let assignment = event.target.value
      if(assignment === 'null') assignment = null

      let params = {
        paRequestId: this.paRequest.paRequestId,
        assignedToId: assignment
      }
      this.$emit('updating', this.paRequest.paRequestId)

      try {
        await this.$store.dispatch('UPDATE_ASSIGNED_TO_ID', params)
        this.$emit('assigned')
        this.$emit('finishedUpdating', this.paRequest.paRequestId)
        this.assigning = false
      } catch(error) {
        this.assigning = false
        this.$emit('finishedUpdating', this.paRequest.paRequestId)
      }
    },
    setAssignment: function() {
      if(this.paRequest.assignedToId || this.paRequest.assignedToId === 0 || this.paRequest.assignedToId == null){
        this.selected = this.paRequest.assignedToId
      }
      // window.console.log(this.assigning, 'SET ASSIGNMENT')
      this.$emit('finishedUpdating', this.paRequest.paRequestId)
      this.assigning = false
    },
    getAssignedName: function(){
      const assigned = this.workers.filter(x => {
        return x.paPersonId === this.paPersonId
      })[0]

      if(assigned) return assigned.firstName + ' ' + assigned.lastName
    },
    workerIsAssignable: function(){
      const assignable = this.assigneesList.filter( x => {
        return x.paPersonId === this.paPersonId
      })
      const knownWoker = this.workers.filter(x => {
        return x.paPersonId === this.paPersonId
      })
      const disabledWorker = this.disabledWorkers.find( d => {
        return d.paPersonId === this.paPersonId
      })

      if(assignable.length > 0 || this.paPersonId === null || this.paPersonId === undefined) return true
      if(disabledWorker && this.userRole.includes('Manager')) {
        this.$store.commit('SET_GLOBAL_MESSAGE', ['error', `Request #${this.paRequest.paRequestId} for ${this.paRequest.mrn} has been assigned to a user that is no longer valid.`])
        return true
      }
      if(this.workers.length && knownWoker.length === 0){
        this.$store.commit('SET_GLOBAL_MESSAGE', ['error', `Request #${this.paRequest.paRequestId} for ${this.paRequest.mrn} has been assigned to an Unknown user.`])
        return true
      }
      return false
    }
  },
  mounted: function(){
    if(this.paRequest.assignedToId || this.paRequest.assignedToId === 0 || this.paRequest.assignedToId == null){
      this.selected = this.paRequest.assignedToId || 'null'
    }
  },
  destroyed: function(){
    this.$emit('finishedUpdating', this.paRequest.paRequestId)
  },
  computed: {
    ...mapState(
      ['workers', 'requests', 'currUser', 'statusList']
    ),
    ...mapGetters(
      ['userRole', 'assignees', 'assigneesWithDepartments', 'readOnlyUser', 'disabledWorkers', 'assigneesByDepartment']
    ),
    assigneesList: function(){
      const workersInDept =  this.assigneesByDepartment.find( d => d.departmentId === this.paRequest.departmentId)
      const workerIsDeactivated = this.disabledWorkers.find( dw => dw.paPersonId === this.paRequest.assignedToId )

      if (workerIsDeactivated) {
        return [...workersInDept.assignees, workerIsDeactivated]
      }

      return workersInDept.assignees
    },
    requestInUnAssignableState: function () {
      // if the pa status is in an Auto Submission In Progress state
      return this.paRequest.status === 9
    }
  },
  watch: {
    'paPersonId': {
      handler (val) {
        this.setAssignment()
      },
      deep: true
    },
  }
}
</script>

export const DISPLAY_CONFIGS = [
    {
        "header": "Customers",
        tab: "advancedConfig",
        path: null,
        configs: {
            customerId: {
                inputType: "text",
                description: "This is the ID assigned to the customer when it is created in Pathway."
            },
            name: {
                name: "customerName",
                inputType: "text",
                label: "Customer Name",
                description: "The customer name as it will be displayed within the UI."
            },
            allowEditDepartments: {
                inputType: "toggle",
                description: "Defines if the Department names and descriptions are editable within the UI.  This should be set to Yes unless otherwise specified."
            },
            allowEligibilityCheck: {
                inputType: "toggle",
                label: "Use Pathway Elegibility",
                description: "Defines if the Pathway native Eligibility functionality is enabled.  This should be set to No unless otherwise specified."
            },
            twoFactorAuthRequired: {
                inputType: "toggle",
                label: "Require 2FA",
                description: "Defines is the user loging require Two Factor Authentication.  This should be set to Yes unless otherwise specified."
            },
            allowAutoDocumentAttachment: {
                inputType: "toggle",
                label: "AI Packaging",
                description: "Allow documents to be automatically atttached to the document package."
            },
            autoAssignPaRequests: {
                inputType: "toggle",
                label: "Enable UI User Assignment Rules",
                description: "Enables the User Assignment rules/tab within the UI.  This should be Yes unless otherwise specified."
            },
            worklistVersionId: {
                inputType: "text"
            },
            startWorkingHourUtc: {
                inputType: "text",
                label: "PA Team Start Time (UTC)",
                description: "The time the PA team at the customer typically start working in UTC"
            },
            endWorkingHourUtc: {
                inputType: "text",
                label: "PA Team Stop Time (UTC)",
                description: "The time the PA team at the customer typically stop working in UTC."
            },
            allowAutoSubmit: {
                inputType: "toggle",
                description: "Defines if Auto Submission automation is enabled.  This should be no unless otherwise specified."
            },
            autoSetNoPaNeeded: {
                inputType: "toggle"
            },
            allowDirectSubmit: {
                inputType: "toggle",
                label: "Enable Direct Submission",
                description: "Defines if Direct submission is enabled.  This should be no unless otherwise specified.  As of 2/24/23, this is applicable only to Florida Blue."
            },
            allowAuthStatusing: {
                inputType: "toggle",
                label: "Enable Statusing",
                description: "Allow commincation to/from the Auth Status processes"
            },
            allowAuthDetermination: {
                inputType: "toggle",
                label: "Enable Determination",
                description: "Allow commincation to/from the Auth determination processes"
            },
            faxConfidentialityStatement: {
                inputType: "text",
                label: "Fax Statement",
                description: "Verbiage that is displayed on the Fax Cover Sheet when using the Olive Efax."
            },
            postAuthMonitoringType: {
                inputType: "text",
                label: "PAM Type"
            },
            organizationId: {
                inputType: "text",
                description: "This is the ID assigned to the customer in Salesforce/AlphaSuite and is used as the common value across products and for reporting."
            },
            isTaskRunnerOnlyCustomer: {
                inputType: "toggle",
                label: "Det/Status ONLY",
                description: "Defines if the customer is used only to pass data between the EHR and the Determination and/or Status Task Runners."
            },
            includeTimeWithProcedureDate: {
                inputType: "toggle",
                label: "Display Procedure Time",
                description: "Defines if the Time will be displayed as part of the Procedure Date.  The default is No."
            },
            defaultTimeZone: {
                inputType: "text",
                description: "Defines the time zone of the customer and users."
            },
            useDepartmentRoutingRules: {
                inputType: "toggle",
                label: "Activate Team Routing Rules",
                description: "Defines if the Department/Team routing rules are activated in the UI.  Default should be Yes."
            },
            directSubmitTypes: {
                inputType: "json",
            }
        }
    },
    {
        header: "Customer System Settings",
        tab: "advancedConfig",
        path: "customerSystemSettingsConfigViewModel",
        configs: {
            completedInEmrAutomation: {
                inputType: "toggle",
                label: "Enable Completed in EMR",
                description: "Setting to automatically close Pathway cases when a referral status is updated in Epic.  When we get a 278 message, we check the referral status.  If the status is one that you have told us is considered Final, we update the Pathway status to \"Completed in EMR\" and archive the case.  We do not send a message to the EHR to ensure that the referral status is inadvertently overwritten.  Typically, this is enabled when EHR users that are not on the Prior Auth team update referral statuses on a consistent basis.  Note: this is applicable to Referrals only and not Auth Certs. Please confirm if this should be enabled."
            },
            unArchiveFromEmrAutomation: {
                inputType: "toggle",
                label: "Enable Unarchive from EHR",
                description: "Setting to automatically update a case status from a final status to Created and move it back to the PA Worklist based on the referral status.   We typically see this enabled when the referral status is being updated from a final status to a non-final status either automatically or manually.  For example, a user may change the location and then update the status to Pending.  Please confirm if this should be enabled and provide a list of the referral statuses and which ones you consider to be open vs closed."
            },
            authDeterminationMessageType: {
                inputType: "text",
            },
            completedInEmrStatuses: {
                inputType: "json",
                label: "Completed in EHR Statuses",
                description: "If Completed in EMR is enabled, the list of the referral statuses that should close the Pathway case."
            },
            activeInEmrStatuses: {
                inputType: "json",
                label: "EHR Unarchive statuses",
                description: "If Unarchive from EMR is enabled, the list of the referral statuses that should re-open the Pathway case."
            },
        }
    },
    {
        header: "Emr Response Customer Configs",
        tab: "advancedConfig",
        path: "emrResponseCustomerConfigurationViewModel",
        configs: {
            emrResponseType: {
                inputType: "text",
                description: "Defines the message format for the response to the EHR"
            },
            responseDestination: {
                inputType: "toggle",
                label: "EMR Response Destination",
                description: "This is the target URL for the Mirth Interface Engine which will send the 278 messages to the customer"
            },
            isActive: {
                inputType: "toggle",
                label: "Writebacks Active",
                description: "Defines if the writebacks are active to send to the customer"
            },
        }
    },
    {
        tab: "advancedConfig",
        path: "emrResponseCustomerConfigurationViewModel.additionalWritebackInfo",
        configs: {
            commentsSendBack: {
                inputType: "toggle",
                label: "Send User Notes",
                description: "Determines if User Entered Comments will be sent to the EHR.  This should be Yes unless otherwise specified."
            },
            unarchiveSendBack: {
                inputType: "toggle",
                label: "Send Note on Unarchive",
                description: "Determines whether a 278 will be sent to the EHR when a case is Unarchived based on the UnArchiveFromEMRAutomation"
            },
            commentsSendBackHCR01: {
                inputType: "text",
                label: "Pending Status Value",
                description: "Determines what value will be sent back in the HCR01 segment when a Commnet/Note is added in Pathway"
            },
            commentsSendBackHCR03: {
                inputType: "text",
                label: "Pending Reason Value",
                description: "Determines what value will be sent back in the HCR03 segment when a Commnet/Note is added in Pathway"
            },
            noAuthRequiredAuthNumber: {
                inputType: "text",
                label: "No PA Required Auth #",
                description: "Determines the value that will be send in the HCR02 segment when the Pathway case status is \"Pa Is Unnecessary\""
            },
            excludeEmrStatuses: {
                inputType: "json",
                description: "Defines the Request final statuses what will not send a 278 when the status is set by the system."
            },
            ignorableErrors: {
                inputType: "json",
            },
            ignorableDepartments: {
                inputType: "json",
            },
        },
    },
    {

        header: "Contact Info",
        tab: "advancedConfig",
        path: "customerContactInformationViewModel",
        configs: {
            company: {
                inputType: "text",
                description: "Default Provider Contact Company Name"
            },
            addressLine1: {
                inputType: "text",
                description: "Default Provider Contact Address 1"
            },
            addressLine2: {
                inputType: "text",
                description: "Default Provider Contact Address 2"
            },
            city: {
                inputType: "text",
                description: "Default Provider Contact City"
            },
            state: {
                inputType: "text",
                description: "Default Provider Contact State"
            },
            zip: {
                inputType: "text",
                description: "Default Provider Contact Zip"
            },
            website: {
                inputType: "text",
                description: "Default Provider Contact Website"
            },
            email: {
                inputType: "text",
                description: "Default Provider Contact Email"
            },
            phone: {
                inputType: "text",
                description: "Default Provider Contact Phone"
            },
            extension: {
                inputType: "text",
                description: "Default Provider Contact Ext"
            },
            fax: {
                inputType: "text",
                description: "Default Provider Contact Fax"
            },
            lastModifiedBy: {
                inputType: "text"
            }
        },
    },
    {
        header: "Auth Status Settings",
        tab: "advancedConfig",
        path: "",
        configs: {
            authStatusSettingsList: {
                inputType: "json",
            }
        },
    },
    {

        header: "Agent Customer",
        tab: "advancedConfig",
        path: "",
        configs: {
            agentCustomerConfigList: {
                inputType: "json",
            }
        },
    },
    {
        header: "Writeback To Customer",
        tab: "advancedConfig",
        path: "writeBackToCustomerConfigViewModel",
        configs: {
            authStatusingStatuses: {
                inputType: "json",
            },
            authDeterminationStatuses: {
                inputType: "json",
            },
        },
    },
    {
        tab: "ingestConfig",
        configs: {
            dataIngestCustomerConfiguration: {
                inputType: "json",
            },
        },
    },
]
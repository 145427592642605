<template>
  <div>
    <div class="manageTable" v-if="manageTable">
      <div class="manageInner">
        <h3>Manage Worklist Display</h3>
        <div class="flagList">
          <h4>Display Notifications For:</h4>
          <label class="flagItem" v-for="flag in flagReference" :key="flag">
            <input
              type="checkbox"
              :id="flag"
              :value="flag"
              v-model="updatedFlags"
            />
            <span>{{ labelFromPascal(flag) }}</span>
          </label>
        </div>
        <div class="columnSort">
          <h4>Worklist Column Order <span>(drag to reorder)</span></h4>
          <Draggable
            v-model="columnChangeModel"
            class="dragWrap"
            v-bind="dragOptions"
            @start="dragStart"
            @end="dragEnd"
          >
            <transition-group
              tag="ul"
              type="transition"
              :name="!dragging ? 'flip-list' : null"
            >
              <li
                v-for="col in columnChangeModel"
                class="list-group-item"
                :class="{
                  notVisible: !updatedColumns.includes(col.id),
                  changedVisibility:
                    (!col.visible && updatedColumns.includes(col.id)) ||
                    (col.visible && !updatedColumns.includes(col.id)),
                  disabled: col.id === 'assignedToId',
                }"
                :key="col.id + 'drag'"
              >
                <label>
                  <span v-if="col.id != 'urgency'">{{ col.name }}</span>
                  <span v-if="col.id == 'urgency'">Notifications</span>
                  <input
                    type="checkbox"
                    :id="col.id"
                    :value="col.id"
                    v-model="updatedColumns"
                    @change="dragEnd()"
                    :disabled="col.id === 'assignedToId'"
                  />
                </label>
              </li>
            </transition-group>
          </Draggable>
        </div>
      </div>
      <div class="actions">
        <button
          v-if="
            !columnsAreDefault ||
            (worklistDisplaySettings.hiddenNotificationFlags &&
              worklistDisplaySettings.hiddenNotificationFlags.length)
          "
          @click="resetToDefaultDisplaySettings"
          :disabled="updatingDisplaySettings"
        >
          Reset to Default View
        </button>
        <button
          @click="cancelUpdateDisplaySettings"
          v-if="columnsHaveChanged || !notificationsHaveChanged"
          :disabled="updatingDisplaySettings"
        >
          Discard Changes
        </button>
        <button
          :disabled="updatingDisplaySettings"
          v-if="columnsHaveChanged || !notificationsHaveChanged"
          @click.prevent="saveDisplaySettings"
        >
          Save Changes
        </button>
        <button
          :disabled="updatingDisplaySettings"
          v-if="!columnsHaveChanged && notificationsHaveChanged"
          @click="toggleTableView"
        >
          Close
        </button>
      </div>
    </div>
    <!-- </transition> -->
    <div class="tableHeader">
      <form class="search" v-on:submit.prevent="searchPaRequests">
        <span class="requestsCount"
          ><span v-show="hasNoRequestsCount">0</span
          ><strong v-show="hasRequestsCount">{{
            getRequestCountMessage
          }}</strong>
          of {{ allRequestsCount }} requests</span
        >
        <span class="searchField">
          <input
            name="query"
            type="search"
            autocomplete="off"
            placeholder="Find In Requests"
            :value="searchValue"
            @input="handleSearchValue"
          />
        </span>

        <div class="searchOptionsField">
          <VirtualSelect
            v-model="narrowSearchTermSearchBy"
            v-testid="'narrowed-search-term-select'"
            :selectedValue="narrowSearchTermSearchBy"
            :loading="!narrowedSearchTermOptions.length"
            :list="narrowedSearchTermOptions"
            :multiple="false"
            :search="false"
            :hideClearButton="true"
            :mapper="narrowedSearchTermOption => ({
              label: narrowedSearchTermOption.label,
              value: narrowedSearchTermOption.value
            })"
            placeholder="Select"
            id="narrowSearchTermSearchBy"
          >
          </VirtualSelect>
        </div>

        <button
          class="clearSearch mr-1"
          v-show="searchValue == '' || searchValue != filterKey || previousSearchTermSearchBy[0] != narrowSearchTermSearchBy[0]"
          :disabled="!searchValue"
          type="submit"
        >
          Search
        </button>
        <button class="clearSearch" v-if="filterKey || textSearchTerm?.filterKey" @click="clearSearch">
          Clear Search
        </button>
      </form>
      <span class="rightSide">
        <div class="multi-sort-toggle">
          <span class="inline-block mr-0.75 mt-0.5">Multi Sort</span>
          <label class="toggleSwitch inline-block mr-2">
            <span>Off</span>
            <input type="checkbox" :checked="multiSortActive" @change="multiSortHandler"/>
            <span class="track">
              <span class="knob"></span>
            </span>
            <span>On</span>
          </label>
        </div>

        <a 
          href="#" 
          @click.prevent="toggleTableView"
          class="manageDisplay"
          :class="{ hasHiddenSort: hasNonVisibleSavedSortApplied }"
        >
          Manage Display <i class="fas fa-cog"></i>
        </a>

        <button 
          class="excel-download-button" 
          type="button" 
          aria-label="Download a non-PHI version of your current filtered results"
          title="Download a non-PHI version of your current filtered results"
          @click="displayDownloadFilteredRequestsModal = true"
          v-if="!currUser.listOfRoles.includes('HospitalViewer')"
        >
          <i class="fas fa-file-excel"></i>
          Export
        </button>
      </span>
    </div>

    <table
      v-if="requestList.length"
      class="workItems resultTable workItems_v2"
      :class="{ 
        readonly: readonly,
        multisort: multiSortActive
      }"
    >
      <thead>
        <tr>
          <th
            v-for="col in visibleColumns"
            @click="sortBy(columnReference[col].sort)"
            class="tableHeading"
            :data-active-position="getActivePosition(columnReference[col].sort)"
            :class="[
              { 
                active: columnIsActive(columnReference[col].sort),
                hasActivePosition: getActivePosition(columnReference[col].sort)
              },
              columnReference[col].sort,
              readOnlyUser ? 'readOnlyHeader' : ''
            ]"
            :key="col"
          >
            <span
              class="arrow"
              :class="[
                sortOrders[columnReference[col].sort] < 0 ? 'dsc' : 'asc',
                col,
              ]"
            >
              <span v-if="col != 'urgency'">{{
                columnReference[col].name
              }}</span>
              <span v-if="col == 'urgency'">&nbsp;</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="theWorklist-item"
          v-bind:class="[
            {
              hasAlert:
                alertNotificationText(
                  request.notificationFlags,
                  request.status
                ) || 
                request.status === 199 ||
                calendarNotificationText(request.notificationFlags) ||
                codeNotificationText(request.notificationFlags)
            },
            notificationFlagClasses(request.notificationFlags),
            {
              updatingRequest: updatingRequests.includes(request.paRequestId),
              editing: showSelectsFor == request.paRequestId,
            },
            addWorkerClass(request.assignedToId),
            addStatusClass(request.status),
            addUpdateClass(request.notificationFlags),
          ]"
          v-for="(request, index) in requestList"
          v-bind:key="request.paRequestId"
        >
          <td
            v-for="col in visibleColumns"
            :class="[
              col,
              {
                readOnlyUser:
                  readOnlyUser ||
                  (col == 'assignedToId' &&
                    !assigneeIds.includes(request.assignedToId) && 
                    request.hasOwnProperty('assignedToId') &&
                    request.assignedToId !== null),
              },
              colTableSide(col),
            ]"
            :key="col"
          >
            <span
              v-if="col === 'urgency'"
              @click="navigate(request.paRequestId, index)"
              class="tableCell"
            >
              <i
                v-if="
                  request.urgency === URGENCY_URGENT_NOW &&
                  request.status !== STATUS_AI_ANALYSIS
                "
                class="fas fa-chevron-double-up"
              ></i>
              <i
                v-if="
                  request.urgency === URGENCY_URGENT &&
                  request.status !== STATUS_AI_ANALYSIS
                "
                class="fas fa-chevron-up"
              ></i>
              <i
                v-if="
                  alertNotificationText(
                    request.notificationFlags,
                    request.status
                  ) && request.status !== STATUS_AI_ANALYSIS
                "
                class="fas fa-exclamation-triangle"
              ></i>
              <i
                v-if="calendarNotificationText(
                    request.notificationFlags,
                  )"
                class="fas fa-calendar error-color2"
              ></i>
              <i class="fas fa-notes-medical error-color2"
                v-if="codeNotificationText(
                  request.notificationFlags,
                )"
              >
              </i>
              <i
                v-if="
                  infoNotificationText(
                    request.notificationFlags,
                    request.status
                  ) && 
                  request.status !== STATUS_AI_ANALYSIS
                "
                class="fas fa-bell"
              ></i>
              <div
                v-if="
                  (
                    request.status !== STATUS_AI_ANALYSIS && 
                    (
                      alertNotificationText(request.notificationFlags, request.status) ||
                      infoNotificationText(request.notificationFlags) ||
                      calendarNotificationText(request.notificationFlags) ||
                      codeNotificationText(request.notificationFlags)
                    )
                  )
                "
                class="moreInfo"
              >
                <div
                  v-html="
                    alertNotificationText(
                      request.notificationFlags,
                      request.status
                    )
                  "
                  class="hasAlertText"
                ></div>
                <div
                  v-html="infoNotificationText(request.notificationFlags)"
                  class="hasInfoText"
                ></div>
                <div 
                  v-html="calendarNotificationText(request.notificationFlags)"
                  class="calendarNotificationEvent"
                ></div>
                <div 
                  v-html="codeNotificationText(request.notificationFlags)"
                  class="codeNotificationEvent"
                ></div>
              </div>
              <span
                v-if="request.status === STATUS_AI_ANALYSIS"
                class="spinner"
              >
                <i class="far fa-spinner fa-spin"></i>
              </span>
            </span>
            <span
              v-else-if="col === 'patient'"
              class="tableCell"
              @click="navigate(request.paRequestId, index)"
            >
              <span>{{ `${request.patientFullName}` }}</span>
              <span>{{ `${removeTime(request.dob)} - ${request.mrn}` }}</span>
            </span>
            <span
              v-else-if="col === 'orderingProviderName'"
              class="tableCell"
              @click="navigate(request.paRequestId, index)"
            >
              <span>{{ request.orderingProviderName }}</span>
              <span v-if="request.orderingProviderNPI">{{
                request.orderingProviderNPI
              }}</span>
              <span v-if="request.orderingProviderInActive">(inactive)</span>
            </span>
            <span
              style="min-width: 9rem"
              class="tableCell"
              v-else-if="col === 'toBeDoneDate'"
              @click="navigate(request.paRequestId, index)"
            >
              <span v-if="!customerSettings.includeTimeWithProcedureDate">{{
                formatTime(request[col])
                }}</span>
              <span v-if="customerSettings.includeTimeWithProcedureDate">{{
                formatTimeAndDate(request[col])
                }}</span>
            </span>

            <span
              style="min-width: 9rem"
              class="tableCell"
              v-else-if="col === 'createdDate'"
              @click="navigate(request.paRequestId, index)"
            >
              {{ formatDateText(request[col]) }}<br />{{
                formatTimeText(request[col])
              }}
            </span>

            <span
              class="tableCell worklistToolTip"
              v-else-if="col === 'submission'"
              @mouseover="mouseOverItem(request, 'submission')"
              @mouseleave="mouseLeaveItem()"
              @click="navigate(request.paRequestId, index)"
            >
              <more-info-banner
                v-if="
                  request &&
                  request.submissionInfos &&
                  request.submissionInfos.length &&
                  request.submissionInfos.find(subInfo => {
                    return (
                      subInfo.submissionMethod === 'VerataFax' ||
                      subInfo.faxStatus ||
                      subInfo.portalStatusCheck
                    ) 
                  })
                "
                :show="hoveredRequest == request && hoveredItem == 'submission'"
              >
                <div>
                  <template v-for="(subInfo, index) in request.submissionInfos">
                    <!-- PAthway Fax status -->
                    <div
                      v-if="subInfo.submissionMethod === 'VerataFax'"
                      :key="subInfo.submissionId"
                      :style="index === 1 ? { margin: '0.5rem 0' } : {}"
                    >
                      <span
                        ><b>PAthway Fax:</b>{{ index === 1 ? "&nbsp;(2nd)" : ""
                        }}{{ index === 2 ? "&nbsp;(3rd)" : "" }}</span
                      >&nbsp;<span
                        >{{ subInfo.submissionStatus }}&nbsp;{{
                          subInfo.submissionUpdated | formatDateTime
                        }}</span
                      >
                    </div>
                    <!-- PAthway Fax status -->

                    <!-- portal status checks -->
                    <span 
                      :key="`portalStatusCheck-${subInfo.submissionId}`" 
                      class="submissionDetail" 
                      v-if="subInfo.portalStatusCheck"
                    >
                      <span
                        ><b>Portal Status Check:</b>{{ index === 1 ? "&nbsp;(2nd)" : ""
                        }}{{ index === 2 ? "&nbsp;(3rd)" : "" }}</span
                      >

                      {{
                        subInfo.portalStatusCheck
                          ? subInfo.portalStatusCheck.name
                          : "Not Set"
                      }}
                      <span
                        v-if="
                          !nonPortalMethods.includes(subInfo.submissionMethod) &&
                          subInfo.submissionUpdated != null
                        "
                      >
                      - {{ formatDateMMDDYYYY(subInfo.submissionUpdated) }}
                      </span>
                    </span>
                    <!-- portal status checks -->

                    <!-- fax status checks -->
                    <span 
                      :key="`faxStatus-${subInfo.submissionId}`" 
                      class="submissionDetail" 
                      v-if="subInfo.faxStatus"
                    >
                      <span
                        ><b>Fax Status{{ index === 1 ? "&nbsp;(2nd)" : ""
                        }}{{ index === 2 ? "&nbsp;(3rd)" : "" }}:</b></span
                      >

                      {{
                        subInfo.faxStatus
                          ? subInfo.faxStatus.name
                          : ""
                      }}
                      <span
                        v-if="
                          subInfo.submissionUpdated != null
                        "
                      >
                      - {{ formatDateMMDDYYYY(subInfo.faxStatus.statusAsOf) }}
                      </span>
                    </span>
                    <!-- fax status checks -->
                  </template>
                </div>
              </more-info-banner>

              <!-- PAthway Fax status -->
              <template
                v-for="(subInfo, index) in (
                  request.submissionInfos || []
                ).filter((si) => si.submissionMethod !== '')"
              >
                <div
                  :key="subInfo.submissionId"
                  class="submissionStatusRow"
                  :class="`submissionCategory-${subInfo.uiSubmissionCategoryId}`"
                >
                  {{ index === 1 ? "2nd:" : "" }}{{ index === 2 ? "3rd:" : "" }}
                  <span v-if="subInfo.submissionMethod !== 'VerataFax'">{{
                    subInfo.uiSubmissionMethod
                  }}</span>
                  <span v-if="subInfo.submissionMethod === 'VerataFax'">
                    PAthway Fax{{` - ${subInfo.uiSubmissionCategoryName}`}}
                  </span>
                </div>
              </template>
              <!-- PAthway Fax status -->
            </span>

            <span
              class="tableCell"
              v-else-if="col === 'followUpDate'"
              @click="showSelects($event, request, index)"
            >
              <div
                style="min-width: 7rem"
                v-if="
                  !updatingRequests.includes(request.paRequestId) &&
                  showSelectsFor !== request.paRequestId
                "
              >
                {{ formatTime(request.followUpDate) }}
              </div>
              <FollowupDate
                @updating="updating"
                @finishedUpdating="finishedUpdating"
                v-if="
                  updatingRequests.includes(request.paRequestId) ||
                  showSelectsFor === request.paRequestId
                "
                :paRequest="request"
                :paFollowUpDate="request.followUpDate"
                :key="'f' + request.paRequestId"
              />
            </span>

            <span
              class="tableCell line-break"
              v-else-if="col === 'notes'"
              @mouseover="mouseOverItem(request, 'notes')"
              @mouseleave="mouseLeaveItem()"
            >
              <more-info-banner
                v-if="!isEmptyNullOrUndefined(getLastUserComment(request))"
                :show="
                  hoveredRequest === request &&
                  hoveredItem === 'notes' &&
                  getLastUserComment(request)
                "
              >
                <strong>Last User Note:&nbsp;</strong>
                <span class="last-notes-text">{{ getNotesText(request) }}</span>
                <div
                  class="see-more-text"
                  @click="navigate(request.paRequestId, index)"
                >
                  see more
                </div>
              </more-info-banner>

              <div
                style="min-width: 7rem"
                v-if="
                  !updatingRequests.includes(request.paRequestId) ||
                  showSelectsFor !== request.paRequestId
                "
              >
              <i
                v-if="request.status !== STATUS_AI_ANALYSIS && 
                  request.lastUserComment && 
                  request.notificationFlags.includes('NewNote')"
                class="fas fa-comment-alt-lines"
                title="New Note"
              ></i>
                {{
                  getShortNotesText(request).substring(0, 60) +
                  (getShortNotesText(request).length > 60 ? "..." : "")
                }}
              </div>
            </span>

            <span
              class="tableCell"
              v-else-if="col === 'assignedToId'"
              @click="showSelects($event, request, index)"
            >
              <div
                v-if="
                  !updatingRequests.includes(request.paRequestId) &&
                  showSelectsFor !== request.paRequestId
                "
              >
                <span v-if="request.assignedToFirstName"
                  >{{ request.assignedToFirstName }}
                  {{ request.assignedToLastName }}</span
                >
                <span v-if="!request.assignedToFirstName">Unassigned</span>
              </div>
              <Assign
                @updating="updating"
                @finishedUpdating="finishedUpdating"
                v-if="
                  updatingRequests.includes(request.paRequestId) ||
                  showSelectsFor === request.paRequestId
                "
                :paRequest="request"
                :paPersonId="request.assignedToId"
                :key="'a' + request.paRequestId"
              />
            </span>
            <span
              class="tableCell"
              v-else-if="col === 'status'"
              @click="showSelects($event, request, index)"
            >
              <div
                v-if="
                  !updatingRequests.includes(request.paRequestId) &&
                  showSelectsFor !== request.paRequestId
                "
              >
                {{ getStatusName(request.status) }}
              </div>
              <Status
                @updating="updating"
                @finishedUpdating="finishedUpdating"
                v-if="
                  updatingRequests.includes(request.paRequestId) ||
                  showSelectsFor === request.paRequestId
                "
                :paRequest="request"
                :paStatus="request.status"
                :key="'s' + request.paRequestId"
              />
            </span>
            <span
              class="tableCell"
              v-else-if="col === 'primaryInsuranceCompanyName'"
              @click="navigate(request.paRequestId, index)"
              @mouseover="mouseOverItem(request, 'primaryInsuranceCompanyName')"
              @mouseleave="mouseLeaveItem()"
            >
              <span>{{ request.primaryInsuranceCompanyName }}</span>
              <span
                v-if="
                  request.secondaryInsuranceCompanyName &&
                  request.secondaryInsuranceCompanyName != '' &&
                  request.secondaryInsuranceCompanyName != ' '
                "
                >2nd: {{ request.secondaryInsuranceCompanyName }}</span
              >

              <span
                v-if="
                  request.tertiaryInsuranceCompanyName &&
                  request.tertiaryInsuranceCompanyName != '' &&
                  request.tertiaryInsuranceCompanyName != ' '
                "
                >3rd: {{ request.tertiaryInsuranceCompanyName }}</span
              >
              <more-info-banner :show="hoveredRequest == request && hoveredItem == 'primaryInsuranceCompanyName'">
                <div style="width: 250px">
                  <b>Plan Name(s)</b>
                  <div>{{ request.primaryPlanName}}</div>
                  <div v-if="request.secondaryPlanName &&
                    request.secondaryPlanName != '' &&
                    request.secondaryPlanName != ' '">
                     <b>2nd:</b> {{ request.secondaryPlanName }}
                  </div>

                  <div v-if="request.tertiaryPlanName &&
                    request.tertiaryPlanName!= '' &&
                    request.tertiaryPlanName != ' '">
                      <b>3rd:</b> {{ request.tertiaryPlanName}}
                  </div>
                
                  <div class="see-more-text"
                    @click="navigate(request.paRequestId, index)">
                      see more...
                  </div>
                </div>
              </more-info-banner>
            </span>
            <span
              class="tableCell"
              v-else-if="col === 'medicalStemp'"
              @click="navigate(request.paRequestId, index)"
              @mouseover="mouseOverItem(request, 'medicalStemp')"
              @mouseleave="mouseLeaveItem()"
            >
              <span
                v-for="(cpt, index) in getCptCodes(request)"
                :key="`${cpt.code}-${index}`"
                >{{ cpt.code }} {{ index > 0 == cpt ? "..." : "" }}</span
              >
              <more-info-banner
                :show="
                  hoveredRequest == request && hoveredItem == 'medicalStemp'
                "
              >
                <div style="width: 400px">
                  <div style="font-weight: bold">
                    Procedure:<span style="font-weight: normal">
                      {{ request.procedureDescription }}</span
                    >
                  </div>
                  <div
                    v-for="(cpt, index) in getCptCodes(request)"
                    style="font-weight: bold"
                    :key="`${cpt.code}-${index}`"
                  >
                    {{ cpt.code
                    }}<span style="font-weight: normal" v-if="cpt.description">{{
                      " - " + cpt.description
                    }}</span>
                  </div>
                  <div
                    class="see-more-text"
                    @click="navigate(request.paRequestId, index)"
                  >
                    see more...
                  </div>
                </div>
              </more-info-banner>
            </span>
            <span
              class="tableCell"
              v-else-if="col === 'facilityID'"
              @click="navigate(request.paRequestId, index)"
            >
              <span>{{ request.facilityName }}</span
              >
            </span>
            <span
              class="tableCell"
              v-else
              @click="navigate(request.paRequestId, index)"
              ><div style="min-width: 6rem">
                {{ request[col] }}
              </div></span
            >
            <i
              @click="closeSelects(request.paRequestId)"
              v-if="
                showSelectsFor === request.paRequestId &&
                !updatingRequests.includes(request.paRequestId)
              "
              class="closeBtn fa fa-times-circle"
            >
            </i>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="pagination" v-if="requestList.length">
      <div class="leftSide">
        <div class="pages">
          <span
            class="pageSelect"
            v-bind:class="{ currentPage: currentPage === 1 }"
            @click="setCurrentPage(1)"
          >
            1
          </span>
          <span
            class="pageSelect ellipsis"
            v-if="pageCount > 7 && currentPage > 4"
            disabled="disabled"
            >&#8230;</span
          >
          <span
            class="pageSelect"
            v-for="p in pageButtons"
            :key="p"
            v-bind:class="{ currentPage: currentPage === p }"
            @click="setCurrentPage(p)"
          >
            {{ p }}
          </span>
          <span
            class="pageSelect ellipsis"
            v-if="pageCount > 7 && currentPage < pageCount - 3"
            disabled="disabled"
            >&#8230;</span
          >
          <span
            v-if="pageCount > 1"
            class="pageSelect"
            v-bind:class="{ currentPage: currentPage === pageCount }"
            @click="setCurrentPage(pageCount)"
          >
            {{ pageCount }}
          </span>
        </div>
      </div>
      <div class="rightSide" v-if="this.displayType !== 'recent'">
        <select v-model="requestsPerPage" @change="setRequestsPerPage">
          <option
            :value="requestsPerPage"
            v-if="
              requestsPerPage != 10 &&
              requestsPerPage != 20 &&
              requestsPerPage != 50
            "
            disabled
            selected
          >
            Custom
          </option>
          <option value="10">Show 10 Requests</option>
          <option value="20">Show 20 Requests</option>
          <option value="50">Show 50 Requests</option>
        </select>
        <input
          type="number"
          :value="requestsPerPage"
          @input="updateRequestsPerPage"
          placeholder="Custom Number"
          min="1"
          max="150"
        />
      </div>
    </div>
    <div v-if="!requestList.length && !fetchingRequests">
      <NoSearchResultsVue
        :filters="filters"
        :searchValue="searchValue"
        namedRoute="Archive"
      >
      </NoSearchResultsVue>
    </div>

    <DownloadFilteredRequestsModal
      v-if="displayDownloadFilteredRequestsModal"
      @close="displayDownloadFilteredRequestsModal = !displayDownloadFilteredRequestsModal"
      @download="downloadFilteredRequestHandler"
    />
  </div>
</template>

<script>
import FollowupDate from "@/components/FollowupDate";
import ENV_CONFIG from "@/config/environment";
import axios from "axios";
import moment from "moment";
import Draggable from "vuedraggable";
import { mapGetters, mapState } from "vuex";
import Assign from "./Assign";
import MoreInfoBanner from "@/components/MoreInfoBanner";
import NoSearchResultsVue from "./NoSearchResults.vue";
import Status from "./Status";
import requestConstants from "@/mixins/requestConstants";
import utility from "@/mixins/utility";
import { WorklistService } from '@/services/worklist'
import VirtualSelect from '@/components/VirtualSelect.vue'
import { SearchTerm, Sort } from '@/shared/models/worklist'
import PaginatedFilterMapping from '@/services/paginatedFilterMapping'
import { VirtualSelectService } from '@/services/virtualSelect'
import DownloadFilteredRequestsModal from "@/components/Modal.DownloadFilteredRequests";


var API = ENV_CONFIG.env_api;

const columnReference = WorklistService.getColumnReference()

let defaultColumnIds = ["urgency", "patient", "orderingProviderName", "medicalStemp", "primaryInsuranceCompanyName", "createdDate", "toBeDoneDate", "procedureDescription", "assignedToId", "status", "submission", "followUpDate", "notes"]
const defaultColumns = Object.keys(columnReference).map(key => columnReference[key].id).filter(col => defaultColumnIds.includes(col))

export default {
  name: "CustomizedRequestTable",
  mixins: [requestConstants, utility],
  components: {
    Status,
    Assign,
    FollowupDate,
    Draggable,
    MoreInfoBanner,
    NoSearchResultsVue,
    VirtualSelect,
    DownloadFilteredRequestsModal
  },
  props: [
    "requestList",
    "allRequestsCount",
    "title",
    "readonly",
    "filters",
    "searchFilters",
    "pageNumber",
    "sortingInfo",
    "multiSortActive",
    "downloadFilteredRequestHandler",
    "location",
    "activeColumnHeaders"
  ],
  data: function () {
    
    return {
      displayDownloadFilteredRequestsModal: false,
      updatingRequests: [],
      showSelectsFor: null,
      requestsPerPage: 10,
      updateRequestsPerPageTimer: false,
      currentPage: 1,
      localSortingInfo: null,
      sortKey: "urgency",
      searchValue: "",
      filterKey: "",
      requestStatus: false,
      dragging: false,
      trashZone: [],
      columnChangeModel: [],
      visibleColumns: defaultColumns,
      updatedColumns: [],
      updatedFlags: [],
      columnReference: columnReference,
      flagReference: [
        "SubmissionStatusChanged",
        "FaxCompleted",
        "FaxFailed",
        "PortalAutoSubmitInProgress",
        "PortalAutoSubmitComplete",
        "EligibilityUpdate",
        "OtherEMRChange",
        "NewDocument",
        "NewNote",
        "DosChanged",
        "CPTCodeAdded"
      ],
      alertFlags: ["FaxFailed", "EligibilityUpdate", "OtherEMRChange"],
      sortOrders: WorklistService.getSortOrders(),
      displayType: null,
      nonPortalMethods: [null, "", "VerataFax", "Fax", "Phone", "Other"],
      manageTable: false,
      updatingDisplaySettings: false,
      hoveringOverNotes: false,
      hoveredRequest: null,
      hoveredItem: null,
      narrowSearchTermSearchBy: ['all'],
      previousSearchTermSearchBy: ['all'],
      narrowedSearchTermOptions: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'MRN',
          value: 'mrn'
        },
        {
          label: 'EMR ID',
          value: 'emr_id'
        },
        {
          label: 'Patient First Name',
          value: 'patientFirstName'
        },
        {
          label: 'Patient Last Name',
          value: 'patientLastName'
        },
      ]
    };
  },
  created: function () {
    this.currentPage = parseInt(this.pageNumber) || 1;
    this.displayType = this.$route.name === 'Archive' ? sessionStorage.getItem('archiveDisplayType') : null;
  },
  methods: {
    multiSortHandler: function (event) {
      const checked = event.target.checked
      const dispatcher = checked ? 'TURN_MULTI_SORT_ON' : 'TURN_MULTI_SORT_OFF'

      this.$store.dispatch(`${this.location}/${dispatcher}`)
      this.$emit('featureToggle', { name: 'multisort', value: checked })
    },
    columnIsActive: function (sortKey) {
      return this.localSortingInfo?.find(
        sortinfInfo => sortinfInfo.field === sortKey
      )
    },
    getActivePosition: function(sortKey) {
      return this.localSortingInfo?.findIndex(
        sortinfInfo => sortinfInfo.field === sortKey
      ) + 1
    },
    handleSearchValue(e) {
      let tempSearchValue = e.target.value;
      if(tempSearchValue.length == 0) {
        this.searchValue = "";
        this.filterKey = "";
      }
      this.searchValue = tempSearchValue;
    },
    getCptCodes(request) {
      return [
        {
          code: request.primaryCptCode || "",
          description: request.primaryCptDescription || "",
        },
        {
          code: request.secondaryCptCode || "",
          description: request.secondaryCptDescription || "",
        },
        {
          code: request.tertiaryCptCode || "",
          description: request.tertiaryCptDescription || "",
        },
      ];
    },
    getNotesText(request) {
      let note = "";
      if (request.lastUserComment) {
        let comment = request.lastUserComment;
        note =
          this.formatDateMMDDYYYY(comment.when) +
          " - " +
          comment.author.firstName +
          " " +
          comment.author.lastName +
          ': "' +
          comment.text +
          '"';
      }
      return note;
    },
    getShortNotesText(request) {
      let note = "";
      if (request.lastUserComment) {
        let comment = request.lastUserComment;
        note =
          this.formatDateText(comment.when) +
          " - " +
          comment.author.firstName +
          " " +
          comment.author.lastName +
          ': "' +
          comment.text +
          '"';
      }
      return note;
    },
    getLastUserComment(request) {
      if (request && request.lastUserComment && !this.isEmptyNullOrUndefined(request.lastUserComment)) {
        return request.lastUserComment.text;
      }
      return null;
    },
    mouseOverItem(request, item) {
      this.hoveringOverNotes = true;
      this.hoveredRequest = request;
      this.hoveredItem = item;
    },
    mouseLeaveItem() {
      this.hoveringOverNotes = false;
      this.hoveredRequest = null;
      this.hoveredItem = null;
    },
    searchPaRequests: function () {
      this.filterKey = this.searchValue && this.searchValue.length ? this.searchValue : null;
      this.previousSearchTermSearchBy = this.narrowSearchTermSearchBy

      
      const searchTermFilter = WorklistService.getSearchTermFilter(
        this.narrowSearchTermSearchBy[0]
      )

      const filter = PaginatedFilterMapping.formatCurrentFilter({ 
                        [searchTermFilter]: [this.filterKey]
                      })
     
      this.$store.dispatch("SET_SEARCH_TERM", 
        new SearchTerm({
          filterKey: this.filterKey,
          narrowSearchBy: this.narrowSearchTermSearchBy[0],
          filter
        })
      );
      this.$emit('searchTermUpdated')
    },
    clearSearch: function () {
      this.searchValue = "";
      this.filterKey = "";
      this.narrowSearchTermSearchBy = ['all'];
      this.$store.dispatch("SET_SEARCH_TERM", 
        new SearchTerm({
          filterKey: this.filterKey
        })
      );

      const virtualSelectService = new VirtualSelectService(`#narrowSearchTermSearchBy`)
      if (virtualSelectService.el) {
        virtualSelectService.setValue('all')
      }

      this.$emit('searchTermUpdated')
    },
    colTableSide: function (col) {
      let index = this.visibleColumns.indexOf(col);

      if (index + 1 > this.visibleColumns.length / 2) return "rightSide";
      return "leftSide";
    },
    labelFromPascal: function (prop) {
      if (prop === "OtherEMRChange") return "EMR Change";
      if (prop === "DosChanged") return "DOS Changed After Submission"
      if (prop === "CPTCodeAdded") return "New CPT Code Added After Submission"
      return prop.split(/(?=[A-Z])/).join(" ");
    },
    updateRequestsPerPage: function (e) {
      let that = this;
      clearTimeout(this.updateRequestsPerPageTimer);
      this.updateRequestsPerPageTimer = setTimeout(function () {
        let value = Math.floor(e.target.value);
        if (e.target.value < 1) value = 1;
        if (e.target.value > 150) value = 150;
        that.requestsPerPage = value;
        that.updateDisplaySettings(false, false, value);
      }, 1000);
    },
    setRequestsPerPage: function () {
      this.updateDisplaySettings(false, false, this.requestsPerPage);
    },
    toggleTableView: function () {
      this.updatedColumns = [...this.visibleColumns];
      this.updatedFlags = this.visibleFlags;
      this.manageTable = !this.manageTable;
      this.initColumnChangeModel();
    },
    updateDisplaySettings: async function (
      columnOrder,
      hiddenNotificationFlags,
      worklistRequestsPerPage
    ) {
      let visibleColumnOrder = columnOrder;
      if (
        !columnOrder &&
        this.worklistDisplaySettings.visibleColumnOrder &&
        this.worklistDisplaySettings.visibleColumnOrder.length
      ) {
        visibleColumnOrder = this.worklistDisplaySettings.visibleColumnOrder;
      }
      if (
        !columnOrder &&
        this.worklistDisplaySettings.visibleColumnOrder &&
        !this.worklistDisplaySettings.visibleColumnOrder.length
      ) {
        visibleColumnOrder = defaultColumns;
      }
      let params = {
        documentDisplaySettings:
          this.worklistDisplaySettings.documentDisplaySettings,
        visibleColumnOrder: visibleColumnOrder,
        hiddenNotificationFlags:
          hiddenNotificationFlags ||
          this.worklistDisplaySettings.hiddenNotificationFlags,
        worklistRequestsPerPage: +(
          worklistRequestsPerPage ||
          this.worklistDisplaySettings.worklistRequestsPerPage
        ),
      };

      await axios
        .post(`${API}WorkDashboard/UpdateWorklistDisplaySettings`, params, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
            Pragma: "no-cache",
          },
        })
        .then((res) => {
          this.clearSearch();
          this.visibleColumns = params.visibleColumnOrder;
          this.$store.commit("SET_WORKLIST_DISPLAY_SETTINGS", res.data);
          this.updatingDisplaySettings = false;
          this.manageTable = false;
        });
    },
    saveDisplaySettings: async function () {
      this.updatingDisplaySettings = true;
      let hideFlags = this.flagReference.filter(
        (f) => !this.updatedFlags.includes(f)
      );

      this.updateDisplaySettings(this.updatedColumns, hideFlags);
    },
    resetToDefaultDisplaySettings: async function () {
      this.updatingDisplaySettings = true;
      this.updatedColumns = defaultColumns;
      this.updateDisplaySettings(this.updatedColumns, []);
    },
    cancelUpdateDisplaySettings: function () {
      this.initColumnChangeModel();
      this.updatedFlags = this.flagReference.filter(
        (f) => !this.worklistDisplaySettings.hiddenNotificationFlags.includes(f)
      );
      this.updatedColumns = [...this.visibleColumns];
    },
    dragStart: function () {
      this.dragging = true;
    },
    dragEnd: function () {
      this.dragging = false;
      this.trashZone = [];
      let columnIdOrder = this.columnChangeModel.map((c) => c.id);
      this.updatedColumns = this.updatedColumns.sort(function (a, b) {
        return columnIdOrder.indexOf(a) - columnIdOrder.indexOf(b);
      });
    },
    finishedUpdating: function (id) {
      let updating = this.updatingRequests.filter((r) => {
        return r != id;
      });
      if (this.showSelectsFor === id) this.showSelectsFor = null;
      this.updatingRequests = updating;
    },
    updating: function (id) {
      this.updatingRequests.push(id);
    },
    showSelects: function (e, paRequest, requestPosition) {
      e.preventDefault();
      if (!this.allowEditing) this.navigate(paRequest.paRequestId, requestPosition);
      if (
        !this.updatingRequests.includes(paRequest.paRequestId) &&
        this.allowEditing
      )
        this.showSelectsFor = paRequest.paRequestId;
    },
    closeSelects: function (id) {
      if (this.showSelectsFor === id) this.showSelectsFor = null;
    },
    navigate: function (requestId) {
      this.$router.push({ path: `/request/${requestId}` });
    },
    getStatusName: function (statusId) {
      return this.statusList[statusId];
    },
    formatTime: function (time) {
      if (!time ||  moment(time, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).fromNow() === "Invalid date") {
        return "Not set";
      }
      return  moment(time, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).format("MM/DD/YYYY");
    },
    formatDateMMDDYYYY(date) {
      let timestamp = moment(date, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).utc(true).local().format("MM/DD/YYYY h:mm A");
      if (timestamp === "Invalid date") timestamp = null;

      return timestamp;
    },
    formatDateText(date) {
      let timestamp = moment(date, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).utc(true).local().format("MM/DD/YYYY");
      if (timestamp === "Invalid date") timestamp = null;

      return timestamp;
    },
    formatTimeText(date) {
      let timestamp = moment(date, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).utc(true).local().format("h:mm A");
      if (timestamp === "Invalid date") timestamp = null;

      return timestamp;
    },
    removeTime: function (time) {
      if (time) {
        time = time.split(" ");
        return time[0];
      }
      return "";
    },
    formatTimeAndDate: function (time) {
      if (!time ||  moment(time, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).fromNow() === "Invalid date") {
        return "Not set";
      }
      return moment(time, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).format("MM/DD/YYYY h:mm A");
    },
    applyMultiSortLogic: function (key) {
      const existingActiveHeader = this.activeColumnHeaders.find(
        activeColumnHeader => activeColumnHeader.field === key
      )

      if (!existingActiveHeader) {
        const sortInstance = new Sort({
          field: key,
          direction: this.sortOrders[key],
        });
        
        this.$emit('sorting', [ ...this.sortingInfo, sortInstance ], sortInstance)
      } else {
        const foundSort = this.sortingInfo.find(
          sortInfo => sortInfo.field === key
        )

        if (foundSort) {
          const sortInstance = new Sort({
            field: foundSort.field,
            direction: this.sortOrders[key],
          });

          this.$emit('sorting', 
            this.sortingInfo.map(
              sortInfo => {
                if (sortInfo.field === key) {
                  return sortInstance
                }

                return sortInfo
              }
            ),
            // also return the newly created sort instance
            sortInstance
          )
        } else {
          const sortInstance = new Sort({
            field: key,
            direction: this.sortOrders[key],
          });
          
          this.$emit('sorting', [ ...this.sortingInfo, sortInstance ], sortInstance)
        } 
      }
    },
    sortBy: function (key) {
      if (key === "submission" || key === "notes") return;
      this.sortOrders[key] = this.sortOrders[key] * -1;

      if (this.multiSortActive) {
        this.applyMultiSortLogic(key)
      } else {
        const sortInstance = new Sort({
          field: key,
          direction: this.sortOrders[key],
        });
        
        this.$emit('sorting', [ sortInstance ], sortInstance)
      }
    },
    addStatusClass: function (status) {
      var s = "status" + status;
      return s;
    },
    addWorkerClass: function (assignedToId) {
      let worker = this.workers.find((w) => w.paPersonId === assignedToId);
      if (worker && worker.systemManagedAccount) return "systemManagedAccount";
    },
    addUpdateClass: function (flags) {
      if (!flags) return false;
      let filteredFlags = flags.filter((f) => f !== "NewNote");
      if (filteredFlags.includes("NewPaRequest") && filteredFlags.length > 1)
        return "isNew recentUpdate";
      if (filteredFlags.includes("NewPaRequest")) return "isNew";
      if (filteredFlags.length > 0) return "recentUpdate";

      return false;
    },
    setCurrentPage: function (pageNumber) {
      this.currentPage = pageNumber;
      this.$emit("pageSelected", pageNumber);
    },
    notificationFlagClasses: function (rowFlags) {
      let rowVisibleFlags = rowFlags.filter((f) =>
        this.visibleFlags.includes(f)
      );

      return rowVisibleFlags.join(" ");
    },
    codeNotificationText: function (flags) {
      let filteredFlags = flags.filter(
        (f) => f !== "NewNote" && f !== "NewPaRequest"
      );

      let calendarNotificationFlags = filteredFlags.filter(
        flag => this.visibleFlags.includes(flag) && flag === 'CPTCodeAdded'
      )

      let text = calendarNotificationFlags.map((f) => {
        return `<span>A new CPT code has been added since the Request was submitted</span>`;
      });

      return text.length ? text.join("") : null;
    },
    calendarNotificationText: function (flags) {
      let filteredFlags = flags.filter(
        (f) => f !== "NewNote" && f !== "NewPaRequest"
      );

      let calendarNotificationFlags = filteredFlags.filter(
        flag => this.visibleFlags.includes(flag) && flag === 'DosChanged'
      )

      let text = calendarNotificationFlags.map((f) => {
        return `<span>Procedure Date has been changed since the Request was submitted</span>`;
      });

      return text.length ? text.join("") : null;
    },
    alertNotificationText: function (flags, status) {
      let filteredFlags = flags.filter(
        (f) => f !== "NewNote" && f !== "NewPaRequest" && f !== 'DosChanged' && f !== 'CPTCodeAdded'
      );
      let showFlags = filteredFlags.filter((f) => {
        return this.visibleFlags.includes(f) && this.alertFlags.includes(f);
      });
      let text = showFlags.map((f) => {
        return `<span>${this.labelFromPascal(f)}</span>`;
      });
      if (status === 199)
        text.push("<span>Changes detected after approval</span>");
      return text.length ? text.join("") : null;
    },
    infoNotificationText: function (flags) {
      let filteredFlags = flags.filter(
        (f) =>
          f !== "NewNote" &&
          f !== "NewPaRequest" &&
          f !== 'DosChanged' &&
          f !== 'CPTCodeAdded' &&
          !this.alertFlags.includes(f)
      );
      let showFlags = filteredFlags.filter((f) =>
        this.visibleFlags.includes(f)
      );
      let text = showFlags.map((f) => {
        return `<span>${this.labelFromPascal(f)}</span>`;
      });
      return text.length ? text.join("") : null;
    },
    initColumnChangeModel: function () {
      let columnArray = [
        ...Object.keys(this.columnReference).map(
          (k) => this.columnReference[k]
        ),
      ];
      let notVisible = columnArray.filter((k) => {
        if (!this.visibleColumns.includes(k.id)) {
          k.visible = false;
          return k;
        }
      });
      let visible = this.visibleColumns.map((c) => {
        let col = this.columnReference[c];
        col.visible = true;
        return col;
      });

      this.columnChangeModel = [...visible, ...notVisible];
    },
  },
  beforeDestroy: function () {
    clearTimeout(this.updateRequestsPerPageTimer);
  },
  computed: {
    ...mapState([
      "workers",
      "worklistDisplaySettings",
      "customerSettings",
      "textSearchTerm",
      "fetchingRequests",
      "currUser"
    ]),
    ...mapGetters(["statusList", "readOnlyUser", "assignees"]),
    hasNonVisibleSavedSortApplied: function () {
      if (this.activeColumnHeaders.length) {
        return !this.activeColumnHeaders.every(
          activeColumnHeader => {
            return this.visibleColumns.includes(activeColumnHeader.field)
          }
        )
      }
      
      return !this.localSortingInfo?.find(
        sortinfInfo => this.visibleColumns.includes(sortinfInfo.field)
      )
    },
    hasNoRequestsCount: function () {
      return !this.allRequestsCount || this.allRequestsCount < 1;
    },
    hasRequestsCount: function () {
      return this.allRequestsCount && this.allRequestsCount > 0;
    },
    getRequestCountMessage: function () {
      if (this.allRequestsCount < this.requestsPerPage) {
        return `${this.requestsPerPage * (this.currentPage - 1) + 1} -
            ${this.allRequestsCount}`;
      }

      return `${this.requestsPerPage * (this.currentPage - 1) + 1} -
            ${this.requestsPerPage * this.currentPage > this.allRequestsCount ? this.allRequestsCount : this.requestsPerPage * this.currentPage}`;
    },
    assigneeIds: function () {
      if (!this.assignees.length) return [];
      let assignableIds = this.assignees.map((a) => a.paPersonId);
      return assignableIds;
    },
    allowEditing: function () {
      if (!this.readOnlyUser && !this.readonly) return true;
      return false;
    },
    visibleFlags: function () {
      if (
        !this.worklistDisplaySettings ||
        !this.worklistDisplaySettings.hiddenNotificationFlags
      )
        return this.flagReference;
      return this.flagReference.filter(
        (f) => !this.worklistDisplaySettings.hiddenNotificationFlags.includes(f)
      );
    },
    notificationsHaveChanged: function () {
      return (
        this.visibleFlags.length == this.updatedFlags.length &&
        this.visibleFlags.every((flag) => this.updatedFlags.includes(flag))
      );
    },
    columnsHaveChanged: function () {
      return (
        this.visibleColumns.length !== this.updatedColumns.length ||
        !this.visibleColumns.every(
          (element, index) => element === this.updatedColumns[index]
        )
      );
    },
    columnsAreDefault: function () {
      // let defaultColumns = Object.keys(columnReference)
      return (
        defaultColumns.length == this.updatedColumns.length &&
        defaultColumns.every(
          (element, index) => element === this.updatedColumns[index]
        )
      );
    },
    pageCount: function () {
      let pageCount = 1;
      if (this.allRequestsCount > 0)
        pageCount = Math.ceil(this.allRequestsCount / this.requestsPerPage);

      return pageCount;
    },
    filterKeys: function () {
      const keys = Object.keys(this.sortOrders);
      return keys.filter((k) => k !== "createdDate");
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    pageButtons: function () {
      if (this.pageCount < 8)
        return Array.from(Array(this.pageCount).keys()).slice(2);
      let pageNumbers = [
        this.currentPage - 2,
        this.currentPage - 1,
        this.currentPage,
        this.currentPage + 1,
        this.currentPage + 2,
      ];
      pageNumbers = pageNumbers.filter((p) => {
        if (p < this.pageCount && p > 1) return p;
      });

      return pageNumbers;
    },
    existingVisibleColumns: function () {
      if (
        !this.columnReference ||
        !this.worklistDisplaySettings ||
        !this.worklistDisplaySettings.visibleColumnOrder ||
        !this.worklistDisplaySettings.visibleColumnOrder.length
      )
        return defaultColumns;
      let cols = Object.keys(this.columnReference);
      return this.worklistDisplaySettings.visibleColumnOrder.filter((c) =>
        cols.includes(c)
      );
    },
  },
  mounted: function () {
    // this.sortKey = this.requestSort;
    // this.sortOrders[this.requestSort] = this.requestSortOrder;
    // this.localSortingInfo = this.sortingInfo
    this.visibleColumns = this.existingVisibleColumns;
    this.searchValue = this.textSearchTerm?.filterKey || '';

    this.narrowSearchTermSearchBy = [this.textSearchTerm?.narrowSearchBy || 'all'];
    this.previousSearchTermSearchBy = this.narrowSearchTermSearchBy
    
    const virtualSelectService = new VirtualSelectService(`#narrowSearchTermSearchBy`)
    if (virtualSelectService.el) {
      virtualSelectService.setValue(this.narrowSearchTermSearchBy[0])
    }

    this.requestsPerPage = this.worklistDisplaySettings?.worklistRequestsPerPage || 10
  
    this.initColumnChangeModel();
  },
  watch: {
    sortingInfo: {
      immediate: true,
      handler(value) {
        if (value) {
          this.localSortingInfo = value

          value.forEach(
            sortingInfo => this.sortOrders[sortingInfo.field] = sortingInfo.direction
          )
        }
      }
    },
    worklistDisplaySettings: function() {
      if (this.worklistDisplaySettings) {
        this.requestsPerPage = this.worklistDisplaySettings.worklistRequestsPerPage;
        if(this.worklistDisplaySettings.visibleColumnOrder) {
          this.visibleColumns = this.worklistDisplaySettings.visibleColumnOrder;
        }
      }
    },
    pageNumber: function () {
      this.currentPage = parseInt(this.pageNumber) || 1;
    },
    textSearchTerm: {
      handler(nextVal, prevVal) {
        if (nextVal?.filterKey === prevVal?.filterKey) return

        this.searchValue = this.textSearchTerm?.filterKey || ''

        if (nextVal?.filterKey === '') {
          this.clearSearch()
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.workItems_v2.readonly tbody .tableCell:after {
  content: "" !important;
}
#worklist #pagination .pageSelect.ellipsis,
#worklist #pagination .pageSelect.ellipsis:hover {
  background-color: transparent;
  color: inherit;
  border: none;
}
button:disabled {
  opacity: 0.5;
}

.searchOptionsField {
  display: inline-block;
  width: 15rem;
  margin-right: 1rem;
  z-index: 6;
}

form {
  display: flex;
  align-items: center;
}
</style>

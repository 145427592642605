<template>
  <div class="editArea">
    <label>Follow-up Date</label>
    <div class="spinner" v-show="updating">
      <i class="fa fa-spin fa-spinner"></i> Updating
    </div>
    <div v-if="assignedToSystemUser" class="status-readOnly">
      {{formatTime(paFollowUpDate)}}
    </div>
    <DatePicker v-if="!updating && !assignedToSystemUser" v-model="selected" :first-day-of-week="7" @input-error="invalidInput" lang="en" :disabled="readOnlyUser || disabled" v-on:input="updateDate" value-type="token(MM/DD/YYYY)"  placeholder="MM/DD/YYYY" :formatter="followUpFormatter" :disabled-date="disabledDates"/>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FollowupDate',
  components: { DatePicker },
  props: ['paRequest', 'paFollowUpDate', 'disabled'],
  data: function(){
    return {
      selected: '',
      updating:false,
      followUpFormatter: {
        stringify: (date) => date ? moment(date).format('MM/DD/YYYY') : '',
        parse: (value) => this.validateDatePickerDate(value, 'selected'),
        getWeek: (date) => { return }
      },
    }
  },
  methods: {
    validateDatePickerDate(value, field){
      if(!value) return null
      let validDate = /^\d{2}([./-])\d{2}\1\d{4}$/
      
      if(!validDate.test(value)){
        this.invalidInput(value);
        return 
      }
      return value ? moment(value, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).toDate() : null
    },
    disabledDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < new Date(today.getTime() - 3650 * 24 * 3600 * 1000) || date > new Date(today.getTime() + 3650 * 24 * 3600 * 1000);
    },
    invalidInput: function(date){
      this.$store.dispatch('SET_GLOBAL_MESSAGE', ['error', `${date} is not a valid date`])
    },
    updateDate: async function (){
      let validDate = /^\d{2}([./-])\d{2}\1\d{4}$/
      if(this.selected != null && !validDate.test(this.selected)) return
      this.updating = true

      let timestamp = moment(this.selected).format('YYYY-MM-DDTHH:mm:ss')
      if(timestamp === 'Invalid date') timestamp = null

      if(timestamp === this.paFollowUpDate) {
        this.updating = false;
        return
      }

      let params = {
        paRequestId: this.paRequest.paRequestId,
        followUpDate: timestamp
      }
      this.$emit('updating', this.paRequest.paRequestId)

      try {
        await this.$store.dispatch('UPDATE_FOLLOWUP_DATE', params)
        this.$emit('followUpSet')
        this.$emit('finishedUpdating', this.paRequest.paRequestId)
        this.updating = false;
      } catch (error) {
        this.$emit('finishedUpdating', this.paRequest.paRequestId)
        this.updating = false;
      }
    },
    setFollowUpDate: function() {
      if(this.paRequest.assignedToId || this.paRequest.assignedToId === 0 || this.paRequest.assignedToId == null){
        this.selected = this.formatDateMMDDYYYY(this.paRequest.followUpDate)
      }
      // console.log(this.updating, 'SET FOLLOWUPDATE')
      this.$emit('finishedUpdating', this.paRequest.paRequestId)
      this.updating = false
    },
    formatDateMMDDYYYY(date){
      let timestamp = moment(date, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).format('MM/DD/YYYY')
      if(timestamp === 'Invalid date') timestamp = null

      return timestamp
    },
    formatTime: function(time){
      if(moment.utc(time).fromNow() === 'Invalid date'){
        return 'Not set'
      }
      return moment.utc(time).format("MM/DD/YYYY")
    },
  },
  mounted: function(){
    if(this.paRequest.followUpDate ){
      this.selected = this.formatDateMMDDYYYY(this.paRequest.followUpDate)
    }
  },
  destroyed: function(){
    this.$emit('finishedUpdating', this.paRequest.paRequestId)
  },
  computed: {
    ...mapState(
      ['currUser', 'workers']
    ),
    ...mapGetters(
      ['readOnlyUser']
    ),
    assignedToSystemUser: function() {
      let worker = this.workers.find( w => w.paPersonId === this.paRequest.assignedToId)
      if(worker && worker.systemManagedAccount) return true
      return false
    },
  },
  watch: {
    'paFollowUpDate': {
      handler (val) {
        this.setFollowUpDate()
      },
      deep: true
    },
  }
}
</script>

import { Notification } from '@/shared/models/notification'
import {DataExportApi} from "@/services/dataExportApi";


function initialState() {
    return {
        startingExportData: false,
        runningExportData: false,
        downloadsInProgress: [],
        completedDownloads: [],
        failedDownloads: [],
        dataExportApiGetStatusRunning: false
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        RESET: ({ dispatch }) => {
            dispatch('CLEAR_DOWNLOAD_WORKER_QUEUE')
            dispatch('CLEAR_COMPLETED_DOWNLOADS')
            dispatch('CLEAR_FAILED_DOWNLOADS')
        },
        BEGIN_REPORT_DOWNLOAD: async ({commit, dispatch}, startEndRange) => {
            let startTime = Date.now();
            commit('SET_STARTING_EXPORT_DATA', true);

            try {
                const response = await DataExportApi.allPaRequests(startEndRange);

                dispatch('ADD_DOWNLOAD_TO_WORKER_QUEUE', new Notification({ id: parseInt(response.data.id), when: startTime, from: "PA Requests"}));

                commit('SET_RUNNING_EXPORT_DATA', true);
            } catch (err) {
                console.log(err)
                commit('SET_STARTING_EXPORT_DATA', false);
                commit('SET_RUNNING_EXPORT_DATA', false);
                // commit('SET_GLOBAL_MESSAGE', ['error', err.message])
            }
        },
        BEGIN_DOWNLOAD: async ({dispatch}, {promise, from}) => {
            let startTime = Date.now();

            try {
                const response = await promise;
                dispatch('ADD_DOWNLOAD_TO_WORKER_QUEUE', new Notification({ id: parseInt(response.data.id), when: startTime, from}));
            } catch (error) {
                dispatch('ADD_FAILED_DOWNLOAD', new Notification({when: startTime, response: error.message,  from}));
            }
        },
        DOWNLOAD_BG_EXPORT_SUCCEEDED: ({ commit, state, dispatch }, data) => {

            commit('SET_STARTING_EXPORT_DATA', false);
            commit('SET_RUNNING_EXPORT_DATA', false);

            const downloadInProgress = state.downloadsInProgress.find(item => item.id === data.id);

            if (downloadInProgress) {
                let filteredList = state.downloadsInProgress.filter(item => item?.id !== downloadInProgress.id);
                commit('SET_DOWNLOAD_WORKER_QUEUE', filteredList);

                dispatch('ADD_COMPLETED_DOWNLOAD', new Notification({
                    id: downloadInProgress.id,
                    when: downloadInProgress.when,
                    from: downloadInProgress.from,
                    filename: data.filename}));
            }
        },
        DOWNLOAD_BG_EXPORT_FAILED: async ({ commit, state, dispatch }, id) => {
            commit('SET_STARTING_EXPORT_DATA', false);
            commit('SET_RUNNING_EXPORT_DATA', false);

            const downloadInProgress = state.downloadsInProgress.find(item => item.id === id);

            if (downloadInProgress) {
                let filteredList = state.downloadsInProgress.filter(item => item?.id !== downloadInProgress.id);
                commit('SET_DOWNLOAD_WORKER_QUEUE', filteredList);

                await dispatch('ADD_FAILED_DOWNLOAD', downloadInProgress);
            }
        },
        ADD_DOWNLOAD_TO_WORKER_QUEUE: ({ commit, state }, downloadsInProgress) => {
            commit('SET_DOWNLOAD_WORKER_QUEUE', [ { ...downloadsInProgress }, ...state.downloadsInProgress ])
        },
        REMOVE_DOWNLOAD_FROM_WORKER_QUEUE: ({ commit, state }, id) => {
            const newDownloadsInProgress = state.downloadsInProgress.filter(
                downloadInProgress => downloadInProgress?.id != id
            )

            commit('SET_DOWNLOAD_WORKER_QUEUE', newDownloadsInProgress)
        },
        CHECK_DOWNLOAD_STATUS: async ({commit, state, dispatch}, notification) => {
            if (!state.dataExportApiGetStatusRunning) {
                commit('SET_DATAEXPORTAPIGETSTATUSRUNNING', true);
                try {
                    const response = await DataExportApi.getStatus(notification.id);
                    if (response.data.isProcessComplete) {
                        const id = parseInt(response.data.id);
                        const filename = response.data.filename;

                        if (!response.data.hasErrors) {
                            dispatch('DOWNLOAD_BG_EXPORT_SUCCEEDED', {id, filename});
                        } else {
                            dispatch('DOWNLOAD_BG_EXPORT_FAILED', id);
                        }
                    }
                } catch (error) {
                    console.error('Error checking download status:', error);
                } finally {
                    commit('SET_DATAEXPORTAPIGETSTATUSRUNNING', false);
                }
            }
        },
        CLEAR_DOWNLOAD_WORKER_QUEUE: ({ commit }) => {
            commit('SET_DOWNLOAD_WORKER_QUEUE', [])
        },
        ADD_COMPLETED_DOWNLOAD: ({ commit, state }, completedDownload) => {
            commit('SET_COMPLETED_DOWNLOADS', [ { ...completedDownload }, ...state.completedDownloads ])
        },
        REMOVE_COMPLETED_DOWNLOAD: ({ commit, state }, completedDownloadToRemove) => {
            const filteredCompletedDownloadList = state.completedDownloads.filter(
                completedDownload => completedDownload?.id != completedDownloadToRemove?.id
            )
            commit('SET_COMPLETED_DOWNLOADS', filteredCompletedDownloadList)
        },
        CLEAR_COMPLETED_DOWNLOADS: ({ commit }) => {
            commit('SET_COMPLETED_DOWNLOADS', [])
        },
        ADD_FAILED_DOWNLOAD: ({ commit, state }, failedDownloads) => {
            commit('SET_FAILED_DOWNLOADS', [ { ...failedDownloads }, ...state.failedDownloads ])
        },
        REMOVE_FAILED_DOWNLOAD: ({ commit, state }, failedDownloads) => {
            const filteredFailedDownloadList = state.failedDownloads.filter(
                failedDownload => failedDownload?.id != failedDownloads?.id
            )
        
            commit('SET_FAILED_DOWNLOADS', filteredFailedDownloadList)
        },
        CLEAR_FAILED_DOWNLOADS: ({ commit }) => {
            commit('SET_FAILED_DOWNLOADS', [])
        }
    },
    mutations: {
        SET_STARTING_EXPORT_DATA(state, value) {
            state.startingExportData = value;
        },
        SET_RUNNING_EXPORT_DATA(state, value) {
            state.runningExportData = value;
        },
        SET_DOWNLOAD_WORKER_QUEUE: (state, downloadsInProgress) => {
            state.downloadsInProgress = downloadsInProgress
        },
        SET_COMPLETED_DOWNLOADS: (state, completedDownloads) => {
            state.completedDownloads = completedDownloads
        },
        SET_FAILED_DOWNLOADS: (state, failedDownloads) => {
            state.failedDownloads = failedDownloads
        },
        SET_DATAEXPORTAPIGETSTATUSRUNNING: (state, running) => {
            state.dataExportApiGetStatusRunning = running
        }
    }
}
<template>
  <div class="status-select editArea">
    <label>REQUEST STATUS</label>
    <div class="spinner" v-show="updating">
      <i class="fa fa-spin fa-spinner"></i>  Updating
    </div>
    <div v-if="systemStatus.includes(paStatus) || assignedToSystemUser" class="status-readOnly">
      {{statusList[paStatus]}}
    </div>
    <select v-model="selected" v-if="!systemStatus.includes(paStatus) && !assignedToSystemUser" v-show="!updating" v-on:change="updateSubmissionOnChange" :disabled="readOnlyUser || disabled">
      <option disabled value="">Set Status</option>
      <option v-for="item in statusForSelect" v-bind:value="parseInt(item.Id)" :key="item.Id" :disabled="item.disabled">{{item.PrettyName}}</option>
    </select>

    <SubmissionWarningModal
      v-if="showFinalizeModal"
      :postUpdate="submissionWarningModalPostUpdate"
      :showAuthWarning="showAuthWarning"
      :missingSubmissionInfo="[
        ...(missingSubmissionDataPrimary || []),
        ...(missingSubmissionDataSecondary || []),
        ...(missingSubmissionDataTertiary || [])
      ]"
      :statusName="getStatusName"
      :showContinueMessage="true"
      :cancel="canValidateSubmissionStatus && showAuthWarning ? postUpdateOnCancel : cancel"
      :buttonText="canValidateSubmissionStatus && showAuthWarning && 'Go Back'"
      :cancelButtonText="canValidateSubmissionStatus && showAuthWarning && 'Proceed Anyway'"
    />

    <!-- new overall submission finalization warning -->
    <FinalizationWarningModal
      v-if="canValidateSubmissionStatus && !showFinalizeModal && overallSubmissionInfo && overallSubmissionInfo.needsWarning"
      :overallSubmissionInfo="overallSubmissionInfo"
      :postAllSubmissionUpdate="postAllSubmissionUpdate"
      :cancel="cancel"
      :proceedAnyway="proceedAnyway"
    />

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SubmissionHelper } from '@/services/submission'
import moment from 'moment'
import SubmissionWarningModal from '@/components/Modal.SubmissionWarning'
import FinalizationWarningModal from '@/components/Modal.FinalizationWarning'

export default {
  name: 'Status',
  components: { SubmissionWarningModal, FinalizationWarningModal },
  props: ['paRequest', 'paStatus', 'disabled'],
  data: function(){
    return {
      showFinalizeModal: false,
      overallSubmissionInfo: null,
      eventValue: false,
      selected: '',
      updating: false,
      finalizing: false,
    }
  },
  methods: {
    submissionWarningModalPostUpdate: function (event) {
      if (this.canValidateSubmissionStatus && this.showAuthWarning) {
        return this.cancel(event)
      }

      return this.postUpdate()
    },
    updateSubmissionOnChange: function (event) {
      if (this.canValidateSubmissionStatus) {
        this.overallSubmissionInfo = this.makeSubmissionWarningCheck()

        if (!this.overallSubmissionInfo.needsWarning) {
          this.updateStatus(event)
        }

      } else {
        this.updateStatus(event)
      }
    },
    postUpdateOnCancel: function () {
      this.postUpdate();
      this.showFinalizeModal = false
    },
    proceedAnyway: function () {
      this.postUpdate(true) // skip finalization check
      this.showFinalizeModal = false
    },
    makeSubmissionWarningCheck: function () {
      // destroy reference to the overallSubmissionInfo in order to
      // properly display changes and updates.
      this.overallSubmissionInfo = null

      const submissionHelper = new SubmissionHelper(this.collectSubmissions, { Id: this.selected }, this.collectPayers)
      return submissionHelper.needsSubmissionWarning()
    },
    /**
     * Determines if the value being passed in is a status
     * that would require displaying the finalization status
     * modal.
     * 
     * Statuses include:
     * 
     * 11: PA IS UNNECESSARY
     * 200: APPROVED
     * 201: APPROVED WITH CHANGES
     * 202: PARTIALLY APPROVED
     * 205: Approved By Phone
     * 501: DENIED
     * 500: Cancelled
     * 502: Errors In Submission
     * 503: Withdrawn
     * 300: Completed In EMR
     * 
     * @param {String} value 
     */
    valueHasStatusToDisplayFinalizeModal: function (value) {
      return [11, 200, 201, 202, 205, 501, 500, 502, 503, 300].includes(parseInt(value))
    },
    updateStatus: function(event){
      this.eventValue = event.target.value

      if(
        (this.valueHasStatusToDisplayFinalizeModal(event.target.value))
      ) {
        this.showFinalizeModal = true
      } else {
        this.postUpdate()
      }
    },
    selectNearestSubmissionTab: function (payerTabId) {
      // select the nearest tab after making a status change to a submission
      const nearestPayer = document.querySelector(
        `[data-payer-tab-id="${payerTabId}"]`
      )

      if (nearestPayer) {
        nearestPayer.click()
      }
    },
    postAllSubmissionUpdate: async function () {
      this.overallSubmissionInfo = this.makeSubmissionWarningCheck()
      const payload = this.overallSubmissionInfo.payload

      this.finalizing = true
      this.updating = true
      this.$emit('updating', this.paRequest.paRequestId)

      try {
        if (payload.submissionsToUpdate && !!payload.submissionsToUpdate.length) {
          const submissionsToUpdate = payload.submissionsToUpdate.map( submission => {
            return Object.assign({}, submission, {
              paRequestId: this.paRequest.paRequestId,
              paPersonId: this.currUser.paPersonId,
              submitted: moment().format("YYYY-MM-DDTHH:mm:ss"),
              submissionMethod: payload.submitVia.name,
              status: payload.submissionStatusId,
              stempSubmissions: (submission.stempSubmissions || []).map(stempSubmission => {
                return {
                  ...stempSubmission,
                  status: payload.submissionStatusId,
                }
              })
            })
          })

          if (!!submissionsToUpdate.length) {
            await Promise.all(submissionsToUpdate.map(submission => this.$store.dispatch("SEND_SUBMISSION_REQUEST", submission)))
            await this.$store.dispatch( "GET_SUBMISSION_STATUS", this.paRequestAndFormData.paRequest.paRequestId )
          }
        }

        // select the nearest tab after making a status change to a submission
        if (this.overallSubmissionInfo && this.overallSubmissionInfo.nearestTabToSelect) {
          this.selectNearestSubmissionTab(this.overallSubmissionInfo.nearestTabToSelect.insuranceCompanyId)
        }

        this.selected = payload.finalizationStatus || this.selected

        if(
          (this.valueHasStatusToDisplayFinalizeModal(this.selected))
        ) {
          this.showFinalizeModal = true
          this.updating = false
          this.finalizing = false
        } else {
          this.updating = false

          this.postUpdate()
          this.$emit('statusSet')
          this.$emit('finishedUpdating', this.paRequest.paRequestId)

          // cleanup
          this.finalizing = false
          this.updating = false
        }
      } catch(error) {
        console.warn(error)
        this.$emit('statusSet')
        this.$emit('finishedUpdating', this.paRequest.paRequestId)

        // cleanup
        this.overallSubmissionInfo = null
        this.updating = false
        this.finalizing = false
      }
    },
    postUpdate: async function(skipFinalizationCheck = false){
      if (this.canValidateSubmissionStatus && !skipFinalizationCheck) {
        this.overallSubmissionInfo = this.makeSubmissionWarningCheck()

        if (!this.overallSubmissionInfo.needsWarning) {
          this.updating = true
      
          let params = {
            paRequestId: this.paRequest.paRequestId,
            statusId: this.selected
          }

          this.$emit('updating', this.paRequest.paRequestId)

          try {
            await this.$store.dispatch('UPDATE_REQUEST_STATUS', params)
            this.$emit('statusSet')
            this.$emit('finishedUpdating', this.paRequest.paRequestId)
            this.updating = false
          } catch (error) {
            this.updating = false
            this.$emit('finishedUpdating', this.paRequest.paRequestId)
          }

          this.showFinalizeModal = false
        } else {
          this.showFinalizeModal = false
        }
      } else {
        this.updating = true
      
        let params = {
          paRequestId: this.paRequest.paRequestId,
          statusId: this.selected
        }

        this.$emit('updating', this.paRequest.paRequestId)

        try {
          await this.$store.dispatch('UPDATE_REQUEST_STATUS', params)
          this.$emit('statusSet')
          this.$emit('finishedUpdating', this.paRequest.paRequestId)
          this.updating = false
        } catch (error) {
          this.$emit('finishedUpdating', this.paRequest.paRequestId)
          this.updating = false
        }

        this.showFinalizeModal = false
        this.overallSubmissionInfo = null
      }
    },
    setStatus: function() {
      if(this.paRequest.status || this.paRequest.status === 0 || this.paRequest.status == null){
        this.selected = this.paRequest.status
      }
      this.$emit('finishedUpdating', this.paRequest.paRequestId)
      this.updating = false
    },
    cancel: function() {
      this.setStatus()
      this.showFinalizeModal = false

      // select the nearest tab after making a status change to a submission
      if (this.overallSubmissionInfo && this.overallSubmissionInfo.nearestTabToSelect) {
        this.selectNearestSubmissionTab(this.overallSubmissionInfo.nearestTabToSelect.insuranceCompanyId)
      }

      this.overallSubmissionInfo = null
    }
  },
  mounted: async function() {
    if(this.paRequest.status || this.paRequest.status === 0 || this.paRequest.status == null){
      this.selected = this.paRequest.status
    }
  },
  destroyed: function(){
    this.$emit('finishedUpdating', this.paRequest.paRequestId)
  },
  computed: {
    ...mapState(
      ['currUser', 'dataList', 'workers', 'paSubmission', 'paRequestAndFormData', 'customerSettings']
    ),
    ...mapGetters(
      ['statusList', 'readOnlyUser']
    ),
    getStatusName: function(){
      const status = this.dataList.Status.filter((s) => {
        return s.Id == this.selected
      })[0]
      return status ? status.PrettyName : ''
    },
    departmentSettings: function () {
      return this.customerSettings.departments
    },
    nonSelectablePriorAuthStates: function () {
      return this.customerSettings.nonSelectablePriorAuthStates
    },
    /**
     * Validating a submission status By team (the lookup based on the customer department settings)
     */
    canValidateSubmissionStatus: function () {
      const canValidateSubmissionByDepartment = this.departmentSettings.find(ds => ds.departmentId === this.paRequest.departmentId)
      return canValidateSubmissionByDepartment && canValidateSubmissionByDepartment.validateSubmissionStatus
    },
    collectSubmissions: function () {
      return [].concat(...(this.paSubmission || this.paRequest.submissionInfos).map( 
                submission => [{ ...submission }, ...(submission?.secondarySubmissions ?? [])]
              ))
    },
    collectPayers: function () {
      const request = this.paRequestAndFormData && this.paRequestAndFormData.paRequest || this.paRequest
      return [
        {
          name: request.primaryInsurance || request.primaryInsuranceCompanyName,
          primaryInsuranceId: request.primaryInsuranceId || request.primaryInsuranceCompanyId
        },
        {
          name: request.secondaryInsurance || request.secondaryInsuranceCompanyName,
          secondaryInsuranceId: request.secondaryInsuranceId || request.secondaryInsuranceCompanyId
        },
        {
          name: request.tertiaryInsurance || request.tertiaryInsuranceCompanyName,
          secondaryInsuranceId: request.tertiaryInsuranceId || request.tertiaryInsuranceCompanyId
        }
      ]
    },
    statusForSelect: function(){
      if(!this.dataList || !this.dataList.Status) return []

      let nonSystem = this.dataList.Status.filter( s => !s.SystemManaged )
      return nonSystem.map( s => {
        s.disabled = false
        if(this.nonSelectablePriorAuthStates.includes(s.Id)){
          s.disabled = true
        }
        return s
      })
    },
    systemStatus: function(){
      return this.dataList.Status.filter( s => s.SystemManaged ).map(s => s.Id)
    },
    missingSubmissionDataPrimary: function() {
      let missingData = []

      if (this.paRequest) {
        const primarySubmissionInfo = this.paRequest.primarySubmissionInfo || this.paRequest.submissionInfos[0]
        const insuranceCompanyName = this.paRequest.primaryInsurance || this.paRequest.primaryInsuranceCompanyName

        if (primarySubmissionInfo && primarySubmissionInfo.submissionStatusCategoryId === 100) {
          if(!primarySubmissionInfo.authorization || primarySubmissionInfo.authorization === ""){
            missingData.push(`Authorization # for ${insuranceCompanyName}`)
          }
          if(!primarySubmissionInfo.startOfAuthPeriod || primarySubmissionInfo.startOfAuthPeriod === ""){
            missingData.push(`Auth Start Date for ${insuranceCompanyName}`)
          }
          if(!primarySubmissionInfo.endOfAuthPeriod || primarySubmissionInfo.endOfAuthPeriod === ""){
            missingData.push(`Auth End Date for ${insuranceCompanyName}`)
          }
        }
      }

      return missingData
    },
    missingSubmissionDataSecondary: function() {
      let missingData = []

      if (this.paRequest) {
        const secondarySubmissionInfo = this.paRequest.secondarySubmissionInfo || this.paRequest.submissionInfos[1]
        const insuranceCompanyName = this.paRequest.secondaryInsurance || this.paRequest.secondaryInsuranceCompanyName

        if (secondarySubmissionInfo && secondarySubmissionInfo.submissionStatusCategoryId === 100) {
          if(!secondarySubmissionInfo.submissionMethod) return false
        
          if(!secondarySubmissionInfo.authorization || secondarySubmissionInfo.authorization === ""){
              missingData.push(`Authorization # for ${insuranceCompanyName}`)
          }
          if(!secondarySubmissionInfo.startOfAuthPeriod || secondarySubmissionInfo.startOfAuthPeriod === ""){
              missingData.push(`Auth Start Date for ${insuranceCompanyName}`)
          }
          if(!secondarySubmissionInfo.endOfAuthPeriod || secondarySubmissionInfo.endOfAuthPeriod === ""){
              missingData.push(`Auth End Date for ${insuranceCompanyName}`)
          }
        }
      }

      return missingData
    }
    ,
    missingSubmissionDataTertiary: function() {
      let missingData = []

      if (this.paRequest) {
        const tertiarySubmissionInfo = this.paRequest.tertiarySubmissionInfo || this.paRequest.submissionInfos[2]
        const insuranceCompanyName = this.paRequest.tertiaryInsurance || this.paRequest.tertiaryInsuranceCompanyName

        if (tertiarySubmissionInfo && tertiarySubmissionInfo.submissionStatusCategoryId === 100) {
          if(!tertiarySubmissionInfo.submissionMethod) return false
        
          if(!tertiarySubmissionInfo.authorization || tertiarySubmissionInfo.authorization === ""){
              missingData.push(`Authorization # for ${insuranceCompanyName}`)
          }
          if(!tertiarySubmissionInfo.startOfAuthPeriod || tertiarySubmissionInfo.startOfAuthPeriod === ""){
              missingData.push(`Auth Start Date for ${insuranceCompanyName}`)
          }
          if(!tertiarySubmissionInfo.endOfAuthPeriod || tertiarySubmissionInfo.endOfAuthPeriod === ""){
              missingData.push(`Auth End Date for ${insuranceCompanyName}`)
          }
        }
      }

      return missingData
    },
    hasMissingSubmissionData: function () {
      // Only display a warning if that selected status is either
      // Approved, Partially Approved
      // APPROVED = 200
      // PARTIALLY APPROVED = 201
      // PAYER MODIFIED - 202

      if(![200, 201, 202].includes(this.selected)) return false
      if(this.missingSubmissionDataPrimary && this.missingSubmissionDataPrimary.length) return true
      if(this.missingSubmissionDataSecondary && this.missingSubmissionDataSecondary.length) return true
      if(this.missingSubmissionDataTertiary && this.missingSubmissionDataSecondary.length) return true
      return false
    },
    showAuthWarning: function() {
      return this.hasMissingSubmissionData
    },
    assignedToSystemUser: function() {
      let worker = this.workers.find( w => w.paPersonId === this.paRequest.assignedToId)
      if(worker && worker.systemManagedAccount) return true
      return false
    },
  },
  watch: {
    'paStatus': {
      handler (val) {
        this.setStatus()
      },
      deep: true
    },
  }
}
</script>


<style lang="scss" scoped>
 .block{
   display: block;
 }

.authCertWarning .approvalWarning {
  text-align: center !important;
}

 .authCertWarning .approvalWarning p {
   margin: 1rem 0 !important;
   width: 100% !important;
 }

 .twoOptions > button {
  margin-right: 2rem;
 }

 .separator {
    position: relative;
    text-align: center;
    margin: 1rem 0;
  }

  .separator label{
    background-color:$color-white;
    padding: 0 0.4em !important;
    position: relative;
    color: $color-blue3;
  }

  .separator:before{
    content: '';
    border-style: solid;
    border-width: 0 0 1px 0;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-color: $color-base2;
  }
</style>
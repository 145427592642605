<template>
<div v-if="worklistFilters.length">
<transition-group name="loading" tag="ul">
    <li class="header" key="userWorklistFilterHeader">My Filters</li>
    <li
        class="option"
        v-bind:class="{ active: filter.name === selectedFilter.name }"
        v-for="filter in getCustomFilters"
        v-bind:key="filter.worklistFilterId"
        @click="setCustomFilter(filter, true)"
    >
      <span v-testid="filter.name">({{ filter.count }}) {{ filter.name }} </span>
    </li>   
</transition-group>
</div>
</template>

<script>
import { mapState } from 'vuex'
import GenericLoader from '@/components/GenericLoader'
import logger from '@/shared/logger'
import paginatedFilterMapping from '@/mixins/paginatedFilterMapping';

export default {
  name: "UserWorklistFilters",
  props: ['setSelectedFilter', 'selectedFilter', 'worklistFilters' ],
  mixins: [paginatedFilterMapping],
  components: {GenericLoader},
  computed: {
      ...mapState(['customUserFilters']),
      getCustomFilters: function () {
        return this.worklistFilters.reduce((acc, worklistFilter, index) => {
          acc.push({
            ...worklistFilter,
            name: this.customUserFilters[index].name
          })

          return acc
        }, [])
      }
  },
  methods: {
    setCustomFilter: function (filter, bool) {
      this.setSelectedFilter(filter, bool)

      logger.trackCustomFilterSelectionEvent({
        filter: {
          name: filter.name,
          worklistFilterId: filter.worklistFilterId,
          filter: JSON.parse(filter.filter)
        },
        active: bool 
      })
    },
  },
};
</script>

<style scoped>
.loadingContainer > div {
    text-align: inherit !important;
}
</style>

<template>
  <div id="worklist" class="worklist2">
    <div id="customFilters">
      <div key="filterOverview" class="filterOverview"  @click="focusedFilter = false">
        <div class="savedViews" v-bind:class="showViewsList ? 'open':''">
          <div v-if="isFilterCountsLoading" class="currentFilterLoading">
            <span class="currentFilteLoadingText">
              Loading Filters
            </span>
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div v-else class="currentFilter" @click="toggleViewsList" v-testid="'system-filters'">
            <span class="currentFilterName" v-testid="selectedFilter.name">
              {{(selectedFilter && selectedFilter.name) || 'All Requests'}} 
              <span class="modified" v-if="filterModified || filterModifierHasBeenUpdated || filterSortHasBeenModified">(Modified)</span>
            </span>
          </div>
          <div class="listOfFilters" v-if="showViewsList">
            <span class="filtersHeader">
              Select Filter
            </span>
            <div class="availableFilters" v-clickoutside="toggleViewsList">
              <ul>
                <li class="header">System Filters</li>
                <li class="option" 
                  v-bind:class="{active: filter.name == selectedFilter.name}" 
                  v-for="filter in systemFilters" 
                  v-bind:key="filter.worklistFilterId" 
                  @click="setSelectedFilterAndMakeRequest(filter, true)"
                >
                  <span v-testid="filter.name">{{filter.name}}</span>
                </li>
              </ul>
              <UserWorklistFilters
                :setSelectedFilter="setSelectedFilterAndMakeRequest"
                :selectedFilter="selectedFilter"
                :worklistFilters="customUserFilters">
              </UserWorklistFilters>
            </div>
          </div>
          
        </div>
        <div class="filterDetails">
          <div class="saveFilter">
            <a v-if="!isFilterCountsLoading" href="#" @click.prevent="toggleShowSave">
              <span v-testid="'update-or-save-filter'" v-if="canUpdateOrSaveFilter">Update or Save Filter</span>
              <span v-testid="'manage-filters'" v-else-if="customUserFilters.length > 0">Manage Filters</span>
            </a>
          </div>
          <div class="actions">
            <a v-testid="'reset-filters'" href="#" v-if="hasFilterChanges" @click.prevent="resetAllFilters"><i class="fas fa-sync-alt"></i> Reset All</a>
            <a v-testid="'show-hide-filters'" href="#" @click.prevent="toggleSetFiltersList"><span v-if="showSetFilters"><i class="far fa-eye-slash"></i> Hide Filters</span><span v-if="!showSetFilters"><i class="far fa-eye"></i> Show Filters</span></a>
          </div>
        </div>
      </div>
      
      <transition name="fade">
        <div class="setFilters" v-if="showSetFilters">
            <VirtualSelect
              v-if="workers.length && userCounts.length"
              v-testid="'assigned-to-filter'" 
              v-model="filters.filterByAssigned"
              :selectedValue="filters.filterByAssigned"
              :loading="!workersSortedByDepartment.length"
              :list="workersSortedByDepartment"
              :mapper="workerForSelect => ({
                label: `${workerForSelect.fullName} (${getRequestCountByPaPersonId(workerForSelect.paPersonId)})`,
                value: workerForSelect.paPersonId
              })"
              :dataParser="value => isNaN(parseInt(value)) ? value : parseInt(value)"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByAssigned"
            >
              <template v-slot:label>
                <i class="fas fa-user"></i> Assigned To
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByAssigned"
                  :value="localContainsDoesNotContainsInfo?.[`filterByAssigned`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>
            
            <VirtualSelect
              v-if="providers.length"
              v-model="filters.filterByProvider"
              v-testid="'provider-filter'"
              :selectedValue="filters.filterByProvider"
              :loading="!worklistProviders.length"
              :list="worklistProviders"
              :mapper="worklistProviderMapper"
              :dataParser="value => parseInt(value)"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByProvider"
            >
              <template v-slot:label>
                <i class="fas fa-user-md"></i> Ordering Provider
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByProvider"
                  :value="localContainsDoesNotContainsInfo?.[`filterByProvider`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect 
              v-if="filterableStatuses"
              v-model="filters.filterByStatus"
              v-testid="'status-filter'"
              :selectedValue="filters.filterByStatus"
              :loading="!filterableStatuses.length"
              :list="filterableStatuses"
              :mapper="filterableStatus => ({
                label: filterableStatus.PrettyName,
                value: filterableStatus.Id
              })"
              :dataParser="value => parseInt(value)"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByStatus"
            >
              <template v-slot:label>
                <i class="fas fa-folder-open"></i> Status
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByStatus"
                  :value="localContainsDoesNotContainsInfo?.[`filterByStatus`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect
              v-if="payerList.length"
              v-model="filters.filterByPayer"
              v-testid="'payer-filter'"
              :selectedValue="filters.filterByPayer"
              :loading="!payerList.length"
              :list="payerList"
              :mapper="payor => ({
                label: payor.company,
                value: payor.insuranceCompanyId
              })"
              :dataParser="value => parseInt(value)"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByPayer"
            >
              <template v-slot:label>
                <i class="fas fa-building"></i> Payer
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByPayer"
                  :value="localContainsDoesNotContainsInfo?.[`filterByPayer`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect
              v-if="submissionFilterOptions.length"
              v-model="filters.filterBySubmissionMethod"
              v-testid="'submission-method-filter'"
              :selectedValue="filters.filterBySubmissionMethod"
              :loading="!submissionFilterOptions.length"
              :list="submissionFilterOptions"
              :mapper="submissionFilterOption => ({
                label: submissionFilterOption.displayName,
                value: submissionFilterOption.displayName
              })"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterBySubmissionMethod"
            >
              <template v-slot:label>
                <i class="fas fa-building"></i> Submission Method
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterBySubmissionMethod"
                  :value="localContainsDoesNotContainsInfo?.[`filterBySubmissionMethod`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect
              v-if="submissionStatusFilterOptions.length" 
              v-model="filters.filterBySubmissionStatus"
              v-testid="'submission-status-filter'"
              :selectedValue="filters.filterBySubmissionStatus"
              :loading="!submissionFilterOptions.length"
              :list="submissionStatusFilterOptions"
              :mapper="submissionStatusFilterOption => ({
                label: submissionStatusFilterOption.categoryName,
                value: submissionStatusFilterOption.categoryName
              })"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterBySubmissionStatus"
            >
              <template v-slot:label>
                <i class="fas fa-building"></i> Submission Status
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterBySubmissionStatus"
                  :value="localContainsDoesNotContainsInfo?.[`filterBySubmissionStatus`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect
              v-if="showDepartmentFilter"
              v-model="filters.filterByDepartments"
              v-testid="'departments-filter'"
              :selectedValue="filters.filterByDepartments"
              :loading="!availableDepartmentsForTheCurrentUser.length"
              :list="availableDepartmentsForTheCurrentUser"
              :mapper="availableDepartment => ({
                label: availableDepartment.name,
                value: availableDepartment.departmentId
              })"
              :dataParser="value => parseInt(value)"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByDepartments"
            >
              <template v-slot:label>
                <i class="fas fa-building"></i> Team
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByDepartments"
                  :value="localContainsDoesNotContainsInfo?.[`filterByDepartments`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <VirtualSelect
              v-if="facilities && facilities.length" 
              v-model="filters.filterByFacility"
              v-testid="'facility-filter'"
              :selectedValue="filters.filterByFacility"
              :loading="!facilities.length"
              :list="facilities"
              :mapper="facility => ({
                label: facility.name,
                value: facility.name
              })"
              @input="resetAndSearch"
              @clear="clearModifiers"
              placeholder="Select"
              id="filterByFacility"
            >
              <template v-slot:label>
                <i class="fas fa-building"></i> Servicing Facility
              </template>

              <template v-slot:header>
                <ContainsDoesNotContains 
                  parent="filterByFacility"
                  :value="localContainsDoesNotContainsInfo?.[`filterByFacility`]"
                  @change="handleContainsDoesNotContainsUpdates"
                />
              </template>
            </VirtualSelect>

            <div class="dateFilterWrap" id="filterCreatedDate" @click="focusedFilter = false">
              <label><i class="fas fa-calendar-star"></i> Created Dates: <span></span></label>
              <DatePicker @input="pickDate" @pick="removeRelatedFilters('createdDateRange')" @clear="clearWithinDatesSelected('createdDateRange')" v-model="filters.createdDateRange" range :first-day-of-week="7" placeholder="Select Range" lang="en" format="MM/DD/YYYY" :formatter="momentFormat" value-type="token(MM/DD/YYYY)"  v-testid="'created-date-filter'">
                <template v-slot:header="{ emit }">
                  <div class="signInInfo infoBelow grayContainer">
                    <div class="datePickerHeaderContainer">
                      <div class="withinDaysContainer">
                          <label>Last <input type="number" min="0" max="365" step="1" ref="createdDateRangeWithinNDaysModel" :value="createdDateRangeWithinNDaysModel" /> day(s)</label>
                      
                          <div class="bigButton">
                            <button @click="selectLastNAmountOfDays(emit, 'createdDateRange', $refs.createdDateRangeWithinNDaysModel.value)">GO</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
            </div>

            <div class="dateFilterWrap" id="filterProcedureDate" @click="focusedFilter = false">
              <label><i class="fas fa-calendar-star"></i> Procedure Dates: <span></span></label>
              <DatePicker @input="pickDate" @pick="removeRelatedFilters('procedureDateRange')" @clear="clearWithinDatesSelected('procedureDateRange')" v-model="filters.procedureDateRange" range :first-day-of-week="7" placeholder="Select Range" lang="en" format="MM/DD/YYYY" :formatter="momentFormat" value-type="token(MM/DD/YYYY)" v-testid="'procedure-date-filter'">
                <template v-slot:input v-if="filters.procedureDateRangeDisplayWithDaysInput.length">
                  <input name="date" type="text" autocomplete="off" placeholder="Select Range" class="mx-input" :value="`Through ${procedureDateRangeNDaysInputDisplay}`" />
                </template>
                <template v-slot:header="{ emit }">
                  <div class="signInInfo infoBelow grayContainer">
                    <div class="datePickerHeaderContainer">
                      <div class="withinDaysContainer">
                          <label>Within <input type="number" min="0" max="365" step="1" ref="procedureDateRangeWithinNDaysModel" :value="procedureDateRangeWithinNDaysModel" /> day(s)</label>
                          <div class="bigButton">
                            <button @click="selectWithinNAmountOfDays(emit, 'procedureDateRange', $refs.procedureDateRangeWithinNDaysModel.value, filters.procedureDateRangeNullDatesIncluded.length > 0)">GO</button>
                          </div>
                      </div>
                      <div class="includeNullDatesContainer">
                          <label>
                              <input type="checkbox" :checked="filters.procedureDateRangeNullDatesIncluded.length > 0" @click="includeNullDates($event.target.checked, 'procedureDateRange')" />
                              Include Dates Not Set
                          </label>
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
            </div>

            <div class="dateFilterWrap" id="filterFollowUpDate">
              <label><i class="fas fa-calendar-check"></i> Follow Up Dates: <span></span></label>
              <DatePicker @input="pickDate" @pick="removeRelatedFilters('followUpDateRange')" @clear="clearWithinDatesSelected('followUpDateRange')" v-model="filters.followUpDateRange" range :first-day-of-week="7" placeholder="Select Range" lang="en" format="MM/DD/YYYY" :formatter="momentFormat" value-type="token(MM/DD/YYYY)" v-testid="'followup-date-filter'">
                <template v-slot:input v-if="filters.followUpDateRangeDisplayWithDaysInput.length">
                  <input name="date" type="text" autocomplete="off" placeholder="Select Range" class="mx-input" :value="`Through ${followUpDateRangeNDaysInputDisplay}`" />
                </template>
                <template v-slot:header="{ emit }">
                  <div class="signInInfo infoBelow grayContainer">
                    <div class="datePickerHeaderContainer">
                      <div class="withinDaysContainer">
                          <label>Within <input type="number" min="0" max="365" step="1" ref="followUpDateRangeWithinNDaysModel" :value="followUpDateRangeWithinNDaysModel" /> day(s)</label>
                          <div class="bigButton">
                            <button @click="selectWithinNAmountOfDays(emit, 'followUpDateRange', $refs.followUpDateRangeWithinNDaysModel.value, filters.followUpDateRangeNullDatesIncluded.length > 0)">GO</button>
                          </div>
                      </div>
                      <div class="includeNullDatesContainer">
                          <label>
                              <input type="checkbox" :checked="filters.followUpDateRangeNullDatesIncluded.length > 0" @click="includeNullDates($event.target.checked, 'followUpDateRange')" />
                              Include Dates Not Set
                          </label>
                      </div>
                    </div>
                  </div>
                </template>
              </DatePicker>
            </div>
           
          <div class="currentTags"
           @click="focusedFilter = false"
            v-if="hasRequestTags"
          >
            <transition-group name="filter-list" tag="div">
              <ul key="filtered_list">
                <li class="header" key="request_matching">Requests matching:</li>
                <li v-for="worker in getFilterByAssignedTags" 
                  @click="removeFromFilter(worker.paPersonId, 'filterByAssigned')"
                  class="filterItem person"
                  :key="worker.paPersonId || 'null'"
                  :class="{new: !isInAppliedFilter(worker.paPersonId, 'filterByAssigned')}"
                >
                  {{ 'filterByAssigned' | filterModifier(filterUpdates) }} {{worker.fullName}}
                </li>

                <li v-for="(physician, index) in filteredProviders" 
                  @click="removeFromFilter(physician.verataPaProviderId, 'filterByProvider')"
                  class="filterItem doctor"
                  :class="{new: !isInAppliedFilter(physician.verataPaProviderId, 'filterByProvider')}"
                  :key="`${physician.verataPaProviderId}-${index}`"
                >
                  {{ 'filterByProvider' | filterModifier(filterUpdates) }} {{physician.fullName}}<span v-if="physician.npi">&nbsp;- {{physician.npi}}</span>
                </li>

                <li v-for="status in filteredStatusList" 
                  @click="removeFromFilter(status.Id, 'filterByStatus')"
                  class="filterItem statusFilter"
                  :class="{new: !isInAppliedFilter(status.PrettyName, 'filterByStatus')}"
                  v-bind:key="status.Id"
                >
                  {{ 'filterByStatus' | filterModifier(filterUpdates) }} {{status.PrettyName}}
                </li>
                <li v-for="facility in facilities.filter(f => filters.filterByFacility.includes(f.name))"
                  @click="removeFromFilter(facility.name, 'filterByFacility')"
                  class="filterItem facilityFilter"
                  :class="{new: !isInAppliedFilter(facility, 'filterByFacility')}"
                  :key="facility.paFacilityId"
                  >
                  {{ 'filterByFacility' | filterModifier(filterUpdates) }}  {{facility.name}}
                </li>
                <li v-for="payer in filteredPayerList" 
                  @click="removeFromFilter(payer.insuranceCompanyId, 'filterByPayer')"
                  class="filterItem payerName"
                  :class="{new: !isInAppliedFilter(payer.insuranceCompanyId, 'filterByPayer')}"
                  :key="payer.insuranceCompanyId"
                  >
                    {{ 'filterByPayer' | filterModifier(filterUpdates) }} {{payer.company}}
                </li>
                <li
                  v-if="!!filteredMrnList"
                  @click="removeFromFilter(filteredMrnList, 'filterByMrn')"
                  class="filterItem mrnFilter"
                  :class="{new: !isInAppliedFilter(filteredMrnList, 'filterByMrn')}"
                  :key="filteredMrnList"
                  >
                  {{filteredMrnList}}
                </li>
                <li
                  v-if="!!filteredPatientFirstNameList" 
                  @click="removeFromFilter(filteredPatientFirstNameList, 'filterByPatientFirstName')"
                  class="filterItem firstNameFilter"
                  :class="{new: !isInAppliedFilter(filteredPatientFirstNameList, 'filterByPatientFirstName')}"
                  :key="filteredPatientFirstNameList"
                  >
                  {{filteredPatientFirstNameList}}
                </li>
                <li
                  v-if="!!filteredPatientLastNameList"
                  @click="removeFromFilter(filteredPatientLastNameList, 'filterByPatientLastName')"
                  class="filterItem lastNameFilter"
                  :class="{new: !isInAppliedFilter(filteredPatientLastNameList, 'filterByPatientLastName')}"
                  :key="filteredPatientLastNameList"
                  >
                  {{filteredPatientLastNameList}}
                </li>
                <li
                  v-if="!!filteredEmrIdList"
                  @click="removeFromFilter(filteredEmrIdList, 'filterByEmrId')"
                  class="filterItem emrFilter"
                  :class="{new: !isInAppliedFilter(filteredEmrIdList, 'filterByEmrId')}"
                  :key="filteredEmrIdList"
                  >
                  {{filteredEmrIdList}}
                </li>
                <li
                  v-if="!!filteredCptCodeList"
                  @click="removeFromFilter(filteredCptCodeList, 'filterByCptCode')"
                  class="filterItem cptCodeFilter"
                  :class="{new: !isInAppliedFilter(filteredCptCodeList, 'filterByCptCode')}"
                  :key="filteredCptCodeList"
                  >
                  {{filteredCptCodeList}}
                </li>
                <li
                  v-if="!!filteredProcedureDescriptionList"
                  @click="removeFromFilter(filteredProcedureDescriptionList, 'filterByProcedureDescription')"
                  class="filterItem cptCodeFilter"
                  :class="{new: !isInAppliedFilter(filteredProcedureDescriptionList, 'filterByProcedureDescription')}"
                  :key="filteredProcedureDescriptionList"
                  >
                  {{filteredProcedureDescriptionList}}
                </li>
                <li v-for="method in filters.filterBySubmissionMethod" 
                  @click="removeFromFilter(method, 'filterBySubmissionMethod')"
                  class="filterItem methodName"
                  :class="{new: !isInAppliedFilter(method, 'filterBySubmissionMethod')}"
                  :key="method"
                  >
                  {{ 'filterBySubmissionMethod' | filterModifier(filterUpdates) }} {{method}}
                </li>
                <li v-for="status in submissionStatusCategories.filter(c => filters.filterBySubmissionStatus.includes(c.categoryName))" 
                  @click="removeFromFilter(status.categoryName, 'filterBySubmissionStatus')"
                  class="filterItem methodStatus"
                  :class="{new: !isInAppliedFilter(status.categoryName, 'filterBySubmissionStatus')}"
                  :key="status.categoryName"
                  >
                  {{ 'filterBySubmissionStatus' | filterModifier(filterUpdates) }} {{status.categoryName}}
                </li>
                <li v-for="dept in departments.filter(d => filters.filterByDepartments.includes(d.departmentId))" 
                  @click="removeFromFilter(dept.departmentId, 'filterByDepartments')"
                  class="filterItem payerName"
                  :class="{new: !isInAppliedFilter(dept.departmentId, 'filterByDepartments')}"
                  :key="dept.departmentId"
                >
                  {{ 'filterByDepartments' | filterModifier(filterUpdates) }} {{dept.name}}
                </li>
                <li v-if="filters.procedureDateRangeNullDatesIncluded.length"
                  @click="removeFromFilter('true', 'procedureDateRangeNullDatesIncluded')"
                  class="filterItem procedureDatesNullIncluded"
                  :class="{new: !isInAppliedFilter('true', 'procedureDateRangeNullDatesIncluded')}"
                >
                  Procedure Dates Not Set
                </li>
                <li v-if="filters.procedureDateRangeDisplayWithDaysInput.length"
                  @click="removeFromFilter('true', 'procedureDateRangeDisplayWithDaysInput')"
                  class="filterItem methodName procedureDateRangeDisplay"
                  :class="{new: !isInAppliedFilter('true', 'procedureDateRangeDisplayWithDaysInput')}"
                >
                  Procedure dates within {{procedureDateRangeWithinNDaysModel}} day(s)
                </li>
                <li v-if="filters.createdDateRangeDisplayWithDaysInput.length"
                  @click="removeFromFilter('true', 'createdDateRangeDisplayWithDaysInput')"
                  class="filterItem methodName createdDateRangeDisplay"
                  :class="{new: !isInAppliedFilter('true', 'createdDateRangeDisplayWithDaysInput')}"
                >
                  Created dates last {{createdDateRangeWithinNDaysModel}} day(s)
                </li>
                <li v-if="filters.followUpDateRangeNullDatesIncluded.length"
                  @click="removeFromFilter('true', 'followUpDateRangeNullDatesIncluded')"
                  class="filterItem followUpDateRangeNullDatesIncluded"
                  :class="{new: !isInAppliedFilter('true', 'followUpDateRangeNullDatesIncluded')}"
                >
                  Follow Up Dates Not Set
                </li>
                <li v-if="filters.followUpDateRangeDisplayWithDaysInput.length"
                  @click="removeFromFilter('true', 'followUpDateRangeDisplayWithDaysInput')"
                  class="filterItem methodName followUpDateRangeDisplay"
                  :class="{new: !isInAppliedFilter('true', 'followUpDateRangeDisplayWithDaysInput')}"
                >
                  Follow up dates within {{followUpDateRangeWithinNDaysModel}} day(s)
                </li>
              </ul>
            </transition-group>
          </div>
      </div>
      </transition>
    </div>
    
    <div class="tileWrap" @click="focusedFilter = false" ref="tableWrapper">
      <CustomizedRequestTable 
        class="myRequests" 
        :requestList="filteredRequests" 
        :allRequestsCount="allRequestsCount"
        :readonly="false" 
        :filters="filters" 
        :pageNumber="pageNumber"
        :sortingInfo="worklistSortInfo"
        :downloadFilteredRequestHandler="downloadFilteredRequestHandler"
        :multiSortActive="hasMultiSortActivated"
        :activeColumnHeaders="activeColumnHeaders"
        location="worklist"
        title="My PA Requests"
        @searchTermUpdated="searchTermUpdated"
        @pageSelected="pageSelected"
        @sorting="sorting"
        @featureToggle="featureToggle"
      />
    </div>
    
    <SavedFiltersModalVue 
      v-if="showSaveWindow"
      :setSelectedFilter="setSelectedFilter"
      :selectedFilter="selectedFilter || {}"
      :handleCurrentFilterDelete="handleCurrentFilterDelete"
      :setFilterUpdates="setFilterUpdates"
      @close="toggleShowSave" 
    />

    <EditSavedFilter
      v-if="filterToEdit"
      :filter="filterToEdit"
      :sortFilterMapper="sortFilterMapper"
      :dataParser="value => value"
      :sortDirectionOptions="['ASC', 'DESC']"
      :sortDirectionMapper="sortDirectionMapper"
      :departmentsMapper="departmentsMapper"
      @close="closeEditSavedFilterModal"
    />
  
  </div>
</template>

<script>
import UserWorklistFilters from '@/components/UserWorklistFilters'
import clickoutside from '@/directives/clickoutside'
import utility from '@/mixins/utility'
import logger from '@/shared/logger'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import { mapGetters, mapState } from 'vuex'
import CustomizedRequestTable from '@/components/CustomizedRequestTable'
import VirtualSelect from '@/components/VirtualSelect'
import { VirtualSelectService } from '@/services/virtualSelect'
import SavedFiltersModalVue from './Modal.SavedFiltersModal'
import { WorklistService } from '@/services/worklist'
import { UtilityService } from '@/services/utility'
import PaginatedFilterMapping from '@/services/paginatedFilterMapping'
import { WORKLIST_HEADER_STATES } from '@/shared/constants/worklist'
import { Filter, Sort, SearchTerm } from '@/shared/models/worklist'
import ContainsDoesNotContains from '@/components/ContainsDoesNotContains'
import EditSavedFilter from '@/components/Modal.EditSavedFilter'
import { DataExportApi } from '@/services/dataExportApi'

const defaultNDays = 7

export default {
  name: 'SortedWorklist',
  directives: { clickoutside },
  mixins: [utility],
  components: { 
    CustomizedRequestTable, 
    DatePicker, 
    UserWorklistFilters, 
    VirtualSelect,
    SavedFiltersModalVue,
    ContainsDoesNotContains,
    EditSavedFilter
  },
  data: function() {
    return {
      localContainsDoesNotContainsInfo: {},
      procedureDateRangeNullDatesIncluded: [],
      createdDateRangeNullDatesIncluded: [],
      followUpDateRangeNullDatesIncluded: [],
      axiosSource: axios.CancelToken.source(),
      filterDebounceTimeout: false,
      isCallExemptFromDebounce: false,
      poll: false,  // used if error trying to connect to websock
      pollNewRequests: false, // used to check for new requests every 5 mins
      pollRequestsTimer: false,
      requestList: [],
      appliedFilter: "{}",
      selectedFilter: false,
      savingFilter: false,
      showViewsList: false,
      showSetFilters: true,
      focusedFilter: false,
      currentFilterName: 'Default',
      showSaveWindow: false,
      dateStart: '',
      dateEnd: '',
      buildNum: VERSION,
      workersForSelect: [],
      paginatedRequestTimeout: 0,
      filterDateRange: '',
      isFilterCountsLoading: false,
      savedFilterIsDefault: false,
      savedFilterIsShared: false,
      departmentToShareFilterWith: [],
      noop: () => {},
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('MM/DD/YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY']).toDate() : null
        },
        getWeek: (date) => {
          return // a number
        }
      },
      filterParsers: {
        'filterByAssigned':
          {
            niceName: 'Assigned To',
            parser: (workers) => this.workers.filter( w => {
              if(workers.includes(w.paPersonId)) return w
            }).map(w => w.fullName)
          }
      },
      pageNumber: 1,
    }
  },
  filters: {
    filterModifier: function (filterName, filterUpdates) {
      return filterUpdates?.[`${filterName}`]?.operator === 'NEQ' ? 'Does Not Contain' : 'Contains'
    }
  },
  created: function () {},
  beforeMount: async function () {
    if (this.loggedIn) {      
      this.$store.dispatch('LOAD_REQUESTS')

      // clear the filters
      this.$store.dispatch('worklist/CLEAR_WORKLIST_FILTERS')

      this.initContainsDoesNotContainsStartupValues()

      this.setInitialActiveColumnHeaderState()

      // call first bc it could take a long time
      this.getSystemFiltersAndCustomUserFilters()

      this.showSetFilters = JSON.parse(sessionStorage.getItem('showWorklistFilters')) === null ? true : JSON.parse(sessionStorage.getItem('showWorklistFilters'))

      window.addEventListener('beforeunload', this.saveCurrentFilter)

      let sessionWorklistPage = sessionStorage.getItem('worklistCurrentPage') || 1
      sessionWorklistPage == '0' ? sessionWorklistPage = 1 : sessionWorklistPage = sessionWorklistPage
      this.$store.commit('SET_ALL_REQUESTS_COUNT', 0)
      this.$store.commit("SET_WORKLIST_CURRENT_PAGE", sessionWorklistPage);

      this.pageNumber = sessionWorklistPage

      if (this.currUserID) {
        let worker = this.workers.find(w => w.paPersonId == this.currUserID)
        if (worker && this.currUser.departmentId != worker.departmentId) {
          this.$store.commit('SET_SHOW_ALL_DEPARTMENT_REQUESTS', true)
        }
      }

      // fetch requests if we have filters from archive
      if (this.$route.params.filters) {
        this.setFiltersWithRouteParamFilterObject(this.$route.params)
      } else if (this.$route.name === "UserRequests") {
        this.setFiltersWithRouteParamFilterObject({
          filters: {
            filterByAssigned: [this.$route.params.userId]
          }
        })
      } else {
        this.applyDefaultSearchFilter()
        this.applyPreviousSearchFilter()
      }

      this.$store.dispatch('CLEAR_PA_REQUEST_STATE')

      if (this.fallbackToPolling) this.poll = true
      if (!this.fallbackToPolling) this.pollNewRequests = true

      this.setVirtualSelectValues(true)
      this.filterPaginatedRequests()

    } else {
      this.$store.dispatch('auth/CHECK_SESSION_TOKEN')
    }
  },
  methods: {
    downloadFilteredRequestHandler: function () {
      this.$store.dispatch('downloadQueue/BEGIN_DOWNLOAD', {
        promise: new Promise((resolve, reject) => {
          DataExportApi.worklistByFilters(this.worklistParams)
              .then(resolve)
              .catch(error => reject(error));
        }),
        from: 'PA Worklist'
      })
    },
    closeEditSavedFilterModal: function () {
      this.$store.dispatch('worklist/RESET_FILTER_TO_EDIT')
    },
    departmentsMapper: (availableDepartment) => ({
      label: availableDepartment.name,
      value: [availableDepartment.departmentId],
    }),
    sortFilterMapper(columnReference) {
      return {
        label: columnReference.name === "" ? "Urgency" : columnReference.name,
        value: columnReference.sort
      }
    },
    sortDirectionMapper(value) {
      return {
        label: value,
        value: value === "DESC" ? -1 : 1
      }
    },
    featureToggle: function (event) {
      if (event.name === 'multisort') {
        if (event.value) {
          this.setInitialActiveColumnHeaderState()
        } else {
          this.$store.dispatch("RESET_WORKLIST_SORT")
        }
      }
    },
    setInitialActiveColumnHeaderState: function () {
      const sortingParams = this.worklistSortInfo[0]

      const initialState = WorklistService.updateWorklistHeaderState({
        field: sortingParams?.field ?? 'urgency',
        state: WORKLIST_HEADER_STATES.ACTIVE
      })

      this.$store.dispatch('worklist/SET_ACTIVE_COLUMN_HEADERS', [ initialState ])
    },
    clearModifiers: function (event) {
      const filterKey = event.field
      this.localContainsDoesNotContainsInfo[filterKey] = 'CONTAINS'
      this.updateFilterModifiers({ filterKey, value: 'CONTAINS' })
      this.setFilterUpdates({ filterKey, value: 'CONTAINS' })
    },
    initContainsDoesNotContainsStartupValues: function () {
      Object.keys(this.filters).forEach((filterKey) => {
        if (!filterKey.toLowerCase().includes('date')) {
          this.localContainsDoesNotContainsInfo[filterKey] = this.getModifierValueFromFilterUpdates(filterKey)
          this.updateFilterModifiers({ filterKey, value: 'CONTAINS' })
        }
      })
    },
    setCustomFilterModifier: function (customFilter, filterName) {
      const filters = JSON.parse(customFilter ?? {})
      this.localContainsDoesNotContainsInfo = {}

      this.initContainsDoesNotContainsStartupValues()
      this.$store.dispatch('worklist/RESET_FILTER_UPDATES')

      Object.keys(filters).forEach((filterKey) => {
        const filterModifierValue = this.getModifierValueFromCustomUserFilters({ filterName, filterKey })
        
        this.localContainsDoesNotContainsInfo[filterKey] = filterModifierValue
        this.updateFilterModifiers({ filterKey, value: filterModifierValue })
        this.setFilterUpdates({ filterKey, value: filterModifierValue })
      })
    },
    getModifierValueFromFilterUpdates(filterName, modifier = 'operator') {
      return this.filterUpdates?.[`${filterName}`]?.[`${modifier}`] === 'NEQ' ? 'DOES_NOT_CONTAIN' : 'CONTAINS'
    },
    getModifierValueFromCustomUserFilters({ 
      filterName,
      filterKey,
      modifier = 'operator', 
      expectedValue = 'NEQ', 
      positiveOutcome = 'DOES_NOT_CONTAIN',
      negativeOutcome = 'CONTAINS'
    }) {
      const foundCustomUserFilter = this.customUserFilters.find(
        customUserFilter => customUserFilter.name === filterName
      )

      if (foundCustomUserFilter) {
        return foundCustomUserFilter?.modifiers?.[`${filterKey}`]?.[`${modifier}`] === expectedValue ? positiveOutcome : negativeOutcome
      }

      return null
    },
    setFilterUpdates: function ({ filterKey, value }) {
      if (!filterKey.toLowerCase().includes('date')) {
        this.$store.dispatch('worklist/SET_FILTER_UPDATES', {
          [filterKey]: {
            operator: value === 'DOES_NOT_CONTAIN' ? 'NEQ' : 'EQ'
          }
        })
      }
    },
    handleContainsDoesNotContainsUpdates: function ({ value, parent }) {
      this.localContainsDoesNotContainsInfo[parent] = value

      this.updateFilterModifiers({ filterKey: parent, value })
      this.setFilterUpdates({ filterKey: parent, value })

      if (this.filters[parent].length) {
        this.filterPaginatedRequests()
      }
    },
    handleCurrentFilterDelete: function () {
      this.selectedFilter = this.systemFilters.find(
        (f) => (f.worklistFilterId = "s1")
      );
    },
    worklistProviderMapper(worklistProvider) {
      const middleName = worklistProvider.middleName && worklistProvider.middleName !== 'null' ? ` ${worklistProvider.middleName}` : ''
      const providerNpi = worklistProvider.npi ? ` - ${worklistProvider.npi}` : ''

      return {
        label: `
          ${worklistProvider.fullName}${middleName}${providerNpi}
        `,
        value: worklistProvider.verataPaProviderId
      }
    },
    async getSystemFiltersAndCustomUserFilters(options) {
      this.isFilterCountsLoading = true;

      // make a request to get the filter counts in order to
      // properly display a previously selected filter
      await this.$store.dispatch('GET_FILTER_COUNTS').then((response) => {
        // this.checkForMatchingFilters()
        this.isFilterCountsLoading = false;
      });
    },
    checkForMatchingFilters() {
      // sees if currentFilter is in systemFilter or customerUserFilter
      const matchingFilter = this.getMatchingFilterFromCurrent

      if (matchingFilter && matchingFilter.length > 0){            
        this.selectedFilter = matchingFilter[0]
        this.appliedFilter = this.selectedFilter.filter

        const sortArray = this.getUniqueArray({
          array1: this.selectedFilter?.sort ?? [],
          array2: this.worklistSortInfo.map(
            sortInfo => new Sort({
              field: sortInfo.field,
              direction: parseInt(sortInfo.direction) === -1 ? 'DESC' : 'ASC'
            })
          ),
          field: 'field'
        })

        const sortingArray = this.sortFactory(sortArray)

        this.updateWorklistSort(sortingArray)
      }
    },
    sortFactory: function (sortArray) {
      return sortArray.map(
        sort => (
          new Sort({ 
            field: sort.field, 
            direction: (sort.dir === 'DESC' || sort.direction === 'DESC') ? -1 : 1 
          })
        )
      )
    },
    getUniqueArray({ array1, array2, field }) {
      return [
        ...new Map(
          [
            ...array1,
            ...array2
          ].map(item => [item[field], item])
        ).values()
      ]
    },
    saveCurrentFilter: function () {
      // set the worklist filter
      sessionStorage.setItem('worklistFilter', JSON.stringify(this.currentFilter))
    },
    setSelectedFilterAndMakeRequest: function (filter, close) {
      this.resetPage()
      this.setSelectedFilter(filter, close)
      this.setCustomFilterModifier(filter.filter, filter.name)
      this.setMultiSortToggle(filter)
      this.filterPaginatedRequests()
    },
    setMultiSortToggle: function (filter) {
      if (filter?.sort?.length > 1) {
        this.$store.dispatch('worklist/TURN_MULTI_SORT_ON')
      } else {
        this.$store.dispatch('worklist/TURN_MULTI_SORT_OFF')
        this.$store.dispatch('RESET_WORKLIST_SORT')
      }
    },
    resetAndSearch: function () {
      this.resetPage()
      this.filterPaginatedRequests()
    },
    resetPage: function () {
      sessionStorage.setItem("worklistCurrentPage", 1)
      this.$store.commit('SET_WORKLIST_CURRENT_PAGE', 1)
      this.pageNumber = 1
    },
    applyMultiSortLogic: function (sortingParamsArray, sortInstance) {
      // const sortingParams = sortingParamsArray[sortingParamsArray.length - 1]

      const existingActiveHeader = this.activeColumnHeaders.find(
        activeColumnHeader => activeColumnHeader.field === sortInstance.field
      )

      if (!existingActiveHeader) {
        const updatedState = WorklistService.updateWorklistHeaderState({
          field: sortInstance.field,
          state: WORKLIST_HEADER_STATES.ACTIVE
        })

        this.$store.dispatch('worklist/ADD_ACTIVE_COLUMN_HEADER', updatedState)
        this.$store.dispatch("SET_WORKLIST_SORT", sortingParamsArray)
      } else {
        const nextHeaderState = WorklistService.getNextWorklistHeaderState(
          existingActiveHeader.state
        )

        if (nextHeaderState === WORKLIST_HEADER_STATES.INACTIVE) {
          if (sortingParamsArray.length > 1) {
            this.$store.dispatch('worklist/REMOVE_ACTIVE_COLUMN_HEADER', existingActiveHeader)
            this.$store.dispatch("REMOVE_WORKLIST_SORT", sortInstance)
          } else {
            const updatedState = WorklistService.updateWorklistHeaderState({
              field: sortInstance.field,
              state: WORKLIST_HEADER_STATES.ACTIVE
            })

            this.$store.dispatch('worklist/ADD_ACTIVE_COLUMN_HEADER', updatedState)
            this.$store.dispatch("SET_WORKLIST_SORT", sortingParamsArray)
          }
        } else {
          this.$store.dispatch('worklist/UPDATE_ACTIVE_COLUMN_HEADER_STATE', {
            ...existingActiveHeader,
            state: nextHeaderState
          })
          this.$store.dispatch("SET_WORKLIST_SORT", sortingParamsArray)
        }
      }
    },
    sorting: function (sortingParamsArray, sortInstance) {
      if (this.hasMultiSortActivated) {
        this.applyMultiSortLogic(sortingParamsArray, sortInstance)
      } else {
        this.$store.dispatch("SET_WORKLIST_SORT", sortingParamsArray)
      }

      this.filterPaginatedRequests();
    },
    searchTermUpdated: function () {
      this.resetPage()

      // clear out any archive requests
      this.$store.commit('SET_WORKLIST_CURRENT_FILTERS', this.formattedCurrentFilter)

      this.filterPaginatedRequests();
    },
    updateFilterModifiers: function (params) {
      const event = new CustomEvent(`${params.filterKey}Update`, {
        bubbles: false,
        detail: { value: params.value },
      });

      document.getElementById(`${params.filterKey}RadioGroup`)
              ?.dispatchEvent(event);
    },
    resetFilterModifiers: function () {
      this.localContainsDoesNotContainsInfo = {}
      Object.keys(this.filters).forEach((filterKey) => {
        this.localContainsDoesNotContainsInfo[filterKey] = "CONTAINS"

        this.updateFilterModifiers({ filterKey, value: "CONTAINS" })
      })

      this.$store.dispatch('worklist/RESET_FILTER_UPDATES')
    },
    resetAllFilters: function () {
      this.clearFilter()
      this.resetFilterModifiers()
      this.resetAndSearch()
      this.resetSortOrderAndKey()
    },
    resetSortOrderAndKey: function () {
      this.$store.dispatch('RESET_WORKLIST_SORT')
      this.$store.dispatch('worklist/TURN_MULTI_SORT_OFF')
    },
    resetRouteParamFilters: function() {
      this.$route.params.filters = [];
    },
    clearWithinDatesSelected: function (filterName) {
      this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
        [`${filterName}DisplayWithDaysInput`]: [],
        [`${filterName}WithinNDays`]: [],
        [`${filterName}NullDatesIncluded`]: []
      })
    },
    includeNullDates: function (isIncluded, filterName) {
      if (isIncluded) {
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          [`${filterName}NullDatesIncluded`]: ['true']
        })
      } else {
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          [`${filterName}NullDatesIncluded`]: []
        })
      }
    },
    pageSelected: function(pageNumber){
      var numPagesBeforeLoadingPAs = Math.floor( this.allRequestsCount / this.worklistDisplaySettings.worklistRequestsPerPage );

      // If user has click on the last page, reload all PAs to ensure there aren't less than before 
      // e.g. if there were 10 pages when the user loaded the worklist, and they waited a few minutes -
      // Then there may now only be 9 pages worth of PAs, so there is no page 10 any more
      if( pageNumber >= numPagesBeforeLoadingPAs ){
        this.$store.dispatch('GET_PAGINATED_REQUESTS');
        var numPagesAfterLoadingPAs = Math.floor( this.allRequestsCount / this.worklistDisplaySettings.worklistRequestsPerPage );

        // If this is the case - set the current page as the actual last page
        if ( numPagesBeforeLoadingPAs > numPagesAfterLoadingPAs ){
            pageNumber = numPagesAfterLoadingPAs;
        }
      }

      document.documentElement.scrollTop = this.$refs.tableWrapper.offsetTop

      sessionStorage.setItem("worklistCurrentPage", pageNumber);
      this.$store.commit("SET_WORKLIST_CURRENT_PAGE", pageNumber);

      this.pageNumber = pageNumber

      this.filterPaginatedRequests()
    },
    setDefaultFilter: function () {    
      this.setSelectedFilter({})
      this.selectedFilter = false
    },
    applyPreviousSearchFilter: function() {
      const filtersFromSession = JSON.parse(sessionStorage.getItem('worklistFilter'))

      if (filtersFromSession && Object.keys(filtersFromSession).length > 0) {
        this.$store.dispatch('worklist/SET_WORKLIST_FILTERS', {
          ...filtersFromSession
        })

        this.applyMatchedFilterIfItExists()
      }
    },
    applyDefaultSearchFilter: function() {
      const filtersFromSession = JSON.parse(sessionStorage.getItem('worklistFilter'))

      const hasPreviousSearchTerm = this.textSearchTerm?.filterKey === "" && this.textSearchTerm?.filter?.filters?.length > 0

      if (
          this.defaultFilterToSearchOnStart && 
          !hasPreviousSearchTerm && 
          (!filtersFromSession || Object.keys(filtersFromSession).length === 0)
        ) {
        const defaultFilter = PaginatedFilterMapping.reverseEngineerFilters({
          ...this.defaultFilterToSearchOnStart,
          filter: JSON.stringify({
            Filter: {
              Filters: this.defaultFilterToSearchOnStart
                           .filter
                           .filter
                           .filters.map(filter => (new Filter({
                              field: filter.field,
                              operator: filter.operator,
                              value: filter.value,
                           })))
            }
          }),
          sort: this.defaultFilterToSearchOnStart
                    .filter
                    .sort
        })

        this.$store.dispatch('worklist/SET_WORKLIST_FILTERS', {
          ...JSON.parse(defaultFilter.filter)
        })

        this.setMultiSortToggle(this.defaultFilterToSearchOnStart.filter)
        this.applyMatchedFilterIfItExists()
      }
    },
    applyMatchedFilterIfItExists: function () {
      const matchingFilter = this.getMatchingFilterFromCurrent

      if (matchingFilter && matchingFilter.length > 0) {
        const filterModifiers = matchingFilter[0]?.modifiers ?? {}
        Object.keys(filterModifiers).forEach(
          filterKey => {
            const modifierToCheck = this.filterUpdates[filterKey] ?? filterModifiers[filterKey]
            const value = modifierToCheck.operator === "NEQ" ? "DOES_NOT_CONTAIN" : "CONTAINS"

            this.localContainsDoesNotContainsInfo[filterKey] = value
            this.updateFilterModifiers({ filterKey, value })
            this.setFilterUpdates({ filterKey, value })
          })
      }
    },
    pickDate() {
      this.resetPage()
      this.filterPaginatedRequests()
    },
    removeRelatedFilters(filterName) {
      this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
        [`${filterName}DisplayWithDaysInput`]: [],
        [`${filterName}WithinNDays`]: []
      })
    },
    selectLastNAmountOfDays(emit, filterName, days) {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - days * 24 * 3600 * 1000);
      const dateRange = [start, end];

      this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
        [`${filterName}DisplayWithDaysInput`]: ['true'],
        [`${filterName}NullDatesIncluded`]: this[`${filterName}NullDatesIncluded`],
        [`${filterName}WithinNDays`]: [days || defaultNDays]
      })

      emit(dateRange);
    },
    selectWithinNAmountOfDays(emit, filterName, days, nullDatesIncluded) {
      const convertedWinthinDays = WorklistService.convertDateFromDSL([days])

      this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
        [`${filterName}WithinNDays`]: [days],
        [filterName]: convertedWinthinDays,
        [`${filterName}DisplayWithDaysInput`]: ['true'],
        [`${filterName}NullDatesIncluded`]: this.filters[`${filterName}NullDatesIncluded`]
      })

      emit(convertedWinthinDays)
    },
    isInAppliedFilter: function(id, filter){
      if(!this.appliedFilter[filter]) return false
      return this.appliedFilter[filter].includes(id)
    },
    removeFromFilter: function(id, model){
      if(Array.isArray(this.filters[model])) {
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          [model]: this.filters[model].filter( m => m !== id)
        })

        if (this.isInAppliedFilter(id, model)){
          this.selectedFilter = false
          this.appliedFilter = '{}'
        }

        if (this.filters[model].length === 0) {
          this.updateFilterModifiers({ filterKey: model, value: 'CONTAINS' })
          this.setFilterUpdates({ filterKey: model, value: 'CONTAINS' })
        }

        const virtualSelectService = new VirtualSelectService(`#${model}`)
        if (virtualSelectService.el) {
          virtualSelectService.setValue(this.filters[model])
        }
      } else {
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          [model]: []
        })

        this.updateFilterModifiers({ filterKey: model, value: 'CONTAINS' })
        this.setFilterUpdates({ filterKey: model, value: 'CONTAINS' })

        const virtualSelectService = new VirtualSelectService(`#${model}`)
        if (virtualSelectService.el) {
          virtualSelectService.setValue(this.filters[model])
        }
      }
      if (model === 'procedureDateRangeDisplayWithDaysInput') {
        this.procedureDateRangeWithinNDaysModel = defaultNDays
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          procedureDateRange: [],
          procedureDateRangeWithinNDays: [],
          procedureDateRangeDisplayWithDaysInput: []
        })
      } else if (model === 'followUpDateRangeDisplayWithDaysInput') {
        this.followUpDateRangeWithinNDaysModel = defaultNDays
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          followUpDateRange: [],
          followUpDateRangeWithinNDays: [],
          followUpDateRangeDisplayWithDaysInput: []
        })
      } else if (model === 'createdDateRangeDisplayWithDaysInput') {
        this.createdDateRangeWithinNDaysModel = defaultNDays
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          createdDateRange: [],
          createdDateRangeWithinNDays: [],
          createdDateRangeDisplayWithDaysInput: []
        })
      } else if (model === 'followUpDateRangeNullDatesIncluded') {
        this.followUpDateRangeNullDatesIncluded = []
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          followUpDateRangeNullDatesIncluded: [],
        })
      } else if (model === 'procedureDateRangeNullDatesIncluded') {
        this.procedureDateRangeNullDatesIncluded = []
        this.$store.dispatch('worklist/UPDATE_WORKLIST_FILTERS', {
          procedureDateRangeNullDatesIncluded: [],
        })
      }

      if (!this.hasRequestTags) {
        this.setDefaultFilter()
      }

      this.resetPage()
      this.filterPaginatedRequests()
    },
    assignedFilterSelected: function(e){
      let index = e.indexOf(-1)
      if(index > -1) this.filters.filterByAssigned.splice(index, 1)

      this.resetAndSearch()
    },
    filterPaginatedRequests: async function() {
      const timeout = this.paginatedRequestTimeout

      if (this.filterDebounceTimeout) {
        clearTimeout(this.filterDebounceTimeout)
        // clear out a request that is already in progress
        this.axiosSource.cancel('Cancel Worklist Request')
      }

      this.filterDebounceTimeout = setTimeout(() => {
        this.axiosSource = axios.CancelToken.source()
        return this.$store.dispatch('GET_PAGINATED_REQUESTS')
      }, timeout)
    },
    toggleShowAllDepartments: function(){
      this.$store.commit('SET_SHOW_ALL_DEPARTMENT_REQUESTS', !this.showAllDepartmentRequests)

      this.resetPage()
      this.filterPaginatedRequests()
    },
    toggleShowSave: function(){
      this.showSaveWindow = !this.showSaveWindow;
      this.showViewsList = false
    },
    clearFilter: function(){
      this.searchQuery = ''
      this.clearTextSearchValue();
      this.showViewsList = false
      
      sessionStorage.setItem('worklistFilter', '{}')
      this.selectedFilter = false
      this.appliedFilter = '{}'

      this.$store.dispatch('worklist/SET_WORKLIST_FILTERS', {})

      this.resetVirtualSelectValues()
      this.resetRouteParamFilters();
    },
    clearTextSearchValue() {
      this.$store.dispatch('SET_SEARCH_TERM', new SearchTerm());
    },
    setSelectedFilter: function(filter = {}, close) {
      this.selectedFilter = filter

      const parsedFilter = JSON.parse(filter.filter || '{}')

      if (parsedFilter.Filter) {
        const reverseEngineerFilter = PaginatedFilterMapping.reverseEngineerFilters({ filter: JSON.stringify(parsedFilter) })
        this.appliedFilter = JSON.parse(reverseEngineerFilter.filter || '{}')
      } else {
        this.appliedFilter = JSON.parse(filter.filter || '{}')
      }

      const sortingArray = this.sortFactory(filter?.sort ?? [])

      this.updateWorklistSort(sortingArray)
      this.applyFilter()
      if (close) this.showViewsList = false
    },
    updateWorklistSort: function (sortingArray = []) {
      if (sortingArray.length > 1) {
        if (!this.hasMultiSortActivated) {
          this.$store.dispatch('worklist/TURN_MULTI_SORT_ON')
        }

        this.$store.dispatch(
          'SET_WORKLIST_SORT',
          [ ...sortingArray ]
        )
      }
    },
    applyFilter: function(filter){
      if (filter) {
        this.appliedFilter = filter
      }

      this.$store.dispatch('worklist/SET_WORKLIST_FILTERS', {
        ...this.appliedFilter
      })
      
      this.setVirtualSelectValues()
    },
    setVirtualSelectValues: function (noEventTrigger = false) {
      this.filterKeys.forEach(model => {
        const virtualSelectService = new VirtualSelectService(`#${model}`)
        if (virtualSelectService.el) {
          virtualSelectService.setValue(this.filters[model], noEventTrigger)
        }
      })
    },
    resetVirtualSelectValues: function () {
      this.filterKeys.forEach(model => {
        const virtualSelectService = new VirtualSelectService(`#${model}`)
        if (virtualSelectService.el) {
          virtualSelectService.reset()
        }
      })
    },
    toggleViewsList: function(){
      this.showViewsList = !this.showViewsList;
    },
    toggleSetFiltersList: function(){
      this.showSetFilters = !this.showSetFilters;
      sessionStorage.setItem('showWorklistFilters', this.showSetFilters)
      logger.trackHideFiltersEvent(this.showSetFilters)
    },
    setFiltersWithRouteParamFilterObject: function(routeParamFilters) {
      this.$store.dispatch('worklist/SET_WORKLIST_FILTERS', {
        ...this.filters,
        ...routeParamFilters.filters
      });

      const searchTermFilter = WorklistService.getSearchTermFilter(
        routeParamFilters.narrowSearchBy || "all"
      )

      const filter = PaginatedFilterMapping.formatCurrentFilter({ 
                        [searchTermFilter]: [routeParamFilters.searchTerm]
                      }) 

      this.$store.dispatch('SET_SEARCH_TERM',
        new SearchTerm({
          filterKey: routeParamFilters.searchTerm,
          narrowSearchBy: routeParamFilters.narrowSearchBy,
          filter
        })
      )
    },
    getRequestCountByPaPersonId: function (paPersonId) {
      if(!this.userCounts || this.userCounts.length == 0) return 0;

      if (paPersonId === "null") {
        const userCountForUnassigned = this.userCounts.find(u => u.paPersonId === null);

        if (userCountForUnassigned) return userCountForUnassigned.count;
      }

      if (paPersonId === "ASSIGNED_TO_ME") {
        const userCountForUnassigned = this.userCounts.find(u => u.paPersonId === this.currUser.paPersonId);

        if (userCountForUnassigned) return userCountForUnassigned.count;
      }

      const userCountObject = this.userCounts.find(u => u.paPersonId == paPersonId);

      if (userCountObject) {
        return userCountObject.count;
      }

      return 0;
    }
  },
  beforeDestroy: function () {
    this.saveCurrentFilter()

    window.removeEventListener('beforeunload', this.saveCurrentFilter)
  },
  computed: {
    ...mapState(
      [
        'worklistCurrentPage', 
        'workers', 
        'requests', 
        'allRequestsCount', 
        'payerList', 
        'dataList', 
        'providers', 
        'customUserFilters', 
        'fetchingRequests', 
        'currUser',  
        'fallbackToPolling', 
        'showAllDepartmentRequests', 
        'customerSettings', 
        'submissionMethods', 
        'submissionStatusCategories',
        'worklistDisplaySettings', 
        'departments', 
        'systemFilters', 
        'userCounts',
        'facilities',
        'worklistSortInfo',
        'textSearchTerm'
      ]
    ),
    ...mapState('auth', ['currUserID', 'loggedIn']),
    ...mapGetters(
      [
        'requestCounts', 
        'userRole', 
        'readOnlyUser', 
        'assigneesWithDepartments', 
        'currUserDeptWorkers', 
        'assigneesByDepartment', 
        'sortedPortalList', 
        'availableDepartmentsForTheCurrentUser', 
        'worklistParams',
        'hiddenStatuesFromWorklist',
        'currentUserIsManager'
      ]
    ),
    ...mapState('worklist', [
      'filters',
      'defaultFilterToSearchOnStart',
      'activeColumnHeaders',
      'hasMultiSortActivated',
      'filterUpdates',
      'filterToEdit'
    ]),
    ...mapGetters('worklist', [
      'worklistProviders',
      'filterableStatuses',
      'showDepartmentFilter',
      'workersSortedByDepartment',
      'submissionFilterOptions',
      'submissionStatusFilterOptions',
      'worklistFilterNamesLowered',
      'filterModified',
      'filterModifierHasBeenUpdated',
      'filteredProviders',
      'filteredStatusList',
      'filteredPayerList',
      'filteredMrnList',
      'filteredPatientFirstNameList',
      'filteredPatientLastNameList',
      'filteredEmrIdList',
      'filteredCptCodeList',
      'filteredProcedureDescriptionList',
      'hasRequestTags',
      'hasFilterChanges',
      'filteredRequests',
      'currentFilter',
      'getMatchingFilterFromCurrent',
      'currentFilterIsExistingFilter',
      'formattedCurrentFilter',
      'filterKeys',
      'procedureDateRangeNDaysInputDisplay',
      'followUpDateRangeNDaysInputDisplay',
      'filterSortHasBeenModified'
    ]),
    procedureDateRangeWithinNDaysModel: {
      set(value) {
        this.filters.procedureDateRangeWithinNDays = [value]
      },
      get() {
        return this.filters.procedureDateRangeWithinNDays.length ? this.filters.procedureDateRangeWithinNDays[0] : defaultNDays
      }
    },
    createdDateRangeWithinNDaysModel: {
      set(value) {
        this.filters.createdDateRangeWithinNDays = [value]
      },
      get() {
        return this.filters.createdDateRangeWithinNDays.length ? this.filters.createdDateRangeWithinNDays[0] : defaultNDays
      }
    },
    followUpDateRangeWithinNDaysModel: {
      set(value) {
        this.filters.followUpDateRangeWithinNDays = [value]
      },
      get() {
        return this.filters.followUpDateRangeWithinNDays.length ? this.filters.followUpDateRangeWithinNDays[0] : defaultNDays
      }
    },
    searchFilters() {
      return {
        ...this.filters,
        procedureDateRangeWithinNDaysModel: this.procedureDateRangeWithinNDaysModel,
        createdDateRangeWithinNDaysModel: this.createdDateRangeWithinNDaysModel,
        followUpDateRangeWithinNDaysModel: this.followUpDateRangeWithinNDaysModel
      }
    },
    canUpdateOrSaveFilter() {
      if (
        (this.filterModified || this.filterModifierHasBeenUpdated || this.filterSortHasBeenModified) && 
        !UtilityService.isEmptyNullOrUndefined(
          this.selectedFilter.departmentId)
        ) 
      {
        return this.currentUserIsManager
      }

      return this.filterModified || this.filterModifierHasBeenUpdated || this.filterSortHasBeenModified
    },
    getFilterByAssignedTags() {
      return this.workersSortedByDepartment.filter(
        w => this.filters.filterByAssigned.includes(w.paPersonId)
      )
    },
  },
  watch: {
    'filters.procedureDateRange': {
      handler(val){
        if(val && val.length && val.includes(null)) this.filters.procedureDateRange = [] 
      }
    },
    'filters.createdDateRange': {
      handler(val) {
        if(val && val.length && val.includes(null)) this.filters.createdDateRange = []
      }
    },
    'filters.followUpDateRange': {
      handler(val){
        if(val && val.length && val.includes(null)) this.filters.followUpDateRange = [] 
      }
    },
    'formattedCurrentFilter': {
      deep: true,
      handler: function(newVal, oldVal) {
        if(newVal && newVal.length && !newVal[0].Operator) return // don't trigger from initial state change
        this.$store.commit('SET_WORKLIST_CURRENT_FILTERS', newVal)
        this.checkForMatchingFilters()
      },
    },
    worklistSortInfo: {
      handler(nextValue, prevValue) {
        WorklistService.saveWorklistSortValuesInSession(nextValue)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
li .new{
  color:green;
}

.toggleSwitch{
  position: relative
}

.filtersHeader{
  padding: 0 4rem 0 1rem;
  border: 1px solid #CDCDCD;
  -webkit-transition: 0.25s opacity ease;
  transition: 0.25s opacity ease;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1B3668;
  width: 38.5rem;
  cursor: pointer;
  border-radius: 0.3rem;
  border-color: transparent;
  display: block;
}

.filtersHeader:after{
  font-family: 'Font Awesome 5 Pro';
   content: "\f078";
   position: absolute;
   right: 1rem;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   -webkit-transition: 0.25s all ease;
   transition: 0.25s all ease;
   -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.forceBlurOfFilter{
  position: absolute;
  z-index: 8;
  top: 24px;
  right: 2px;
  padding: 13px 21px 16px 10px;
  width: 10px;
  height: 14px;
  background-color: #fff;
  cursor: pointer;
}

.forceBlurOfFilter:before{
  content: '';
  display: block;
  border: solid transparent;
  border-top-color: #000;
  border-width: .6em .38em 0;
  opacity: .8;
  margin: 0 .2em;
}

.inputWrap.error input {
 border-color: #F05123;
}

.inputWrap.error label:after {
  color: #F05123;
  content: 'invalid filter name';
  float: right;
  font-style: italic;
  font-size: 1.1rem;
}

.buttonWrap button:disabled {
  cursor: not-allowed;
  background-color: #6DABE4;
}

.step1Container {
  padding: 0.5rem;
}

.datePickerHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 35rem; */
}

.datePickerHeaderContainer > div:first-child {
  margin-right: 2rem
}

.grayContainer {
  padding: 1.5rem;
  margin: 0 !important;
}

.grayContainer div {
  margin-bottom: 0;
}

.grayContainer a {
  font-size: 1.5rem;
  font-weight: 700;
}

.includeNullDatesContainer > label {
  margin-bottom: 0;
}

.withinDaysContainer > button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
}

.withinDaysContainer > label {
  margin-bottom: 0;
}

.withinDaysContainer > label > input {
  width: 45px;
  text-align: center;
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: 0 2px 0 #000000;
  height: 25px;
  margin: 0;
  padding: 0;
}
.align-center {
  display: flex;
  margin: 5rem auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: #1B3668;
}
.or {
  display: flex;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 2.5rem;
  margin-bottom: 2rem;
}

.or:after {
  content: 'OR';
  position: absolute;
  top: 100%;
  left: 50%;
  background: #ffffff;
  padding: 1rem;
  font-size: 2rem;
  transform: translate(-50%, -50%);
  color: #CDCDCD;
  font-weight: 600;
}

.bigButton {
  margin-top: 2rem;
}

.bigButton > button {
  width: 100%;
}

.filter-ctas {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.radio-button {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  input {
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
}

</style>



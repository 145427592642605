export default {
    computed: {
        URGENCY_NORMAL: function () {
            return 0
        },
        URGENCY_URGENT_NOW: function () {
            return 5
        },
        URGENCY_URGENT: function () {
            return 10
        },
        STATUS_AI_ANALYSIS: function () {
            return 5
        },
        STATUS_IN_PROGRESS: function () {
            return 10
        },
        PROCEDURE_DATE_CHANGED: function () {
            return 5
        }
    }
}

<template>
    <BaseModal
        modalContainerId="editFiltersModal"
        modalContentId="actionsModal"
        :includeCloseOption="true"
        :modalContentClasses="[
            'manageFilters',
        ]"
    >
        <template>
            <h3>{{ filter.name }}</h3>

            <section class="sort-filter-section">
                <div v-for="(sortInfo, index) in filterSortInfo" :key="index">
                    <div class="select-container flex-wrap mb-1.5">
                        <div class="inputWrap flex-half mr-1.5">
                            <VirtualSelect
                                v-model="sortInfo.field"
                                v-testid="'filter-sorting-value-for-new-filter'"
                                :selectedValue="sortInfo.field"
                                :loading="!selectableSortColumns.length"
                                :list="selectableSortColumns"
                                :search="false"
                                :multiple="false"
                                :hideClearButton="true"
                                :mapper="sortFilterMapper"
                                :dataParser="dataParser"
                                :id="`filterSortingValueForNewFilter-${index}`"
                                placeholder="Select"
                            >
                                <template v-slot:label>
                                    Column To Sort By
                                </template>
                            </VirtualSelect>
                        </div>

                        <div class="inputWrap flex-half">
                            <VirtualSelect
                                v-model="sortInfo.direction"
                                v-testid="'filter-sorting-direction-for-new-filter'"
                                :selectedValue="sortInfo.direction"
                                :loading="!sortDirectionOptions"
                                :list="sortDirectionOptions"
                                :search="false"
                                :multiple="false"
                                :hideClearButton="true"
                                :mapper="sortDirectionMapper"
                                :dataParser="dataParser"
                                :id="`filterSortingDirectionForNewFilter-${index}`"
                                placeholder="Select"
                            >
                                <template v-slot:label>
                                    Sort Direction
                                </template>
                            </VirtualSelect>
                        </div>

                        <div class="delete-container">
                            <i 
                                v-if="index > 0" 
                                @click="removeAdditionalSort(index)" 
                                class="fa fa-trash color-blue3"
                            ></i>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center color-blue3">
                    <button @click="addAdditionalSort">
                        <i class="fa fa-plus-circle"></i> Add Additional Sort
                    </button>
                </div>
            </section>

            <section>
                <div
                    class="inputWrap mt-2"
                >
                    <label class="checkbox-label-input" for="make-filter-default">
                    <input
                        id="make-filter-default"
                        :value="true"
                        v-testid="'default-filter-checkbox'"
                        type="checkbox"
                        v-model="savedFilterIsDefault"
                    />
                    <span>Make this my default Filter when logging into Pathway</span>
                    </label>
                </div>
            </section>

            <section class="team-shared-section">
                <div class="inputWrap">
                    <label class="checkbox-label-input" for="make-filter-default">
                        <input
                            id="make-filter-default"
                            :value="true"
                            v-testid="'default-filter-checkbox'"
                            type="checkbox"
                            v-model="savedFilterIsShared"
                        />
                        <span>Share this Filter with Team</span>
                    </label>

                    <VirtualSelect
                        v-if="savedFilterIsShared"
                        v-model="departmentToShareFilterWith"
                        v-testid="'share-filter-with-teams'"
                        :selectedValue="departmentToShareFilterWith"
                        :loading="!availableDepartmentsForTheCurrentUser.length"
                        :list="availableDepartmentsForTheCurrentUser"
                        :multiple="false"
                        :search="availableDepartmentsForTheCurrentUser.length > 10"
                        :mapper="departmentsMapper"
                        :dataParser="(value) => value"
                        :hideClearButton="true"
                        @input="setSharedDepartmentValue"
                        placeholder="Select"
                        id="selectTeamToShareFilterWith"
                    >
                        <template v-slot:label>
                            Team To Share With
                        </template>
                    </VirtualSelect>
                </div>
            </section>

            <div class="buttonWrap">
                <button
                    @click="updateFiltersHandler"
                    :disabled="!filtersHaveUpdates"
                >
                    <span v-if="updatingFilters">
                        <i class="fas fa-sync-alt fa-spin"></i>
                        Updating Filter(s)
                    </span>
                    <span v-else>Update Filter(s)</span>
                </button>

                <a
                    v-testid="'cancel-manage-filters-modal'"
                    href="#"
                    @click.prevent="cancelButtonHandler"
                >
                    Cancel
                </a>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/Modal.Base";
import VirtualSelect from "@/components/VirtualSelect.vue"
import { mapGetters, mapState } from "vuex";
import { WorklistFilter, Sort } from "@/shared/models/worklist"
import PaginatedFilterMapping from '@/services/paginatedFilterMapping'
import { WorkDashboardApi } from "@/services/workDashboardApi"
import { WorklistService } from '@/services/worklist'

const columnReference = WorklistService.getColumnReference()

export default {
    name: 'EditSavedFilter',
    components: { VirtualSelect, BaseModal },
    props: {
        filter: {
            type: Object,
            default: () => {}
        },
        sortFilterMapper: {
            type: Function,
            default: () => {}
        },
        dataParser: {
            type: Function,
            default: () => {}
        },
        sortDirectionOptions: {
            type: Array,
            default: () => []
        },
        sortDirectionMapper: {
            type: Function,
            default: () => {}
        },
        departmentsMapper: {
            type: Function,
            default: () => {}
        }
    },
    data: function () {
        return {
            filterSortInfo: [],
            departmentToShareFilterWith: [],
            updatingFilters: false,
            savedFilterIsShared: false,
            savedFilterIsDefault: false,
            columnReference: Object.keys(columnReference)
            .filter(key => key !== 'notes')
            .map(
                key => columnReference[key]
            ),
        }
    },
    mounted: function () {
        const parsedSortInfo = JSON.parse(JSON.stringify(this.filter.sort))

        this.filterSortInfo = parsedSortInfo.map(
            psi => ({
                field: [psi.field],
                direction: [psi.dir === "DESC" ? -1 : 1]
            })
        )

        const teamSharedWith = this.availableDepartmentsForTheCurrentUser
                                   .find(team => team.departmentId === this.filter.departmentId)
        if (teamSharedWith) {
            this.departmentToShareFilterWith = [ teamSharedWith.departmentId ]
            this.savedFilterIsShared = true
        }

        this.savedFilterIsDefault = this.filter.isDefault
    },
    computed: {
        ...mapState([
            "currUser",
            "worklistDisplaySettings"
        ]),
        ...mapGetters([ 
            "availableDepartmentsForTheCurrentUser",
        ]),
        filtersHaveUpdates: function () {
            return JSON.stringify(this.filterSortInfo) !== JSON.stringify(this.filter.sorts)
        },
        selectableSortColumns: function () {
            return this.columnReference.filter(
                columnReference => this.worklistDisplaySettings
                                    .visibleColumnOrder
                                    .includes(columnReference.id)
            )
        }
    },
    methods: {
        updateFiltersHandler: async function () {
            try {
                this.updatingFilters = true

                const payload = new WorklistFilter({
                    ...this.filter,
                    departmentId: this.savedFilterIsShared ? this.departmentToShareFilterWith[0] : null,
                    isDefault: this.savedFilterIsDefault,
                    filter: JSON.stringify({
                        Filter: {
                            Logic: null,
                            Filters: PaginatedFilterMapping.formatCurrentFilter(
                                JSON.parse(this.filter.filter)
                            ),
                            Modifiers: this.filter.modifiers
                        },
                        "Sort": this.filterSortInfo.map(
                            sortChanges => {
                                return {
                                    "Field": sortChanges.field[0],
                                    "Dir": parseInt(sortChanges.direction[0]) === -1 ? "DESC" : "ASC"
                                }
                            }
                        )
                    }),
                });

                if (this.filter.isDefault && !this.savedFilterIsDefault) {
                    await WorkDashboardApi.clearDefaultFilter(this.currUser.paPersonId)
                }

                await WorkDashboardApi.addOrUpdateSavedFilter(payload)

                await this.$store.dispatch('GET_FILTER_COUNTS')

                this.$store.commit('SET_GLOBAL_MESSAGE', [
                    'success', 
                    `Successfully updated filter: ${this.filter.name}`
                ])

                this.updatingFilters = false
                this.$emit('close')
            } catch (error) {
                this.$store.commit('SET_GLOBAL_MESSAGE', [
                    'error', 
                    `Unable to update filter: ${this.filter.name}`
                ])
                
                this.updatingFilters = false
                this.$emit('close')
            }
        },
        cancelButtonHandler: function() {
            this.$emit('close')
        },
        getUnSelectedSortOptions: function () {
            const currentlySelectedOptions = this.filterSortInfo.map(
                sortInfo => sortInfo.field[0]
            )

            return this.columnReference.filter(
                cr => !currentlySelectedOptions.includes(cr.sort)
            )
        },
        removeAdditionalSort: function (sortIndex) {
            this.filterSortInfo = this.filterSortInfo.filter(
                (_, index) => index !== sortIndex
            )
        },
        addAdditionalSort: function () {
            const unselectedSortOptions = this.getUnSelectedSortOptions()

            if (unselectedSortOptions.length) {
                this.filterSortInfo.push(
                {
                    field: [ unselectedSortOptions[0].sort ],
                    direction: [1]
                }
                )
            }
        },
        setSharedDepartmentValue(value) {
            this.departmentToShareFilterWith = value;
        },
    },
}
</script>

<style lang="scss" scoped>
.delete-container {
  position: absolute;
  right: -23px;
  bottom: 11px;
  display: flex;
  align-items: center;
}

.checkbox-label-input {
  display: flex;
  align-items: center;

  input {
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
}
</style>
<template>
  <BaseModal
    modalContainerId="savedFiltersModal"
    modalContentId="actionsModal"
    :includeCloseOption="false"
    :modalContentClasses="[
      'manageFilters',
      !isManagingFilters ? 'savingFilter' : ''
    ]"
  >
    <template>
      <transition-group 
        name="loading" 
        tag="div" 
        v-if="!loadingFilters"
      >
        <div class="sharedFiltersTableContainer" key="sharedFiltersTableContainer">
          <h3
            v-if="isManagingFilters"
          >
            Manage Saved Filters
          </h3>
          <h3 v-if="isSavingOrUpdatingFilters">
            Save This Filter
          </h3>
          <div v-if="isSavingOrUpdatingFilters">
            <div
              class="inputWrap"
              :class="{ error: !hasValidFilterName && hasFilterNameInputChanged }"
            >
              <label>Create new filter name</label>
              <input
                maxlength="55"
                type="text"
                v-model="saveFilterName"
                placeholder="Name this view"
                @input="setSaveFilterName"
              />
            </div>

            <div v-if="customUserFilters.length">
              <label>Or Select Filter to Update</label>
              <select class="previousFilterNamesSelect" v-model="saveFilterName">
                <option value="">None</option>
                <option
                  v-for="filter in listOfFiltersUserCanUpdate"
                  v-bind:key="filter.name"
                  :value="filter.name"
                >
                  {{ filter.name }}
                </option>
              </select>
            </div>
          </div>

          <table 
            v-if="isManagingFilters"
            class="resultTable"
          >
            <thead>
              <tr>
                <th class="tableHeading">Filter Name</th>
                <th class="tableHeading">Shared With</th>
                <th class="tableHeading">Default</th>
                <th class="tableHeading">Column Sorted By</th>
                <th class="tableHeading">Edit</th>
                <th class="tableHeading">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="tableCell">All Requests</span>
                </td>
                <td></td>
                <td>
                  <label class="tableCell radio-button">
                    <input
                      value="all_requests"
                      v-model="defaultSavedFilter"
                      type="radio"
                    />
                  </label>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="filter in customUserFilters" v-bind:key="filter.name">
                <td>
                  <span class="tableCell">
                    {{ filter.name }}
                  </span>
                </td>
                <td>
                  <span class="tableCell">
                    {{convertTeamIdToTeamName(filter.departmentId)}}
                  </span>
                </td>
                <td>
                  <label class="tableCell radio-button">
                    <input
                      :value="filter.name"
                      v-model="defaultSavedFilter"
                      type="radio"
                    />
                  </label>
                </td>
                <td>
                  <div class="tableCell filterSortingValue tagList">
                    <div class="currentTags mt-0:override p-0:override">
                      <ul>
                        <li
                          v-for="(sort, index) in filterSortingValueChanges[filter.worklistFilterId]" 
                          :key="index"
                          class="filterItem nonInteractiveTag mr-0.5:override"
                        >
                          {{ prettyPrintSortField(sort.field) }} | {{ sort.dir }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    v-if="canEditASharedFilter(filter)"
                    v-testid="filter.name"
                    href="#"
                    class="tableCell filterAction"
                    @click.prevent="editFilter(filter)"
                    ><i class="fas fa-pencil"></i> Edit</a
                  >
                </td>
                <td>
                  <a
                    v-if="canEditASharedFilter(filter)"
                    v-testid="filter.name"
                    href="#"
                    class="tableCell filterAction"
                    @click.prevent="displayDeleteModalWarning(filter)"
                    ><i class="fas fa-trash"></i> Delete</a
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="inputWrap mt-2"
            v-if="isSavingOrUpdatingFilters"
          >
            <label class="checkbox-label-input" for="make-filter-default">
              <input
                id="make-filter-default"
                :value="true"
                v-testid="'default-filter-checkbox'"
                type="checkbox"
                v-model="savedFilterIsDefault"
              />
              <span>Make this my default Filter when logging into Pathway</span>
            </label>
          </div>

          <div
            class="inputWrap mt-0.5"
            v-if="
              isSavingOrUpdatingFilters &&
              currentUserIsManager
            "
          >
            <label class="checkbox-label-input" for="make-filter-default">
              <input
                id="make-filter-default"
                :value="true"
                v-testid="'default-filter-checkbox'"
                type="checkbox"
                v-model="savedFilterIsShared"
              />
              <span>Share this Filter with Team</span>
            </label>

            <div class="mt-1.5 mb-1.5">
              <VirtualSelect
                v-if="savedFilterIsShared"
                v-model="departmentToShareFilterWith"
                v-testid="'share-filter-with-teams'"
                :selectedValue="departmentToShareFilterWith"
                :loading="!availableDepartmentsForTheCurrentUser.length"
                :list="availableDepartmentsForTheCurrentUser"
                :multiple="false"
                :search="availableDepartmentsForTheCurrentUser.length > 10"
                :mapper="mapAvailableDepartmentForVirtualSelect"
                :dataParser="(value) => value"
                :hideClearButton="true"
                @input="setSharedDepartmentValue"
                placeholder="Select"
                id="selectTeamToShareFilterWith"
              >
                <template v-slot:label>
                  Team To Share With
                </template>
              </VirtualSelect>
            </div>
          </div>

          <div v-for="(sortInfo, index) in dynamicSortingInfo" :key="index">
            <div
              class="select-container flex-wrap mb-1.5" 
              v-if="isSavingOrUpdatingFilters"
            >
              <div class="inputWrap flex-half mr-1.5">
                <VirtualSelect
                  v-model="sortInfo.field"
                  v-testid="'filter-sorting-value-for-new-filter'"
                  :selectedValue="sortInfo.field"
                  :loading="!selectableSortColumns.length"
                  :list="selectableSortColumns"
                  :search="false"
                  :multiple="false"
                  :hideClearButton="true"
                  :mapper="mapColumnReferenceForFilter"
                  :dataParser="dataParserForFilterSortInfo"
                  :id="`filterSortingValueForNewFilter-${index}`"
                  placeholder="Select"
                >
                  <template v-slot:label>
                    Column To Sort By
                  </template>
                </VirtualSelect>
              </div>

              <div class="inputWrap flex-half">
                <VirtualSelect
                  v-model="sortInfo.direction"
                  v-testid="'filter-sorting-direction-for-new-filter'"
                  :selectedValue="sortInfo.direction"
                  :loading="!sortDirectionOptions"
                  :list="sortDirectionOptions"
                  :search="false"
                  :multiple="false"
                  :hideClearButton="true"
                  :mapper="mapColumnReferenceForFilterSortDirection"
                  :dataParser="dataParserForFilterSortInfo"
                  :id="`filterSortingDirectionForNewFilter-${index}`"
                  placeholder="Select"
                >
                  <template v-slot:label>
                    Sort Direction
                  </template>
                </VirtualSelect>
              </div>

              <div class="delete-container">
                <i 
                  v-if="index > 0" 
                  @click="removeAdditionalSort(index)" 
                  class="fa fa-trash color-blue3"
                ></i>
              </div>
            </div>
          </div>

          <div v-if="isSavingOrUpdatingFilters" class="mt-2 text-center color-blue3">
            <button @click="addAdditionalSort">
              <i class="fa fa-plus-circle"></i> Add Additional Sort
            </button>
          </div>

          <div class="buttonWrap" key="savedFiltersModalSaveFilterOptions">
            <button
              @click="saveFilter"
              :disabled="isSaveOrUpdateFilterButtonDisabled"
              v-if="isSavingOrUpdatingFilters"
            >
              <i v-if="savingFilter" class="fas fa-sync-alt fa-spin"></i>
              <span
                v-if="
                  worklistFilterNamesLowered &&
                  worklistFilterNamesLowered.includes(saveFilterName.toLowerCase())
                "
                >Overwrite Filter</span
              >
              <span v-else>Create Filter</span>
            </button>
            <button
              v-if="isManagingFilters"
              @click="updateFilters"
              :disabled="!filtersHaveUpdates || updatingFilters"
            >
              <span v-if="updatingFilters">
                <i class="fas fa-sync-alt fa-spin"></i>
                Updating Filter(s)
              </span>
              <span v-else>Update Filter(s)</span>
            </button>
            <a
              v-testid="'cancel-manage-filters-modal'"
              href="#"
              @click.prevent="cancelButtonHandler"
              >Cancel</a
            >

            <div class="deleteWarningModal" v-if="displayDeleteWarningModal">
              <p>
                <b>
                    This will delete this shared filter from all users on the corresponding team.
                </b>
              </p>

              <p>
                <b>Are you sure you want to continue?</b>
              </p>

              <div class="buttonWrap">
                <button @click="proceedToDeleteFilter">
                  <span>Proceed</span>
                </button>
                <a
                  v-testid="'cancel-manage-filters-modal'"
                  href="#"
                  @click="resetDeleteFilterWarningData"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </transition-group>
      <transition name="loading">
        <GenericLoader 
          v-if="loadingFilters" 
          displaySize="large"
        >
          Loading Filters
        </GenericLoader>
      </transition>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import VirtualSelect from "@/components/VirtualSelect.vue";
import { WorkDashboardApi } from "@/services/workDashboardApi";
import { WorklistFilter } from "@/shared/models/worklist"
import BaseModal from "@/components/Modal.Base";
import PaginatedFilterMapping from '@/services/paginatedFilterMapping';
import { WorklistService } from '@/services/worklist'
import GenericLoader from '@/components/GenericLoader';
import { Sort } from "../shared/models/worklist";

const columnReference = WorklistService.getColumnReference()

export default {
  name: "SavedFiltersModal",
  components: { VirtualSelect, BaseModal, GenericLoader },
  props: {
    setSelectedFilter: {
      type: Function,
      default: () => {}
    },
    selectedFilter: {
      type: Object,
      default: () => {}
    },
    handleCurrentFilterDelete: {
      type: Function,
      default: () => {}
    },
    setFilterUpdates: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      departmentToShareFilterWith: [],
      savedFilterIsShared: false,
      savedFilterIsDefault: false,
      saveFilterName: "",
      savingFilter: false,
      defaultSavedFilter: "all_requests",
      columnReference: Object.keys(columnReference)
      .filter(key => key !== 'notes')
      .map(
        key => columnReference[key]
      ),
      filterSortingValue: {},
      filterSortingValueChanges: {},
      dynamicSortingInfo: [],
      sortingValueForNewFilter: [],
      sortingDirectionForNewFilter: [],
      filterSortingDirection: {},
      filterSortingDirectionChanges: {},
      sortDirectionOptions: ['ASC', 'DESC'],
      updatingFilters: false,
      displayDeleteWarningModal: false,
      filterToDelete: {},
      loadingFilters: true
    };
  },
  computed: {
    ...mapState([
      "customUserFilters", 
      "systemFilters",
      "currUser",
      "worklistSortInfo",
      "worklistDisplaySettings"
    ]),
    ...mapGetters([
      "currentUserIsManager", 
      "availableDepartmentsForTheCurrentUser",
      "worklistParams",
      "currentUserIsManager"
    ]),
    ...mapState("worklist", [
      "defaultFilter",
      "filterUpdates"
    ]),
    ...mapGetters("worklist", [
      "currentFilterIsExistingFilter",
      "currentFilter",
      "worklistFilterNamesLowered",
      "filterModifierHasBeenUpdated",
      "filterSortHasBeenModified"
    ]),
    hasValidFilterName: function () {
      return this.saveFilterName.trim().length > 0;
    },
    hasFilterNameInputChanged: function () {
      return this.saveFilterName.length > 0
    },
    filtersHaveUpdates: function () {
      return (
        JSON.stringify(this.filterSortingValueChanges) !== JSON.stringify(this.filterSortingValue) ||
        // JSON.stringify(this.filterSortingDirectionChanges) !== JSON.stringify(this.filterSortingDirection) ||
        this.defaultSavedFilter !== this.defaultFilter
      )
    },
    isSaveOrUpdateFilterButtonDisabled: function () {
      return (
        !this.saveFilterName || 
        this.savingFilter || 
        !this.hasValidFilterName || 
        (this.savedFilterIsShared && this.departmentToShareFilterWith.length === 0)
      )
    },
    isSavingOrUpdatingFilters: function () {
      return (!this.currentFilterIsExistingFilter && this.currentFilter) || this.filterModifierHasBeenUpdated || this.filterSortHasBeenModified
    },
    isManagingFilters: function () {
      return (
        (
          this.currentFilterIsExistingFilter && 
          !this.filterModifierHasBeenUpdated && 
          !this.filterSortHasBeenModified
        ) || 
        (!this.currentFilter && this.customUserFilters.length)
      )
    },
    listOfFiltersUserCanUpdate: function () {
      return this.customUserFilters.filter(
        customUserFilter => {
          if (customUserFilter.departmentId) {
            return this.currentUserIsManager
          }

          return true
        }
      )
    },
    selectableSortColumns: function () {
      const selectable = this.columnReference.filter(
        columnReference => this.worklistDisplaySettings
                               .visibleColumnOrder
                               .includes(columnReference.id)
      )

      return selectable
    }
  },
  created: async function () {
    this.dynamicSortingInfo = this.worklistSortInfo.map(
      worklistSortInfo => {
        return {
          field: [worklistSortInfo.field],
          direction: [worklistSortInfo.direction]
        }
      }
    )

    try {
      await this.$store.dispatch('GET_FILTER_COUNTS')
      this.loadingFilters = false
    } catch (error) {
      console.log(error)
    }

  },
  methods: {
    prettyPrintSortField: function (sortField) {
      const columnReference = WorklistService.getColumnReference()
      const fieldName = Object.keys(columnReference)
                              .find(fieldKey => columnReference[fieldKey].sort === sortField)
      if (fieldName) {
        return columnReference[fieldName].name === "" ? "Urgency" : columnReference[fieldName].name
      }

      return "N/A"
    },
    getUnSelectedSortOptions: function () {
      const currentlySelectedOptions = this.dynamicSortingInfo.map(
        sortInfo => sortInfo.field[0]
      )

      return this.columnReference.filter(
        cr => !currentlySelectedOptions.includes(cr.sort)
      )
    },
    addAdditionalSort: function () {
      const unselectedSortOptions = this.getUnSelectedSortOptions()

      if (unselectedSortOptions.length) {
        this.dynamicSortingInfo.push(
          {
            field: [ unselectedSortOptions[0].sort ],
            direction: [1]
          }
        )
      }
    },
    removeAdditionalSort: function (sortIndex) {
      this.dynamicSortingInfo = this.dynamicSortingInfo.filter(
        (_, index) => index !== sortIndex
      )
    },
    resetDeleteFilterWarningData: function () {
      this.displayDeleteWarningModal = false
      this.filterToDelete = {}
    },
    proceedToDeleteFilter: function () {
      this.deleteFilter(this.filterToDelete?.worklistFilterId)

      this.displayDeleteWarningModal = false
    },
    displayDeleteModalWarning: function (filter) {
      if (filter.departmentId && filter.paPersonId === this.currUser.paPersonId) {
        this.displayDeleteWarningModal = true
        this.filterToDelete = filter
      } else {
        this.deleteFilter(filter.worklistFilterId)
      }
    },
    editFilter: function (filter) {
      this.$store.dispatch('worklist/SET_FILTER_TO_EDIT', filter)
    },
    canEditASharedFilter: function (filter) {
      if (filter.departmentId) {
        return this.currentUserIsManager
      }

      return true
    },
    async updateFilters() {
      this.updatingFilters = true

      const worklistIds = this.customUserFilters
                              .map(customUserFilter => customUserFilter.worklistFilterId)

      const promises = worklistIds.map(worklistId => {
        const currentFilter = this.customUserFilters.find(
          customUserFilter => customUserFilter.worklistFilterId === worklistId
        )

        if (
          this.defaultSavedFilter === currentFilter.name
        ) {
          const filterModifiers = (currentFilter?.modifiers ?? {})

          const payload = new WorklistFilter({
            ...currentFilter,
            isDefault: currentFilter.name === this.defaultSavedFilter,
            filter: JSON.stringify({
              Filter: {
                Logic: null,
                Filters: PaginatedFilterMapping.formatCurrentFilter(
                  JSON.parse(currentFilter.filter)
                ),
                Modifiers: filterModifiers
              },
              "Sort": this.filterSortingValueChanges[worklistId].map(
                sortChanges => {
                  return {
                    "Field": sortChanges.field,
                    "Dir": sortChanges.dir
                  }
                }
              )
            }),
          });

          if (this.selectedFilter.worklistFilterId === worklistId) {
            this.$store.dispatch(
              'SET_WORKLIST_SORT',
              this.filterSortingValueChanges[worklistId].map(
                sortChanges => {
                  return new Sort({
                    field: sortChanges.field,
                    direction: sortChanges.dir === 'DESC' ? -1 : 1
                  })
                }
              )
            )
          }

          Object.keys(filterModifiers).forEach(
            modifierFilterKey => {
              this.setFilterUpdates({ 
                filterKey: modifierFilterKey,
                value: filterModifiers[modifierFilterKey]?.operator === 'NEQ' ? 'DOES_NOT_CONTAIN' : 'CONTAINS'
              })
            }
          )

          return WorkDashboardApi.addOrUpdateSavedFilter(payload);
        }
      }).filter(promise => promise)

      try {
        await Promise.resolve(promises)

        if (this.defaultSavedFilter !== this.defaultFilter && this.defaultSavedFilter === 'all_requests') {
          await WorkDashboardApi.clearDefaultFilter(this.currUser.paPersonId)
        }

        await this.$store.dispatch('GET_FILTER_COUNTS')

        this.cancelButtonHandler()

        this.$store.commit('SET_GLOBAL_MESSAGE', [
          'success', 
          'Successfully Updated Filter(s)'
        ])
      } catch (error) {
        this.$store.commit('SET_GLOBAL_MESSAGE', [
          'error', 
          'Unable To Update Filter(s)'
        ])
      }

      this.updatingFilters = false
    },
    dataParserForFilterSortInfo(value) {
      return value
    },
    mapColumnReferenceForFilter(columnReference) {
      return {
        label: columnReference.name === "" ? "Urgency" : columnReference.name,
        value: columnReference.sort
      }
    },
    mapColumnReferenceForFilterSortDirection(value) {
      return {
        label: value,
        value: value === "DESC" ? -1 : 1
      }
    },
    convertTeamIdToTeamName(teamId) {
      const foundTeam = this.availableDepartmentsForTheCurrentUser
                 .find( availableDepartment => availableDepartment.departmentId === teamId )
      return foundTeam?.name || ''
    },
    cancelButtonHandler: function () {
      this.saveFilterName = ''
      this.savedFilterIsDefault = false
      this.savedFilterIsShared = false

      this.$emit('close')
    },
    setSaveFilterName: function($event) {
      let existing = this.customUserFilters.findIndex( w => {
        return w.name.toLowerCase() == $event.target.value.toLowerCase()
      })
      if(existing > -1){
        this.saveFilterName = this.customUserFilters[existing].name
      }
    },
    setSharedDepartmentValue(value) {
      this.departmentToShareFilterWith = value;
    },
    mapAvailableDepartmentForVirtualSelect: (availableDepartment) => ({
      label: availableDepartment.name,
      value: [availableDepartment.departmentId],
    }),
    deleteFilter: async function (filterId) {
      try {
        const res = await WorkDashboardApi.deleteSavedFilter(filterId);

        if (res.data.length === 0) {
          this.setSelectedFilter({});
          this.$store.dispatch("UPDATE_CUSTOM_USER_FILTERS", res.data);
        } else {
          this.$store.dispatch("UPDATE_CUSTOM_USER_FILTERS", res.data);
        }

        if (filterId === this.selectedFilter?.worklistFilterId) {
          this.deleteCurrentFilter();
        }
    
        if (this.customUserFilters.length < 1) {
          this.cancelButtonHandler()
        };

        this.$store.commit('SET_GLOBAL_MESSAGE', [
          'success', 
          `Successfully removed filter`
        ])

        // get custom filter count
        await this.$store.dispatch('GET_FILTER_COUNTS')
      } catch (error) {
        window.console.log(error);
        this.$store.commit('SET_GLOBAL_MESSAGE', [
          'error', 
          `Unable to delete filter`
        ])
      }
    },
    deleteCurrentFilter: function () {
      this.handleCurrentFilterDelete()
      this.cancelButtonHandler()
    },
    saveFilter: async function () {
      this.savingFilter = true;

      const filter = JSON.stringify({
        ...this.worklistParams,
        "Sort": this.dynamicSortingInfo.map(sort => {
          return {
            "Field": sort.field[0],
            "Dir": parseInt(sort.direction[0]) === -1 ? "DESC" : "ASC"
          }
        })
      });

      const existingFilter = this.customUserFilters.find(
        (f) => f.name.toLowerCase() === this.saveFilterName.toLowerCase()
      );

      const newFilter = new WorklistFilter({
        name: this.saveFilterName,
        filter,
        departmentId: this.departmentToShareFilterWith[0],
        worklistFilterId: existingFilter?.worklistFilterId ?? 0,
        isDefault: this.savedFilterIsDefault,
      })


      try {
        const res = await WorkDashboardApi.addOrUpdateSavedFilter(newFilter);

        this.$store.dispatch("UPDATE_CUSTOM_USER_FILTERS", res.data);

        if (existingFilter) {
          this.setSelectedFilter(
            res.data.find(
              (d) => d.worklistFilterId === existingFilter.worklistFilterId
            )
          );
        } else {
          this.setSelectedFilter(res.data[res.data.length - 1]);
        }

        this.$store.dispatch(
          'SET_WORKLIST_SORT',
          this.dynamicSortingInfo.map(sort => {
            return new Sort({
              field: sort.field[0],
              direction: parseInt(sort.direction[0]),
            })
          })
        )

        this.saveFilterName = ''
        this.savingFilter = false;
        this.savedFilterIsDefault = false;
        
        // get custom filter count
        await this.$store.dispatch('GET_FILTER_COUNTS')
        
        this.cancelButtonHandler()
      } catch (error) {
        window.console.log(error);
        this.savingFilter = false;
        this.savedFilterIsDefault = false;

        this.cancelButtonHandler()
      }
    },
  },
  watch: {
    savedFilterIsShared: {
      handler(value) {
        if (!value) {
          this.departmentToShareFilterWith = []
        }
      }
    },
    defaultFilter: {
      immediate: true,
      handler(value) {
        this.defaultSavedFilter = value || "all_requests"
      }
    },
    customUserFilters: {
      immediate: true,
      handler(value) {
        this.filterSortingValue = value.reduce(
          (tempObj, customUserFilter) => {
            if (customUserFilter?.sort?.length > 0) {
              tempObj[customUserFilter.worklistFilterId] = customUserFilter.sort
            }

            return tempObj
        }, {})

        this.filterSortingValueChanges = { ...this.filterSortingValue }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
li .new{
  color:green;
}

.toggleSwitch{
  position: relative
}

.filtersHeader{
  padding: 0 4rem 0 1rem;
  border: 1px solid #CDCDCD;
  -webkit-transition: 0.25s opacity ease;
  transition: 0.25s opacity ease;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1B3668;
  width: 38.5rem;
  cursor: pointer;
  border-radius: 0.3rem;
  border-color: transparent;
  display: block;
}

.filtersHeader:after{
  font-family: 'Font Awesome 5 Pro';
   content: "\f078";
   position: absolute;
   right: 1rem;
   top: 50%;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
   -webkit-transition: 0.25s all ease;
   transition: 0.25s all ease;
   -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.forceBlurOfFilter{
  position: absolute;
  z-index: 8;
  top: 24px;
  right: 2px;
  padding: 13px 21px 16px 10px;
  width: 10px;
  height: 14px;
  background-color: #fff;
  cursor: pointer;
}

.forceBlurOfFilter:before{
  content: '';
  display: block;
  border: solid transparent;
  border-top-color: #000;
  border-width: .6em .38em 0;
  opacity: .8;
  margin: 0 .2em;
}

.inputWrap.error input {
 border-color: #F05123;
}

.inputWrap.error label:after {
  color: #F05123;
  content: 'invalid filter name';
  float: right;
  font-style: italic;
  font-size: 1.1rem;
}

.buttonWrap button:disabled {
  cursor: not-allowed;
  background-color: #6DABE4;
}

.step1Container {
  padding: 0.5rem;
}

.datePickerHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 35rem; */
}

.datePickerHeaderContainer > div:first-child {
  margin-right: 2rem
}

.grayContainer {
  padding: 1.5rem;
  margin: 0 !important;
}

.grayContainer div {
  margin-bottom: 0;
}

.grayContainer a {
  font-size: 1.5rem;
  font-weight: 700;
}

.includeNullDatesContainer > label {
  margin-bottom: 0;
}

.withinDaysContainer > button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
}

.withinDaysContainer > label {
  margin-bottom: 0;
}

.withinDaysContainer > label > input {
  width: 45px;
  text-align: center;
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: 0 2px 0 #000000;
  height: 25px;
  margin: 0;
  padding: 0;
}
.align-center {
  display: flex;
  margin: 5rem auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-color: #1B3668;
}
.or {
  display: flex;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 2.5rem;
  margin-bottom: 2rem;
}

.or:after {
  content: 'OR';
  position: absolute;
  top: 100%;
  left: 50%;
  background: #ffffff;
  padding: 1rem;
  font-size: 2rem;
  transform: translate(-50%, -50%);
  color: #CDCDCD;
  font-weight: 600;
}

.bigButton {
  margin-top: 2rem;
}

.bigButton > button {
  width: 100%;
}

.filter-ctas {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  input {
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
}

.checkbox-label-input {
  display: flex;
  align-items: center;

  input {
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
}

.manageFilters {
  padding: 0;
  border-top: 1px solid #e3e8ec;
  max-height: 50vh;
  overflow: auto;

  & li {
    list-style: none;
    padding: 0.5rem 1rem;
    margin: 0;
    border-bottom: 1px solid #e3e8ec;
    display: flex;
    justify-content: space-between;

    .filterName {
      width: 45rem;
    }
  }

  .previousFilterNamesSelect {
    width: 100%;
  }

  .filterSortingDirection {
    width: 8rem;
  }
}

.deleteWarningModal {
  position: absolute;
  background: $color-white;
  bottom: 0;
  left: 25%;
  padding: 2.5rem;
  box-shadow: 0px 1px 3px black;
}

.resultTable {
  width: 100%;
}
.resultTable thead tr th {
  top: -2.1rem;
}

.select-container {
  position: relative;
}

.delete-container {
  position: absolute;
  right: -23px;
  bottom: 11px;
  display: flex;
  align-items: center;
}
</style>
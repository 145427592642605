import { Sort, WorklistColumnHeader, SearchTerm } from '@/shared/models/worklist';
import { WORKLIST_HEADER_STATES } from '@/shared/constants/worklist'

export class WorklistService {
  static convertDateFromDSL(filter) {
    if (filter.length) {
      const start = new Date("1/1/2000");
      const end = new Date();

      end.setTime(end.getTime() + filter[0] * 24 * 3600 * 1000);

      return [start, end];
    }
    return filter;
  }

  static getColumnReference() {
    return {
      urgency: {
        name: "",
        sort: "urgency",
        id: "urgency",
      },
      patient: {
        id: "patient",
        sort: "lastName",
        name: "Patient",
      },
      orderingProviderName: {
        id: "orderingProviderName",
        sort: "orderingProviderName",
        name: "Ordering Provider",
      },
      medicalStemp: {
        id: "medicalStemp",
        sort: "primaryCptcode",
        name: "Code",
      },
      primaryInsuranceCompanyName: {
        id: "primaryInsuranceCompanyName",
        sort: "primaryInsuranceCompanyName",
        name: "Payer",
      },
      createdDate: {
        id: "createdDate",
        sort: "createdDate",
        name: "Created",
      },
      toBeDoneDate: {
        id: "toBeDoneDate",
        sort: "toBeDoneDate",
        name: "Proc Date",
      },
      procedureDescription: {
        id: "procedureDescription",
        sort: "procedureDescription",
        name: "Description",
      },
      strOrderNumber: {
        id: "strOrderNumber",
        sort: "strOrderNumber",
        name: "EMR ID",
      },
      facilityID: {
        id: "facilityID",
        sort: "facilityName",
        name: " Servicing Facility",
      },
      assignedToId: {
        id: "assignedToId",
        sort: "assignedToLastName",
        name: "Assigned To",
      },
      status: {
        id: "status",
        sort: "statusDisplayName",
        name: "Status",
      },
      submission: {
        id: "submission",
        sort: "submission",
        name: "Submission",
      },
      followUpDate: {
        id: "followUpDate",
        sort: "followUpDate",
        name: "Follow Up",
      },
      notes: {
        id: "notes",
        sort: "notes",
        name: "Notes",
      },
    }
  }

  static getSortOrders() {
    return {
      urgency: -1,
      lastName: 1,
      orderingProviderName: 1,
      primaryCptcode: 1,
      primaryInsuranceCompanyName: 1,
      secondaryInsuranceCompanyName: 1,
      tertiaryInsuranceCompanyName: 1,
      createdDate: 1,
      toBeDoneDate: 1,
      procedureDescription: 1,
      facilityName: 1,
      strOrderNumber: 1,
      submission: 1,
      statusDisplayName: 1,
      assignedToLastName: 1,
      followUpDate: 1,
      notes: 1
    }
  }

  static saveSortValuesInSession(params) {''
    if (params.sortKey) {
      sessionStorage.setItem('sortValue', params.sortKey)
    }

    if (params.order) {
      sessionStorage.setItem('sortOrder', params.order)
    }
  }

  static saveWorklistSortValuesInSession(updatedWorklistSortInfo) {
    sessionStorage.setItem('worklistSortInfo', JSON.stringify(updatedWorklistSortInfo))
  }

  static transformSortsToNewFormat(location) {
    const newFormat = sessionStorage.getItem(`${location}SortInfo`)

    if (newFormat) {
      return JSON.parse(newFormat)
    }

    let sortValue = sessionStorage.getItem('sortValue')
    let sortOrder = sessionStorage.getItem('sortOrder')

    sessionStorage.removeItem('sortValue')
    sessionStorage.removeItem('sortOrder')

    if (location === 'archive') {
      sortValue = sessionStorage.getItem('archiveRequestSort')
      sortOrder = sessionStorage.getItem('archiveRequestSortOrder')

      sessionStorage.removeItem('archiveRequestSort')
      sessionStorage.removeItem('archiveRequestSortOrder')
    }

    const newSortFormat = [
      new Sort({
        field: sortValue || 'urgency',
        direction: sortOrder || -1
      })
    ]

    // sessionStorage.removeItem('sortValue')
    // sessionStorage.removeItem('sortOrder')

    sessionStorage.setItem(`${location}SortInfo`, JSON.stringify(newSortFormat))

    return newSortFormat
  }

  static updateWorklistHeaderState({ field, state }) {
    return new WorklistColumnHeader({ field, state })
  }

  static getNextWorklistHeaderState(currentState) {
    const WORKLIST_HEADER_STATE_MANAGER = {
      1: WORKLIST_HEADER_STATES.READY_TO_MAKE_INACTIVE,
      2: WORKLIST_HEADER_STATES.INACTIVE,
      0: WORKLIST_HEADER_STATES.ACTIVE
    }

    return WORKLIST_HEADER_STATE_MANAGER[currentState]
  }
  
  static getSearchTermFilter(searchTermFilterBy) {
    const mapper = {
      "all": null,
      "mrn": "filterByMrn",
      "emr_id": "filterByEmrId",
      "patientFirstName": "filterByPatientFirstName",
      "patientLastName": "filterByPatientLastName",
      "cptCode": "filterByCptCode",
    }

    return mapper[searchTermFilterBy]
  }

  static processSearchTerm(searchTerm) {
    try {
        const processedSearchTerm = JSON.parse(searchTerm)
        return processedSearchTerm
    } catch (e) {
        const processedSearchTerm = new SearchTerm({
            filterKey: searchTerm
        })

        sessionStorage.setItem('textSearchTerm', JSON.stringify(processedSearchTerm))

        return processedSearchTerm
    }
  }
}

<template>
  <portal to="body">
    <transition name="modal-fade">
      <div :id="modalContainerId" class="modal-backdrop overlayContainer" :class="modalContainerClasses">
        <div :id="modalContentId" class="modal-content overlayContent" :class="modalContentClasses">
          <div
            v-if="includeCloseOption"
            id="closeModal"
            class="close"
            @click="closeModal"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: "BaseModal",
  props: [
    "modalContainerClasses",
    "modalContentClasses",
    "modalContainerId",
    "modalContentId",
    "includeCloseOption",
  ],
  methods: {
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>
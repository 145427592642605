<template>
    <BaseModal
      modalContainerId="downloadFilteredRequestsModal"
      modalContentId="downloadFilteredRequestsModalContent"
      :includeCloseOption="true"
      @close="$emit('close')"
    >
  
      <div class="content-area text-center mt-1.5 mb-3.5">
          <h3 class="mb-1">
            This may take a few minutes to export.
          </h3>

          <p class="mb-1">
            When complete you will see a notification in the top right corner of Pathway.
          </p>
      </div>
      <div class="buttonWrap text-right">
        <button class="mr-1.5 cancelBtn" @click="$emit('close')">Cancel</button>
        <button @click="buttonClickHandler">Export</button>
      </div>
    </BaseModal>
  </template>
      
      <script>
  import BaseModal from "@/components/Modal.Base"
  
  export default {
    name: "DownloadFilteredRequestsModal",
    props: ["procedureDateRangeWarning"],
    components: { BaseModal },
    methods: {
      buttonClickHandler: function () {
        this.$emit('download')
        this.$emit('close')
      },
    }
  };
</script>

<style scoped lang="scss">
.cancelBtn {
  background-color: $color-gray2;
}
</style>
      
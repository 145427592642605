import axios from "axios"
import ENV_CONFIG from '@/config/environment'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const apiPath1 = ENV_CONFIG.env_api;

export const directSubmitHealthHelp = {
    namespaced: true,
    state: {
        healthHelpCaseInitiationTransactionToken: '',
        healthHelpCaseInitiationError: null,
        healthHelpQuestionnaireErrorMessage: '',
        healthHelpLocationCode: '',
        healthHelpNextEndpoint: '',
        healthHelpQuestionnaireData: null,
        healthHelpQuestionnaireResponseData: null,
        healthHelpAtFinalClaimSubmit: false,
        healthHelpSubmitCaseErrorMessage: '',
        healthHelpDocumentsAttached: false,
        healthHelpDocumentsError: '',
        backgroundMonitoringUpdate: false,
        healthHelpBackgroundUpdate: false
    },
    actions: {
		HEALTH_HELP_CASE_INITIATION: async function({ commit, dispatch }, data) {
            await axios.post(`${apiPath1}HealthHelp/CaseInitiation`, data, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_HEALTH_HELP_CASE_INITIATION_TRANSACTION_TOKEN', response.data.transactionToken );
                commit('SET_HEALTH_HELP_LOCATION_CODE', response.data.nextCall.locationCode );
                commit('SET_HEALTH_HELP_NEXT_ENDPOINT', response.data.nextCall.address );
                commit('SET_HEALTH_HELP_REFERENCE_NUMBER', response.data.referenceNumber );
            })
            .catch((err) => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT');
                commit('SET_HEALTH_HELP_CASE_INITIATION_ERROR', err.response.data.Message ?? err.response.data );
            })
        },
        HEALTH_HELP_GET_QUESTIONNAIRE: async function({commit, dispatch}, payload) {
            await axios.post(`${apiPath1}HealthHelp/LoadQuestionnaire`, payload, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
                commit('SET_HEALTH_HELP_QUESTIONNAIRE_DATA', response.data)
                if (response.data.endpoint) {
                    commit('SET_HEALTH_HELP_NEXT_ENDPOINT', response.data.endpoint);
                }

                if (response.data.locationCode) {
                    commit('SET_HEALTH_HELP_LOCATION_CODE', response.data.locationCode);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }
                commit('SET_HEALTH_HELP_QUESTIONNAIRE_ERROR_MESSAGE', err.response.data );
            })
        },
        HEALTH_HELP_ANSWER_QUESTIONNAIRE: async function({commit, dispatch}, params) {
            await axios.post(`${apiPath1}HealthHelp/AnswerQuestionnaire`, params, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
				commit("SET_HEALTH_HELP_QUESTIONNAIRE_DATA", response.data);
                if ( response?.data?.locationCode === 'FinalSubmit'){
                    commit("SET_AT_HEALTH_HELP_FINAL_CLAIM_SUBMIT", true);
                } else {
                    if (response.data.endpoint) {
                        commit('SET_HEALTH_HELP_NEXT_ENDPOINT', response.data.endpoint);
                    }

                    if (response.data.locationCode) {
                        commit('SET_HEALTH_HELP_LOCATION_CODE', response.data.locationCode);
                    }
                }
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }
                window.console.log('HEALTH_HELP_ANSWER_QUESTIONNAIRE error: ', err);
                commit('SET_HEALTH_HELP_QUESTIONNAIRE_ERROR_MESSAGE', err.response.data );
            })
        },
        HEALTH_HELP_FINAL_SUBMIT: async function({commit, dispatch}, params) {
            await axios.post(`${apiPath1}HealthHelp/SubmitCase`, params, {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                    'Pragma': 'no-cache'
                }
            })
            .then((response) => {
				commit("SET_HEALTH_HELP_FINAL_SUBMIT_DATA", response.data);
                commit("SET_AT_HEALTH_HELP_FINAL_CLAIM_SUBMIT", false);
            })
            .catch((err) => {
                commit("SET_AT_HEALTH_HELP_FINAL_CLAIM_SUBMIT", false);
                if (err.response?.status === 401) dispatch('auth/SIGN_OUT')
                window.console.log(err)
                commit('SET_HEALTH_HELP_FINAL_SUBMIT_ERROR_MESSAGE', err.response.data )
            })
        },
        HEALTH_HELP_SUBMIT_DOCS: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}HealthHelp/AttachDocuments`, params, {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
            })
            .then((response) => {
                if (response.status === 200) {
                    commit('SET_HEALTH_HELP_DOCUMENTS_ATTACHED', true);
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }
                commit('SET_HEALTH_HELP_DOCUMENTS_ATTACHED', false);
                commit('SET_HEALTH_HELP_DOCUMENTS_ERROR', (err.response.data.Message ?? err.response.data) ?? 'Please contact Humata if errors continue');
            });
        },
        HEALTH_HELP_SEND_SUBMISSION_REQUEST: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}Submissions/UpdateSubmissionsForPaRequest`, params, {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
            })
            .then((response) => {
                // console.log(response);
                // window.console.log(response.data); 
            }, (err) => {
                if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                // Handle the edge case where the use links their account
                // Without refreshing the page.
                if (err.response.data !== "User does not have a linked account") {
                    commit('SET_GLOBAL_MESSAGE', ['error', `${err.response.data}`])
                }
                else {
                    commit('attestation/SET_NOT_LINKED_ACCOUNT', true)
                }
            });
        },
        HEALTH_HELP_WITHDRAW_SUBMISSION: async function({commit, dispatch}, submissionId, fail) {
            let successfulWithdraw = false;

            await axios.post(`${apiPath1}Submissions/WithdrawSubmission/${submissionId}?failed=${fail}&withdrawAnyway=${false}`, {}, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                    Pragma: "no-cache",
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    successfulWithdraw = response.data.withdraw;
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch('auth/SIGN_OUT');
                }
                commit('SET_HEALTH_HELP_DOCUMENTS_ATTACHED', false);
            });

            return successfulWithdraw;
        }
    },
    mutations: {
		SET_HEALTH_HELP_CASE_INITIATION_TRANSACTION_TOKEN: (state, data) => {
            state.healthHelpCaseInitiationTransactionToken = data
        },
        SET_HEALTH_HELP_REFERENCE_NUMBER: (state, data) => {
            state.healthHelpReferenceNumber = data
        },
        SET_HEALTH_HELP_CASE_INITIATION_ERROR: (state, data) => {
            state.healthHelpCaseInitiationError = data
        },
        SET_HEALTH_HELP_QUESTIONNAIRE_ERROR_MESSAGE: (state, data) => {
            state.healthHelpQuestionnaireErrorMessage = data
        },
        SET_HEALTH_HELP_LOCATION_CODE: (state, data) => {
            state.healthHelpLocationCode = data
        },
        SET_HEALTH_HELP_NEXT_ENDPOINT: (state, data) => {
            state.healthHelpNextEndpoint = data
        },
        SET_HEALTH_HELP_QUESTIONNAIRE_DATA: (state, data) => {
            state.healthHelpQuestionnaireData = data;
        },
        SET_HEALTH_HELP_ANSWER_QUESTIONNAIRE_RESPONSE_DATA: (state, data) => {
            state.healthHelpQuestionnaireResponseData = data;
        },
        SET_AT_HEALTH_HELP_FINAL_CLAIM_SUBMIT: (state, bool) => {
            state.healthHelpAtFinalClaimSubmit = bool;
        },
        SET_HEALTH_HELP_FINAL_SUBMIT_DATA: (state, data) => {
            state.healthHelpQuestionnaireData = data;
        },
        SET_HEALTH_HELP_FINAL_SUBMIT_ERROR_MESSAGE: (state, data) => {
            state.healthHelpSubmitCaseErrorMessage = data;
        },
        SET_HEALTH_HELP_DOCUMENTS_ATTACHED: (state, documentsAttached) => {
            state.healthHelpDocumentsAttached = documentsAttached
        },
        SET_HEALTH_HELP_DOCUMENTS_ERROR: (state, errorMessage) => {
            state.healthHelpDocumentsError = errorMessage;
        },
        SET_HEALTH_HELP_BACKGROUND_UPDATE: (state, bool) => {
            state.healthHelpBackgroundUpdate = bool;
        }
    },
    getters: {
        getHealthHelpSubmissionData: (state) => {
            return state.healthHelpQuestionnaireData;
        },
        areDocumentsAttached: (state) => {
            return state.healthHelpDocumentsAttached;
        },
        processingBackgroundHealthHelpUpdate: (state) => {
            return state.healthHelpBackgroundUpdate;
        }
    }
};
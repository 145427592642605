<template>
    <div>
        <div v-for="payload in messages" v-bind:key="payload.messageID">
            <UMSBanner 
                :message="payload.message"
                :messageType = "payload.messageType"
                :messageID="payload.messageId" 
                :hasDetails="payload.details && payload.details.length > 0"
                :onClose="closeMessage"
                :onMoreInfo="showDetails" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import UMSBanner from '@/components/UMSMessages.Banner'

export default {
    name: "UMSMessages",
    components: { UMSBanner, },
    data: function(){
        return { }
    },
    computed: {
        ...mapState("displayMessages", ["messages"])
    },
    methods: {
        closeMessage: function(messageId) {
            this.$store.dispatch('displayMessages/REMOVE_UMS_MESSAGE', messageId);
        },

        showDetails: function(messageId) {
            const message = this.messages.find(message => message.messageId === messageId);

            if (message.details) {
                this.$store.dispatch('displayMessages/TOGGLE_DISPLAY_DETAILS', { shown: true, details: message.details });
            }
        },
    }
}
</script>
import axios from 'axios'
import ENV_CONFIG from '@/config/environment'
import deepFreeze from '@/mixins/deepFreeze'

const apiPath1 = ENV_CONFIG.env_api;

function initialState() {
    return {
        requestRequirements: false,
        requestRequirementsErr: false,
        requirementConfidenceThresholds: false,
        requestRequirementsResults: false,
        updatingRequirementResults: [],
    }
}

export default {
    namespaced: true,
    state: initialState(),
    actions: {
        GET_REQUEST_REQUIREMENTS: async function({ commit, dispatch }, params) {
            // await axios.get(`${apiPath1}PaRequirements/Test`, {
            await axios.post(`${apiPath1}PaRequirements/GetRequirements`, params, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    if(response.data.requirementLists) {
                      commit('SET_REQUEST_REQUIREMENTS', response.data)
                      commit('SET_REQUEST_REQUIREMENTS_RESULTS', response.data.requirementResults)
                    }
                    if(!response.data.requirementLists) commit('SET_REQUEST_REQUIREMENTS_ERR', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_REQUEST_REQUIREMENTS_ERR', err.response.data)
                    return err;
                })
        },
        GET_REQ_CONFIDENCE_THRESHOLDS: async function({ commit, dispatch }) {
            await axios.get(`${apiPath1}RequirementResults/GetConfidenceThresholds`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQ_CONFIDENCE_THRESHOLDS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        CLEAR_REQUEST_REQUIREMENTS: function({ commit }) {
            commit('SET_REQUEST_REQUIREMENTS', false)
        },
        GET_REQUEST_REQUIREMENTS_RESULTS: async function({ commit, dispatch }, paRequestId) {
            await axios.get(`${apiPath1}RequirementResults/GetRequirementResultsForPa/${paRequestId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Pragma': 'no-cache'
                    }
                })
                .then((response) => {
                    commit('SET_REQUEST_REQUIREMENTS_RESULTS', response.data)
                }, (err) => {
                    if (err.response.status === 401) dispatch('auth/SIGN_OUT')
                    window.console.log(err)
                    return err;
                })
        },
        SET_REQUEST_REQUIREMENT_RESULT: async function({ commit, dispatch }, params) {
            await axios.post(`${apiPath1}RequirementResults/${params.resultState}`, params.data, {
                    headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }
                })
                .then((response) => {
                    // window.console.log(response)
                    commit('SET_REQUEST_REQUIREMENTS_RESULTS', response.data)
                    // dispatch('GET_REQUEST_REQUIREMENTS_RESULTS', params.data.paRequestId)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) dispatch('auth/SIGN_OUT')
                    commit('SET_GLOBAL_MESSAGE', ['error', `${err}, unable to set requirement`])
                    window.console.log(err)
                })
        },
        CLEAR_REQUEST_REQUIREMENTS_RESULTS: function({ commit, dispatch }) {
            commit('SET_REQUEST_REQUIREMENTS_RESULTS', false)
        },
    },
    mutations: {
        SET_REQUEST_REQUIREMENTS: (state, requestRequirements) => {
            // if (requestRequirements) {
            //     deepFreeze.methods.deepFreeze(requestRequirements)
            // }
            state.requestRequirements = requestRequirements
        },
        SET_REQUEST_REQUIREMENTS_ERR: (state, err) => {
            state.requestRequirementsErr = err
        },
        SET_REQ_CONFIDENCE_THRESHOLDS: (state, thresholds) => {
            state.requirementConfidenceThresholds = thresholds
        },
        SET_REQUEST_REQUIREMENTS_RESULTS: (state, requestRequirementsResults) => {
            // if (requestRequirementsResults) {
            //     requestRequirementsResults.forEach(Object.freeze)
            // }
            state.requestRequirementsResults = requestRequirementsResults
        },
        SET_UPDATING_REQUIREMENT_RESULTS: (state, updatingRequirementResults) => {
            state.updatingRequirementResults = updatingRequirementResults
        },
    }
}
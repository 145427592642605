export default {
    methods: {
      isEmptyNullOrUndefined(value) {
        if (value == null || value == undefined) return true;
    
        if (value == "" || value.length == 0) {
          return true;
        }
        return false;
      },
      hasNumbersInString(str) {
        const regex = /\d/;
        return regex.test(str);
      },
      isEmptyDateRange: function(dateRange) {
        if (dateRange && this.isEmptyNullOrUndefined(dateRange[0]) && this.isEmptyNullOrUndefined(dateRange[1])) {
          return true;
        }
        
        return false;
      },
      isNotNullEmptyOrAnUndefinedString(value) {
        if (value != null && value.toLowerCase() != "undefined" && value != undefined && value != "") {
          return true;
        }
        return false;
      }
    }
}

<template>
  <div>
    <UMSMessages />
    <div v-if="worklistNotifications.includes('UpcomingReleaseNotes')">
      <div class="newFeaturesBanner">
        <p>{{upcomingReleaseNotes.upcomingReleaseBannerText}} <a href="#" @click.prevent="toggleReleaseNoteDisplay">{{upcomingReleaseNotes.upcomingReleaseLinkText}}</a></p>
      </div>
    </div>
    <div>
      <SortedWorklist />
      <AarAccountLinkModal v-if="gorillaLinkToken" :verificationCode="gorillaLinkToken" />
    </div>
    <transition name="loading">
    <div id="loadingBlock" v-if="fetchingRequests || loading">
      <div class="loadingBlockInfo">
        <GenericLoader displaySize="large">Getting PA Requests</GenericLoader>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import GenericLoader from '@/components/GenericLoader'
import { mapState, mapGetters } from 'vuex'
import SortedWorklist from './SortedWorklist'
import UMSMessages from '@/components/UMSMessages'

export default {
  name: 'Worklist',
  components: { 
    GenericLoader,
    SortedWorklist,
    UMSMessages, 
    AarAccountLinkModal: () => import('../components/Modal.AarAccountLink.vue'), 
  },
  data: function(){
    return {
      linkAccountToken: '',
      displayAarLinkModal: false,
    }
  },
  computed: {
    ...mapState({
      currUser: (state) => state.currUser,
      customerSettings: (state) => state.customerSettings,
      fetchingRequests: (state) => state.fetchingRequests,
      worklistNotifications: (state) => state.worklistNotifications,
      showReleaseNotes: (state) => state.showReleaseNotes,
      upcomingReleaseNotes: (state) => state.upcomingReleaseNotes,
      workers: (state) => state.workers,
      payerList: (state) => state.payerList,
      dataList: (state) => state.dataList,
      providers: (state) => state.providers,
      submissionStatusCategories: (state) => state.submissionStatusCategories,
      gorillaLinkToken: (state) => state.gorilla.gorillaLinkToken
    }),
    ...mapGetters(
      ['availableDepartmentsForTheCurrentUser']
    ),
    loading() {
      return !(  
        this.providers 
        && this.workers
        && this.dataList 
        && this.payerList
        && this.submissionStatusCategories  
        && this.availableDepartmentsForTheCurrentUser
      )
    },
  },
  methods: {
    toggleReleaseNoteDisplay: function(){
      let params = {
        show: !this.showReleaseNotes,
        source: 'upcoming'
      }
      this.$store.commit('SET_RELEASE_NOTES_DISPLAY', params)
    },
    hideAarLinkingModal: async function () {
      // if closing the Gorilla Linking modal let tokenauth
      // know that the user had decided to not link their account.
      // If they (the user) needed to link their account again,
      // they would need to go through the linking process again.
      if (this.gorillaLinkToken) {
        await this.$store.dispatch('attestation/CONFIRM_ACCOUNT_LINK', {
          token: this.gorillaLinkToken,
          linkAccount: false
        })

        this.$store.dispatch('gorilla/SET_GORILLA_LINK_TOKEN', null)
      }
    }
  },
  mounted: function(){
    let isIE = this.$browserDetect.isIE;
    this.$store.commit('SET_IS_IE', isIE);
    this.$store.dispatch('GET_ASSIGNEDTO_REQUEST_COUNT');
    this.$store.dispatch('CLEAR_SUBMISSION_HISTORY');

    // always scroll to the top of the page
    // single page application have a tendency
    // to hold your scroll position when chaning
    // routes.
    window.scrollTo(0, 0);
  },
  // beforeRouteEnter: function(to, from, next){
  //   if(to.params && to.params.root) {
  //     sessionStorage.setItem('worklistCurrentPage', 0)
  //   }
  //   return next()
  // },
}
</script>

<style scoped>
.filters{
  height: 50px;
}

.filters .toggleSwitch{
  position: relative;
}

.closeBanner{
  float:right;
  cursor: pointer;
  color: #1B3668;
}
</style>

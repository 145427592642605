import PaginatedFilterMapping from '@/services/paginatedFilterMapping'

export default {
  methods: {
    getFilterMapping: () => PaginatedFilterMapping.getFilterMapping(),
    reverseEngineerFilters: function(filter) {
        return PaginatedFilterMapping.reverseEngineerFilters(filter)
    },
    formatCurrentFilter: function(currentFilter, filterUpdates) {
      return PaginatedFilterMapping.formatCurrentFilter(currentFilter, filterUpdates)
    },
    getFilterNameFromField: function(fieldName) {
      return PaginatedFilterMapping.getFilterNameFromField(fieldName)
    }
  },
};